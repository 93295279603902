import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import Account_Billing from "components/afrijula/Settings/Account_Billing.jsx";
import User_Account from "components/afrijula/Settings/User_Account.jsx";
import General_info from "components/afrijula/Settings/General_Info.jsx";
import GridContainer from "components/common/Grid/GridContainer";
import GridItem from "components/common/Grid/GridItem";
import Card from "components/common/Card/Card.jsx";
import CardBody from "components/common/Card/CardBody";
import Button from "components/common/CustomButtons/Button";

function TabContainer1(props) {
    return (
        <Typography component={General_info} style={{ padding: 8 * 3 }}>

        </Typography>
    );
}

function TabContainer2(props) {
    return (
        <Typography component={Account_Billing} style={{ padding: 8 * 3 }}>

        </Typography>
    );
}
function TabContainer3(props) {
    return (
        <Typography component={User_Account} style={{ padding: 8 * 3 }}>

        </Typography>
    );
}

TabContainer1.propTypes = {
    children: PropTypes.node.isRequired,
};
TabContainer2.propTypes = {
    children: PropTypes.node.isRequired,
};
TabContainer3.propTypes = {
    children: PropTypes.node.isRequired,
};
const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
});

class Settings extends React.Component {
    state = {
        value: 0,

        back: false,
        callerName: this.props.callerName,
        callerProps: this.props.callerProps,
        callerState: this.props.callerState,
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    render() {
        const { classes } = this.props;
        const { value } = this.state;

        if(this.state.back){
            let ChildComponent = this.state.callerName;
            let state = this.state.callerState;
            state.edit = false;
            return <ChildComponent state = {state} returningProps = {this.state.callerProps} />
        }

        return (
            <div className={classes.root}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={8}>
                                    </GridItem>


                                    <GridItem xs={12} sm={12} md={2}>

                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={2}>
                                        <Button color="danger" onClick={() => {this.setState({back: true})}} style={{float:'right'}}>
                                            {'back'}
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <AppBar position="static" style={{backgroundColor: '#9c27b0', color: '#fff' }}>
                    <Tabs value={value} onChange={this.handleChange}>
                        <Tab label="Genenral Info" />
                        <Tab label="Account & Billing" />
                        <Tab label="User Account" />
                    </Tabs>
                </AppBar>
                {value === 0 && <TabContainer1></TabContainer1>}
                {value === 1 && <TabContainer2></TabContainer2>}
                {value === 2 && <TabContainer3></TabContainer3>}
            </div>
        );
    }
}

Settings.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Settings);

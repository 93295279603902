import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GridItem from "../../../components/common/Grid/GridItem.jsx";
import GridContainer from "../../../components/common/Grid/GridContainer.jsx";
import Button from "../../../components/common/CustomButtons/Button.jsx";
import Card from "../../../components/common/Card/Card.jsx";
import CardBody from "../../../components/common/Card/CardBody.jsx";
import CardFooter from "../../../components/common/Card/CardFooter.jsx";
import Add_User from "../../../components/afrijula/Settings/Add_User.jsx";
import Table from "@material-table/core";
import frStrings from  "../../../assets/js/locales_fr.js";
import enStrings from  "../../../assets/js/locales_en.js";
import $ from "jquery";
import cookies from "react-cookies";
import baseUrl from "../../../assets/js/config/const.js";

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});


class User_Account extends React.Component {

    getString = () => {
        var strings;
        var lang = window.navigator.language;
        if(lang === 'en' || lang === 'en-US' ){
            strings = enStrings;
        }else{
            strings = frStrings;
        }
        return strings;
    };

    state = {


        header:  [
            {title: this.getString().first_name, field: "first_name"},

            {title: this.getString().last_name, field: "last_name"},
            {title: this.getString().email , field: "email"},
            {title: this.getString().type , field: "type"},
        ],
        account_lists: [],
    };



    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };


    addUser = () => {
        this.setState({add_user:true});
    };
    componentDidMount() {
        this.loadUsers();
    };
    loadUsers = () => {
        let the_data = [];
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/afrijula/user_accounts",
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},

            success: function (result) {
                this.setState({account_lists:result});
            }.bind(this)
        });
    };

    render() {
        const { classes } = this.props;

        if(this.state.add_user){
            return <Add_User />;
        }

        return (

            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <Button xs={12} sm={12} md={2} color="primary" onClick={this.addUser}><i className="material-icons">person_add</i>
                                            {this.getString().add_user}
                                        </Button>
                                    </GridItem>
                                </GridContainer>

                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                             <Table
                                 title={this.getString().user_acc}
                                data={this.state.account_lists}
                                columns={this.state.header}
                                 options={{paging:false}}
                                 actions={[

                                     {
                                         icon: 'delete',
                                         tooltip: 'Delete Account',
                                         onClick: (event, rowData) => alert("You want to delete " + rowData.name)
                                     },
                                     {
                                         icon: 'edit',
                                         tooltip: 'Edit Account',
                                         onClick: (event, rowData) => alert("You saved " + rowData.name)
                                     },
                                 ]

                                 }/>

                            <CardFooter>
                            </CardFooter>
                        </Card>
                    </GridItem>

                </GridContainer>
            </div>
        );
    }
}

User_Account.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(User_Account);

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GridItem from "../../../components/common/Grid/GridItem.jsx";
import GridContainer from "../../../components/common/Grid/GridContainer.jsx";
import Button from "../../../components/common/CustomButtons/Button.jsx";
import Card from "../../../components/common/Card/Card.jsx";
import CardHeader from "../../../components/common/Card/CardHeader.jsx";
import CardBody from "../../../components/common/Card/CardBody.jsx";

import PayBill from "../../../components/afrijula/Settings/PayBill.jsx";
import Upgrade from "../../../components/afrijula/Settings/UpgradeVersion.jsx";

import frStrings from  "../../../assets/js/locales_fr.js";
import enStrings from  "../../../assets/js/locales_en.js";
import $ from "jquery";
import cookies from "react-cookies";
import baseUrl from "../../../assets/js/config/const.js";
import {numberFormat, currencyFormat, dateFormat,compileQuery} from "../../../assets/js/utils/utils.js";
import Table from "@material-table/core";


const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});


class Account_Billing extends React.Component {

    getString = () => {
        var strings;
        var lang = window.navigator.language;
        if(lang === 'en' || lang === 'en-US' ){
            strings = enStrings;
        }else{
            strings = frStrings;
        }
        return strings;
    };

    state = {

        last_payment: '',
        last_charge: '',
        balance: '',
        max_users: '',
        monthly_price: '',
        payment_method: '',
        package_name: '',
        charges:[],
        price: 0.0,
        payments:[],
        package: '',

        pay_bill: false,
        upgrade_version: false,

        chargeData:  [],
        chargeHeader:  [
            {title: this.getString().date, field:'date',render: rowData => dateFormat(rowData.date)},
            {title: this.getString().total, field:'total',render: rowData => currencyFormat(rowData.amount)}
        ],

       paymentData:  [],
       paymentHeader:  [
            {title: this.getString().date, field:'date',render: rowData => dateFormat(rowData.date)},
            {title: this.getString().total, field:'total',render: rowData => currencyFormat(rowData.amount)}
        ],
    };

    payBill = () => {
        this.setState({pay_bill:true});
    };

    upgradeVersion = () => {
        this.setState({upgrade_version:true});
    };

    loadSetting = () => {
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/afrijula/billing/",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            success: function (result) {
                _this.setState({balance: result.balance, max_users: result.max_users, users: result.users,
                                last_payment:result.last_payment, price: result.pricing,
                                package: result.package, payments: result.payments, charges: result.charges});
            }
        });
    };


    componentWillMount (){
        this.loadSetting();
    }

    render() {
        const { classes } = this.props;

        if(this.state.pay_bill){
            return <PayBill package={this.state.package} price={this.state.price}/>;
        }
        if(this.state.upgrade_version){
            return <Upgrade package={this.state.package} price={this.state.price} />;
        }
        return (

            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <Button xs={12} sm={12} md={2} color="primary" onClick={this.payBill}><i className="material-icons">attach_money</i>
                                            {this.getString().pay}
                                        </Button>
                                        <Button xs={12} sm={12} md={2} color="warning" onClick={this.upgradeVersion} ><i className="material-icons">shop</i>
                                            {this.getString().upgrade}
                                        </Button>
                                    </GridItem>
                                </GridContainer>

                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader>
                                <h4 >{this.getString().details}</h4>
                            </CardHeader>
                            <CardBody>
                                <form>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <p><b>{this.getString().package}:</b></p>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <p id="package_name"><b>{this.state.package}</b></p>
                                                    </GridItem>
                                                </GridContainer>
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <p><b>{this.getString().balance}:</b></p>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <p id="bal"><b>{currencyFormat(this.state.balance)}</b></p>
                                                    </GridItem>
                                                </GridContainer>
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <p><b> {this.getString().payment_method}:</b></p>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <p id="payment_method"><b>{this.state.payment_method}</b></p>
                                                    </GridItem>
                                                </GridContainer>
                                            </GridItem>

                                            <GridItem xs={12} sm={12} md={6}>
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <p><b>{this.getString().billing_info}:</b></p>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <p id="monthly_price"><b>{this.state.price}</b></p>
                                                    </GridItem>
                                                </GridContainer>
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <p><b>{this.getString().last_payment}:</b></p>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <p id="bal"><b>{dateFormat(this.state.last_payment)}</b></p>
                                                    </GridItem>
                                                </GridContainer>
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <p><b> {this.getString().max_users}:</b></p>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <p id="max_users"><b>{this.state.max_users}</b></p>
                                                    </GridItem>
                                                </GridContainer>
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                </form>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="warning">
                                <h3 >{this.getString().charges}</h3>

                            </CardHeader>
                            <Table
                                tableRef={this.tableRef}
                                data={this.state.charges}
                                columns={this.state.chargeHeader}
                                onRowClick={this.rowSelect}
                                options={{
                                    exportButton: true,filtering: false,
                                    grouping:false,sorting:false,
                                    selection:false,showTitle:false,
                                    pageSize:5
                                }}
                            />
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="success">
                                <h3 >{this.getString().payment}</h3>

                            </CardHeader>
                            <Table
                                tableRef={this.tableRef}
                                data={this.state.payments}
                                columns={this.state.paymentHeader}
                                onRowClick={this.rowSelect}
                                options={{
                                    exportButton: true,filtering: false,
                                    grouping:false,sorting:false,
                                    selection:false,showTitle:false,
                                    pageSize:5
                                }}
                            />
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

Account_Billing.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Account_Billing);

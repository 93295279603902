import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/common/Grid/GridItem.jsx";
import GridContainer from "../../../components/common/Grid/GridContainer.jsx";
import Button from "../../../components/common/CustomButtons/Button.jsx";
import Card from "../../../components/common/Card/Card.jsx";
import CardHeader from "../../../components/common/Card/CardHeader.jsx";
import CardBody from "../../../components/common/Card/CardBody.jsx";
import frStrings from "../../../assets/js/locales_fr.js";
import enStrings from "../../../assets/js/locales_en.js";
import {currencyFormat, dateFormat} from "../../../assets/js/utils/utils.js";
import ReactTimeAgo from "react-time-ago";
import 'rodal/lib/rodal.css';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from "react-toasts";
import $ from "jquery";
import {displayError} from "../../../assets/js/utils/utils";
import baseUrl from "assets/js/config/const.js";
import cookies from "react-cookies";
import AddLoan from "./AddLoanProduct";

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};


class LoanProductDetails extends React.Component {
    constructor(props) {
        super(props);

        this.tableRef = React.createRef();
        this.chargeRef = React.createRef();
    }

    getString = () => {
        var strings;
        var lang = window.navigator.language;
        if (lang === 'en' || lang === 'en-US') {
            strings = enStrings;
        } else {
            strings = frStrings;
        }
        return strings;
    };

    state = this.props.state || {
        back: false,
        editDetails: false,
        editSetting: false,
        userData: null,
        countryName: 'Gambia (GMD)',
        packages: [],
        billing: {charges: [], payments: []},
        header: [
            {title: this.getString().name, field: 'name'},
            {title: this.getString().email, field: 'email'},
            {
                title: this.getString().last_active, field: 'last_login_date', render: rowData => {
                    return <ReactTimeAgo date={new Date(rowData.last_login_date)} locale="en"/>
                }
            },
            {title: this.getString().status, field: 'status'},
        ],
        paymentHeader: [
            {
                title: this.getString().amount, field: 'amount', render: rowData => {
                    return currencyFormat(rowData.amount)
                }, cellStyle: {padding: '4px'}, headerStyle: {padding: '4px'}
            },
            {
                title: this.getString().date,
                field: 'date',
                render: rowData => dateFormat(rowData.date),
                cellStyle: {padding: '4px'},
                headerStyle: {padding: '4px'}
            },
        ],
        redeem: false,
        chargesHeader: [
            {
                title: this.getString().date,
                field: 'date',
                render: rowData => dateFormat(rowData.date),
                cellStyle: {padding: '4px'},
                headerStyle: {padding: '4px'}
            },
            {
                title: this.getString().amount,
                field: 'amount',
                render: rowData => currencyFormat(rowData.amount),
                cellStyle: {padding: '4px'},
                headerStyle: {padding: '4px'}
            },
        ],
        voucher: '',
        status: [
            {
                value: 'activate',
                label: 'Activate',
            },
            {
                value: 'unactivate',
                label: 'Unactivate',
            },

        ],
        activate: false,
        addUser: false,
        upgrade: false,
        settings: {},
        users_list: [],
        metrics: {
            users: 0,
            active: 0,
            tran_count: 0,
            package: {
                price: 0,
                max_users: 0,
                extra_users: 0,
                term: '',
                max_sales: 0,
            }
        },
        printerType: [
            {
                value: 'thermal',
                label: this.getString().thermal_printer,
            },
            {
                value: 'a4',
                label: this.getString().large,
            },
        ],
        genderList: [
            {value: 'Female', label: 'Female', _type: 'owner_gender'},
            {value: 'Male', label: 'Male', _type: 'owner_gender'}
        ],
        regionList: [
            {value: 'BJL', label: 'BJL', _type: 'lga'},
            {value: 'CRR', label: 'CRR', _type: 'lga'},
            {value: 'KMC', label: 'KMC', _type: 'lga'},
            {value: 'LRR', label: 'LRR', _type: 'lga'},
            {value: 'NBR', label: 'NBR', _type: 'lga'},
            {value: 'URR', label: 'URR', _type: 'lga'},
            {value: 'WCR', label: 'WCR', _type: 'lga'}
        ],
        ageBrackets: [
            {value: '15-24', label: '15-24', _type: 'owner_age'},
            {value: '25-35', label: '25-35', _type: 'owner_age'},
            {value: 'Over 35', label: 'Over 35', _type: 'owner_age'}
        ],
        businessTypes: [
            {value: 'MSME', label: 'MSME', _type: 'business_type'},
            {value: 'Large Corporation', label: 'Large Corporation', _type: 'business_type'}
        ],
        callerName: this.props.callerName,
        callerProps: this.props.callerProps,
        callerState: this.props.callerState,
        loanProdData: {},
        editLoanProduct: false
    };

    displayToast = (message, status) => {
        if (status === "success")
            return ToastsStore.success(message, 6000);
        else
            return ToastsStore.error(message, 6000);
    };

    onChange = (opt) => {
        this.setState({locale: opt.label})
    };

    deleteProduct = () => {
        let response = window.confirm("Are you sure want to delete this loan product?");
        if(response){
            let _this = this;
            $.ajax({
                method: "DELETE",
                url: baseUrl + "/afrijula/loans/loan_products/"+this.state.loanProdData._id.$oid,
                dataType: "json",
                headers: {
                    "Authorization": "token " + cookies.load("token"),
                    "UserProfile": cookies.load("profile"),
                    "UserKey": cookies.load('User-Key'),
                },
                success: function (result) {
                    if (result.error) {
                        _this.displayToast(result.message, "error");

                    } else {
                        _this.displayToast(result.message, "success");
                        _this.setState({back: true});
                    }
                }.bind(this),
                error: function (error) {
                    displayError(error.responseText)
                }
            });
        }
    };

    editProduct = () => {
        this.setState({editLoanProduct: true});
    };

    cancelEditLoanProduct = () => {
        this.setState({editLoanProduct: false});
    };

    updateMe = (loanProdData) => {
        this.setState({loanProdData: loanProdData})
    };

    _isObject = data => typeof data === "object";

    componentDidMount() {
        if(!this.props.notUpdatingMe){
            this.setState({loanProdData: this.props.loanProdData})
        }
        if(this.props.fromDashboard){
            this.setState({loanProdData: this.props.loanProdData})
        }
    };

    getProps = () => {
        return this.props.returningProps || this.props;
    };

    render() {
        if (this.state.back) {
            let ChildComponent = this.state.callerName;
            let state = this.state.callerState;
            return <ChildComponent state={state} returningProps={this.state.callerProps}/>
        }
        else if (this.state.editLoanProduct) {
            this.state.recalledPage = this.state.lastPage;
            return <AddLoan productData={this.state.loanProdData} callerState={this.state} callerProps={this.getProps()}
                            callerName={LoanProductDetails} for={'Admin'} cancelAddLoanProduct={this.cancelEditLoanProduct}
                            updateMe={this.updateMe}
            />
        }
        else {
            return (
                <div>
                    <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT}/>
                    <h3>Loan Product Details</h3>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={9}>
                                    <Button color="rose" xs={12} sm={12} md={2} onClick={() => {
                                        this.deleteProduct()
                                    }}
                                    >Delete</Button>
                                    <Button xs={12} sm={12} md={2} onClick={() => {
                                        this.editProduct()
                                    }}
                                    >Edit</Button>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                    <Button color="danger" onClick={() => {
                                        this.setState({back: true})
                                    }} style={{float: 'right'}}>
                                        {this.getString().back}
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <Card>
                                <CardHeader style={{color: "#000"}}>
                                    <h4>Loan Description</h4>
                                </CardHeader>
                                <CardBody>
                                    <form>
                                        <GridContainer>
                                            <GridItem xs={12} sm={4} md={4}>
                                                <p><b>Name:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={8} md={8}>
                                                <p><b>{this.state.loanProdData.name}</b></p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={4} md={4}>
                                                <p><b>{this.getString().purpose}:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={8} md={8}>
                                                {
                                                    (this.state.loanProdData.purpose && this._isObject(this.state.loanProdData.purpose)) ?
                                                        <p><b>{this.state.loanProdData.purpose.join()}</b></p> : <p><b>{this.state.loanProdData.purpose}</b></p>
                                                }
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={4}>
                                                <p><b> Interest Rate:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={8}>
                                                <p><b>{this.state.loanProdData.interest_rate}% {this.state.loanProdData.interest_rate_frequency}</b></p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={4} md={4}>
                                                <p><b>Minimum Amount:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={8} md={8}>
                                                <p><b>{currencyFormat(this.state.loanProdData.minimum_amount)}</b></p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={4} md={4}>
                                                <p><b>Maximum Amount: </b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={8} md={8}>
                                                <p><b>{currencyFormat(this.state.loanProdData.maximum_amount)}</b></p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={4} md={4}>
                                                <p><b>Tenor:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={8} md={8}>
                                                <p><b>{this.state.loanProdData.tenor}</b>
                                                </p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={4} md={4}>
                                                <p><b>Penalty:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={8} md={8}>
                                                <p><b>{this.state.loanProdData.penalty}</b></p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={4} md={4}>
                                                <p><b>Administration Fee:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={8} md={8}>
                                                <p><b>{currencyFormat(this.state.loanProdData.administration_fee)}</b></p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={4} md={4}>
                                                <p><b>Processing Time:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={8} md={8}>
                                                <p><b>{this.state.loanProdData.processing_time}  (Hours)</b></p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={4} md={4}>
                                                <p><b>Nationality:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={8} md={8}>
                                                <p><b>{this.state.loanProdData.nationality}</b></p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={4} md={4}>
                                                <p><b>Repayment Frequency:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={8} md={8}>
                                                <p><b>{this.state.loanProdData.repayment_frequency}</b></p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={4} md={4}>
                                                <p><b>Payment Start Date:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={8} md={8}>
                                                <p><b>{this.state.loanProdData.payment_start_date}</b></p>
                                            </GridItem>
                                        </GridContainer>
                                    </form>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader style={{color: "#000"}}>
                                        <h4>Eligibility Criteria</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <form>
                                            <GridContainer>
                                                <GridItem xs={12} sm={4} md={4}>
                                                    <p><b>Legal Status:</b></p>
                                                </GridItem>
                                                <GridItem xs={12} sm={8} md={8}>
                                                    {
                                                        (this.state.loanProdData.legal_status && this._isObject(this.state.loanProdData.legal_status)) ?
                                                            <p><b>{this.state.loanProdData.legal_status.join()}</b></p> : <p><b>{this.state.loanProdData.legal_status}</b></p>
                                                    }
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={4} md={4}>
                                                    <p><b>Business Location:</b></p>
                                                </GridItem>
                                                <GridItem xs={12} sm={8} md={8}>
                                                    {
                                                        (this.state.loanProdData.business_location && this._isObject(this.state.loanProdData.business_location)) ?
                                                            <p><b>{this.state.loanProdData.business_location.join()}</b></p> : <p><b>{this.state.loanProdData.business_location}</b></p>
                                                    }
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={4} md={4}>
                                                    <p><b>Business Age:</b></p>
                                                </GridItem>
                                                <GridItem xs={12} sm={8} md={8}>
                                                    <p><b>{this.state.loanProdData.business_age}</b></p>
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={4} md={4}>
                                                    <p><b>Minimum Age of Applicant:</b></p>
                                                </GridItem>
                                                <GridItem xs={12} sm={8} md={8}>
                                                    <p><b>{this.state.loanProdData.minimum_age}</b></p>
                                                </GridItem>
                                            </GridContainer>
                                        </form>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            {
                                (this.state.loanProdData.required_documents && this.state.loanProdData.required_documents.length > 0)?
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Card>
                                            <CardHeader style={{color: "#000"}}>
                                                <h4>Required Documents</h4>
                                            </CardHeader>
                                            <CardBody>
                                                <form>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={6}>
                                                            <p><b>Name</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={6}>
                                                            <p><b>Status</b></p>
                                                        </GridItem>
                                                    </GridContainer>
                                                    {
                                                        this.state.loanProdData.required_documents.map(doc => {
                                                            return(
                                                                <GridContainer>
                                                                    <GridItem xs={12} sm={12} md={6}>
                                                                        <p>{doc.doc_name}</p>
                                                                    </GridItem>
                                                                    <GridItem xs={12} sm={12} md={6}>
                                                                        <p>{doc.status}</p>
                                                                    </GridItem>
                                                                </GridContainer>
                                                            );
                                                        })

                                                    }
                                                </form>
                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                    :
                                    null
                            }
                        </GridItem>
                    </GridContainer>
                </div>
            );
        }
    }
}


export default withStyles(styles)(LoanProductDetails);

import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../../../components/common/Grid/GridItem.jsx";
import GridContainer from "../../../components/common/Grid/GridContainer.jsx";
import Table from "@material-table/core";
import Card from "../../../components/common/Card/Card.jsx";
import CardHeader from "../../../components/common/Card/CardHeader.jsx";
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import $ from "jquery";
import {currencyFormat, dateFormat, compileQuery} from "../../../assets/js/utils/utils.js";
import frStrings from  "../../../assets/js/locales_fr.js";
import enStrings from  "../../../assets/js/locales_en.js";
import baseUrl from "../../../assets/js/config/const.js";

import cookies from "react-cookies";
import AccountDetails from "../../../components/afrijula/Super_Admin/AccountDetails.jsx";
import UserDetails from "../../../components/afrijula/Super_Admin/User_Details";
import ReactTimeAgo from "react-time-ago";
import Button from "../../common/CustomButtons/Button";
import AddUser from "../../../components/afrijula/Settings/Add_User.jsx";
import {ToastsContainer, ToastsContainerPosition, ToastsStore} from "react-toasts";
import Vouchers from "components/afrijula/Super_Admin/Vouchers.jsx";

class Home extends React.Component {

    constructor(props) {
        super(props);

        this.tableRef = React.createRef();
        this.tableOrgRef = React.createRef();
    }

    getString = () => {
        let strings;
        let lang = window.navigator.language;
        if(lang === 'en' || lang === 'en-US' ){
            strings = enStrings;
        }else{
            strings = frStrings;
        }
        return strings;
    };

    state = this.props.state || {
        back: false,
        userData: null,
        vouchers: false,

        paymentHeader:  [
            {title: this.getString().name, field: 'name', cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
            {title: this.getString().account_type, field: 'organization_type', defaultGroupOrder: 0 , cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
            {title: this.getString().amount, field: 'amount'},
            {title: this.getString().date, field: 'date', render: rowData => {return dateFormat(rowData.date)}},
            {title: this.getString().balance, field:'balance', render: rowData => {return currencyFormat(rowData.balance)}},
            {title: this.getString().status, field:'status', render: rowData =>{return (rowData.status) ? rowData.status : 'n/a' }},

        ],

        usersHeader:  [
            {title: this.getString().name, field: 'name', cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
            {title: this.getString().email, field:'email', cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
            {title: this.getString().last_active, field: 'last_login_date', render: rowData=>{return (rowData.last_login_date) ? <ReactTimeAgo date={new Date(rowData.last_login_date)} locale="en" />  : 'never'}, cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
            {title: this.getString().status, field: 'status', cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
        ],

        customerCount:0,
        totalCustomers: false,
        account_lists: [],
        org_list: [],
        orgPayments: [],

        tilesData: {
            tot_customers: '',
            agingdebotrs: '',
            tot_due_invoice: '',
        },
        orgData: null,

    };

    rowSelectOrg = (event, colData)  => {
        let id = colData._id.$oid;
        let _this = this;

        $.ajax({
            method: "GET",
            url: baseUrl + "/organizations_v2/"+id,
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            success: function (result) {
                _this.setState({orgData: result});
            }
        });

    };

    rowSelectUsers = (event, colData)  => {

        let id = colData._id;
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/account/users/"+id,
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            success: function (result) {
                _this.setState({userData: result});
            }
        });
    };

    loadUsers = (query,resolve,reject) => {
        let url = compileQuery(query, (baseUrl + "/account/users"),
            null, [{"by":"asc","attr":"name"}],
            null,{"from_admin": true},null);

        fetch(url, {dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {return response.json()})
            .then(result => {
                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total
                })
            });
    };

    loadOrgData = (query, resolve, reject) => {
        let url = compileQuery(query, (baseUrl + "/organizations_v2"),
            null, [{"by":"asc","attr":"name"}],
            null,{"from_admin": true},null);

        fetch(url, {dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {return response.json()})
            .then(result => {
                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total
                })
            });


    };

    deleteOrg = (rowData) => {
        const conf = window.confirm("Are you sure you want delete to organization. All related records will be removed");
        let _this = this;
        if(conf){
            rowData.forEach((data)=>{
                let id = data._id.$oid;
                $.ajax({
                    method: "PUT",
                    url: baseUrl + "/organizations_v2/"+id,
                    dataType: "json",
                    headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
                    data: {status: 'deleted'},

                    success: function (result) {
                        _this.displayToast("account successfully updated ","success");
                        _this.refreshOrganisation();
                    }.bind(this)
                });
            });
        }
    };

    displayToast = (message) => {
        return  ToastsStore.error(message,6000);
    };

    deleteUser = (rowData) =>{
        let response = window.confirm("User will be deleted!");

        if(response){
            let _this = this;
            $.ajax({
                method: "PUT",
                url: baseUrl + "/afrijula/afrijula/user_accounts",
                dataType: "json",
                headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
                data: {"UserId": rowData._id.$oid},

                success: function (result) {
                    {_this.displayToast("User successfully deleted.");}
                    _this.loadUsers();
                }.bind(this)
            });
        }

    };

    getProps = () => {
        return this.props.returningProps || this.props;
    };

    refreshOrganisation = () => {
        this.tableOrgRef.current && this.tableOrgRef.current.onQueryChange()
    };


    clearAll = () => {
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/administration/schedules/clear_all",
            data: {for:'invoice'},
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            success: function (result) {
                alert("all schedules cleared");
            }
        });
    }

    scheduleAll = () => {
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/administration/schedules/reschedule_all",
            data: {for:'invoice'},
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            success: function (result) {
                alert("all invoice reschedule");
            }
        });
    }

    loadVouchers = () => {
        this.setState({vouchers:true})
    }

    render() {

        const { classes } = this.getProps();

        if(this.state.vouchers) {
            this.state.vouchers = false;
            return <Vouchers callerState={this.state} callerProps={this.getProps()} callerName={Home} />;
        }else if(this.state.orgData){
            let data = this.state.orgData;
            this.state.orgData = null;
            return <AccountDetails callerState={this.state} callerProps={this.getProps()} callerName={Home} orgData={data}/>;
        } else if(this.state.userData){
            let data= this.state.userData;
            this.state.userData = null;
            return <UserDetails callerState={this.state} callerProps={this.getProps()} callerName={Home} userData={data}/>;
        }else if(this.state.edit){
            return <AddUser callerState={this.state} callerProps={this.getProps()} callerName={Home} for={'Admin'}/>
        }
        else{
            return (
                <div style={{marginTop:-40}}>
                    <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT}/>
                    <GridContainer>
                        <GridItem>
                            <h5>Schedules</h5>
                            {(cookies.load('userEmail') ==='obensouda@insistglobal.com') ? <span><Button xs={12} sm={12} md={2} onClick={this.clearAll} >{"clear all"}</Button>
                                <Button xs={12} sm={12} md={2} onClick={this.scheduleAll} >{"schedule all"}</Button><Button xs={12} sm={12} md={2} onClick={this.loadVouchers} >{"vouchers"}</Button></span>: null}


                            <Button xs={12} sm={12} md={2} onClick={() => {this.setState({edit:true})}} >{"add admin"}</Button>
                        </GridItem>
                    </GridContainer>
                    <h2 style={{textAlign: 'center'}}>Lecket Admin Dashboard</h2>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <Card>
                                <CardHeader >
                                    <h3>{this.getString().users}</h3>
                                </CardHeader>
                                <Table
                                    tableRef={this.tableRef}
                                    data={query=>new Promise((resolve,reject) => {
                                        this.loadUsers(query,resolve,reject);
                                    })}
                                    columns={this.state.usersHeader}
                                    onRowClick={this.rowSelectUsers}
                                    options={{
                                        exportButton: true,filtering: true,
                                        grouping:true,sorting:true,
                                        selection:true,showTitle:false,
                                        pageSize:10
                                    }}
                                    actions={[
                                        {
                                            icon: 'refresh',tooltip: 'Refresh',isFreeAction: true,
                                            onClick: () => this.tableRef.current && this.tableRef.current.onQueryChange(),
                                        }
                                    ]}
                                />
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader>
                                        <h3>{this.getString().organisation}</h3>

                                    </CardHeader>
                                    <Table
                                        data={query=>new Promise((resolve,reject) => {
                                            this.loadOrgData(query,resolve,reject);
                                        })}
                                        tableRef={this.tableOrgRef}
                                        columns={[
                                            {title: this.getString().name, field: 'name', cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
                                            {title: this.getString().account_type, field: 'organization_type', cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
                                            {title: this.getString().email, field:'email', cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
                                            {title: this.getString().number, field:'number', cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
                                            {title: this.getString().balance, field: 'balance', cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}, render: rowData=>{return currencyFormat(rowData.balance)}},
                                            {title: this.getString().activity, field: 'last_activity', cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}, render: rowData=>{return dateFormat(rowData.last_activity)}},
                                            {title: this.getString().status, field: 'status', cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
                                        ]}
                                        onRowClick={this.rowSelectOrg}
                                        options={{
                                            exportButton: true,filtering: true,
                                            grouping:true,sorting:true,
                                            selection:true,showTitle:false,
                                            pageSize:50
                                        }}
                                        actions={[
                                            {
                                                icon: 'refresh',tooltip: 'Refresh',isFreeAction: true,
                                                onClick: () => this.tableOrgRef.current && this.tableOrgRef.current.onQueryChange(),
                                            },
                                            {
                                                tooltip: 'Remove Selected sale',icon: 'delete',
                                                onClick: (event, rowData) =>{
                                                    this.deleteOrg(rowData)
                                                }
                                            }
                                        ]}
                                    />
                                </Card>
                            </GridItem>

                        </GridItem>
                    </GridContainer>
                </div>
            );
        }
    }
}

Home.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Home);

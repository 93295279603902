import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import GridItem from "../../../components/common/Grid/GridItem.jsx";
import GridContainer from "../../../components/common/Grid/GridContainer.jsx";
import Button from "../../../components/common/CustomButtons/Button.jsx";
import Card from "../../../components/common/Card/Card.jsx";
import CardHeader from "../../../components/common/Card/CardHeader.jsx";
import CardBody from "../../../components/common/Card/CardBody.jsx";
import Table from "mui-datatables";
import CardFooter from "../../../components/common/Card/CardFooter.jsx";
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import $ from "jquery";
import frStrings from  "../../../assets/js/locales_fr.js";
import enStrings from  "../../../assets/js/locales_en.js";
import baseUrl from "../../../assets/js/config/const.js";
import cookies from "react-cookies";
import Select from "react-select";
import countryList from 'react-select-country-list';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {ToastsContainer, ToastsContainerPosition, ToastsStore} from 'react-toasts';
const Country = require('country-js');


const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});

const currencies = countryList().getData();

const locales = [
    {
        value: 'en-US',
        label: 'en',
    },
    {
        value: 'fr-FR',
        label: 'fr',
    },
    {
        value: 'ca-ES',
        label: 'ca',
    },
];

const countries = countryList().getData();

class General_Info extends React.Component {

    getString = () => {
        var strings;
        var lang = window.navigator.language;
        if(lang === 'en' || lang ===  'en-US' ){
            strings = enStrings;
        }else{
            strings = frStrings;
        }
        return strings;
    };

    state = {

        defaults:[
            {
                value: 'yes',
                label: this.getString().yes,
            },
            {
                value: 'no',
                label: this.getString().no,
            },
        ],

        new_locations: [],

        company_name: '',
        phone: '',
        address: '',
        email: '',
        country: '',
        enable_notifications: true,



        location_name: '',
        manager: '',

        return_to_genInfo: false,
        locale: '',
        default_currency: '',
        enable_stock: '',
        stock_level: '',
        default_location: 'no',
        location: '',
        settings_id: '',
        account_id: '',

        add_location: false,
        countryName: 'Gambia',

        organisation: {
            company_name: '',
            address: '',
            country: 'Gambia',
            phone: '',
            email: '',

        },

        details: {
            locale: '',
            enable_notifications: true,
            stock_level: '',
            default_currency: 'GMD',
        },

        the_location:{
            name: '',
            default_location: '',
        },

        default: '',
        showForm: false,
        locationData: [],
        locationHeader:  [
            {name: this.getString().name},
            {name: this.getString().address},
            {name: this.getString().default},
        ],

    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    add_location = () => {
        this.setState({add_location:true});
    };

    handleAddLocation = () => {
        this.setState({
            showForm: true,
        });
    };


    tableChange = (action, tableState) => {

        //Perform query base on action ie search, filter etc...
        switch(action) {

            // if the user's action is a search
            case "onSearchChange": {
                $.ajax({
                    method: "GET",
                    url: baseUrl + "/afrijula/stocks/stock",
                    dataType: "json",
                    data: {"searchText": tableState.searchText},

                    success: function (res) {
                        console.log(res);

                    }
                });
                break;
            }

            case "changePage": {
                this.loadLocations();
                break;
            }

            case "search": {
                let text = tableState.searchText;
                for(var i = 0;i < this.state.data.length; i++){
                    if(this.state.data[i][0].toLowerCase() === text){
                        console.log("Customer "+text+" found at position "+i);
                    }
                }


            }
        }
    };
    displayToast = (message,status) => {
        if (status=="success")
            return  ToastsStore.success(message,6000);
        if (status=="error")
            return  ToastsStore.error(message,6000);
    };
    loadTableData = (page) => {

        let the_data = [];
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/account/account/-1",
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data:{"attrs":["company_name","address","country","contact_number",'contact_email','_id']},

            success: function (result) {
                this.setState({
                    company_name: result.name,
                    address: result.address,
                    country: result.country,
                    email: result.contact_email,
                    phone: result.contact_number,
                    account_id: result._id.$oid
                });
            }.bind(this)
        });

        $.ajax({
            method: "GET",
            url: baseUrl + "/account/settings/-1",
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data:{"attrs":["locale","package","term","term_charge","balance","max_users",'default_currency','_id','low_stock_level','enable_notifications']},

            success: function (result) {
                this.setState({
                    package: result.package,
                    locale: result.locale,
                    term: result.term,
                    term_charge: result.term_charge,
                    balance: result.balance,
                    max_users: result.max_users,
                    default_currency: result.default_currency,
                    settings_id: result._id.$oid,
                    stock_level: result.low_stock_level,
                    enable_notifications: result.enable_notifications
                });

            }.bind(this)
        });
        this.loadLocations();
    };

    loadLocations = () => {
        let the_data = [];
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/locations/location",
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data:{"attrs":["name","id","is_default","address"],
                "order": [{"by":"asc","attr":"name"}],"model":"Afrijula::Locations::Location"},
            success: function (result) {
                $.map(result,function (data) {
                    let arr = [];
                    arr[0] = data.name;
                    arr[1] = data.address;
                    arr[2] = data.is_default == true ? 'yes' : 'no';
                    arr[3] = data._id.$oid;

                    the_data.push(arr);
                });
                this.setState({locationData:the_data});
            }.bind(this)
        });
    };

    saveOrganisation = () => {
        this.state.organisation.company_name = this.state.company_name;
        this.state.organisation.address = this.state.address;
        this.state.organisation.phone = this.state.phone;
        this.state.organisation.email = this.state.email;
        this.state.organisation.country = this.state.country;
        let id = this.state.account_id;
        // let logo = document.getElementById("logo").value;
        let _this = this;

        $.ajax({
            method: "PUT",
            url: baseUrl+"/account/account/"+id,
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},

            data:{"company_name": this.state.organisation.company_name, "country": this.state.organisation.country,
                "address": this.state.organisation.address, "contact_phone": this.state.organisation.phone,
                "contact_email": this.state.organisation.email},

            success: function (result) {
                _this.displayToast(result.success,"success");
            }.bind(this)
        });
    };

    handleCurrency = value => {
        let curr = Country.search(value['value']);
        let _detail = this.state.details;
        let data;
        if(curr && curr[0]){
             data = curr[0].currency.currencyCode;
            _detail.default_currency = data;
            this.setState({
                details: _detail,
                countryName: value['label'],
            });
        }
    };

    saveSettings = () => {
        let _this = this;
        let locale = this.state.locale;
        let stock_level = this.state.stock_level;
        let enable_notifications = this.state.enable_notifications;
        let default_currency =  this.state.details.default_currency;
        let id = this.state.settings_id;
        //let userID = this.
        $.ajax({
            method: "PUT",
            url: baseUrl+"/account/settings/"+id,
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            //headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile")},
            data:{"locale": locale, "enable_notifications": enable_notifications, "stock_level": stock_level,"default_currency":default_currency},

            success: function (result) {
                _this.displayToast(result.success,"success");
            }.bind(this)
        });
    };

    handleNotification = (e)=>{
        let enable_notif = e.target.value === 'yes' ? true : false;
        this.setState({
            enable_notifications: enable_notif,
        });
    };

    updateCountry = (name, val) => {
        let _organisation = this.state.organisation;
        _organisation.country = val;
        this.setState({organisation: _organisation});
    };

    saveLocation = () =>{
        let location_name = this.state.location_name;
        let address = this.state.address;
        let is_default = this.state.default_location;
        let id = this.state.location_id;
        let _this = this;
        $.ajax({
            method: "POST",
            url: baseUrl + "/afrijula/locations/location/"+(id || ''),
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data:{"location_name":location_name, "address": address, "is_default": is_default},

            success: function (result) {

                _this.setState({
                    location_name: null,
                    address:null,
                    default_location: null,
                    showForm: false,
                });
                _this.tableChange('changePage');
                _this.displayToast("location added successfully","success");
            },
            error: function(error){
                alert(error.responseText);
            }
        });


    };

    rowSelect = (colData, cellMeta)  => {
        let id = this.state.locationData[cellMeta.dataIndex][3];
        let _this = this;

        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/locations/location/"+id,
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            success: function (result) {
                _this.setState({location_name: result.name, address: result.address, default_location: result.is_default});
                _this.setState({showForm: true});
            },
        });

    };

    renderForm = () => {
        const { classes } = this.props;
        return (

            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader >
                                <h4 className={classes.cardTitleWhite}>{this.getString().add_location}</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>

                                    <GridItem xs={12} sm={12} md={4}>
                                        <TextField
                                            autoFocus
                                            id="location_name"
                                            label={this.getString().name}
                                            name="location_name"
                                            className={classes.textField}
                                            value={this.state.location_name}
                                            onChange={e => {this.handleChange(e)}}
                                            margin="normal"
                                            variant="outlined"
                                        />

                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <TextField
                                            id="address"
                                            label={this.getString().address}
                                            name="address"
                                            className={classes.textField}
                                            value={this.state.address}
                                            onChange={e => {this.handleChange(e)}}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <TextField
                                            id="default_location"
                                            name="default_location"
                                            select
                                            label={this.getString().default}
                                            className={classes.textField}
                                            value={this.state.default_location}
                                            onChange={e => {this.handleChange(e)}}
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                            helperText={this.getString().default_chooser}
                                        >
                                            {this.state.defaults.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </GridItem>
                                </GridContainer>

                            </CardBody>
                            <CardFooter>
                                <Button color="success" onClick={this.saveLocation} >{this.getString().save}</Button>
                            </CardFooter>
                        </Card>
                    </GridItem>

                </GridContainer>
            </div>);
    };

    componentDidMount() {
        this.loadTableData();
    };

    render() {
        const { classes } = this.props;

        return (

            <div>
                <GridContainer>
                    <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT}/>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader >
                                <h4 className={classes.cardTitleWhite}>{this.getString().org_info}</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <input
                                            accept="image/*"
                                            className={classes.input}
                                            id="logo"
                                            multiple
                                            type="file"
                                            style={{display: 'none'}}
                                        />
                                        <label htmlFor="logo">
                                            <Button variant="contained" component="span" className={classes.button} title={this.getString().insert_logo}>
                                                <i className="material-icons">add_a_photo</i>
                                            </Button>
                                        </label>

                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <TextField
                                            autoFocus
                                            id="comapny_name"
                                            label={this.getString().company_name}
                                            name="company_name"
                                            className={classes.textField}
                                            value={this.state.company_name}
                                            onChange={e => {this.handleChange(e)}}
                                            margin="normal"
                                            variant="outlined"
                                        />

                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <TextField
                                            id="address"
                                            label={this.getString().address}
                                            name="address"
                                            className={classes.textField}
                                            value={this.state.address}
                                            onChange={e => {this.handleChange(e)}}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                </GridContainer>

                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={4} style = {{marginTop: 13+'px'}}>
                                        <span>{this.getString().country}</span>
                                        <Select options={countries} placeholder = {this.state.organisation.country} name="country" id="country"
                                                defaultValue ={this.state.organisation.country}  onChange={opt => {this.updateCountry('country',opt.label)}} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <TextField
                                            id="phone"
                                            label={this.getString().telephone}
                                            name="phone"
                                            className={classes.textField}
                                            value={this.state.phone}
                                            onChange={e => {this.handleChange(e)}}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <TextField
                                            id="email"
                                            label={this.getString().email}
                                            name="email"
                                            className={classes.textField}
                                            value={this.state.email}
                                            onChange={e => {this.handleChange(e)}}
                                            type="email"
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <CardFooter>
                                <Button color="success" onClick={this.saveOrganisation} >{this.getString().save_settings}</Button>
                            </CardFooter>
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader >
                                <h4 className={classes.cardTitleWhite}>{this.getString().settings}</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <span>{this.getString().locale}</span>
                                        <Select options={locales} placeholder={this.getString().locale} name="locale" id="locale"
                                                defaultValue={locales[0]} onChange={opt => {this.setState({locale: opt.label})}}  />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4} style={{marginTop: -14+'px'}}>
                                        <TextField
                                            autoFocus
                                            id="stock_level"
                                            label={this.getString().stock_level}
                                            name="stock_level"
                                            className={classes.textField}
                                            value={this.state.stock_level}
                                            onChange={e => {this.handleChange(e)}}
                                            type="number"
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4} style={{marginTop: -15+'px'}}>
                                        <h4>{this.getString().enable_notification}</h4>

                                        <RadioGroup
                                            aria-label="Gender"
                                            name="enable_notifications"
                                            className={classes.group}
                                            value={this.state.enable_notifications ? 'yes' : 'no'}
                                            onChange={e => this.handleNotification(e)}
                                        >
                                            <FormControlLabel value="no" control={<Radio />} label="no" />
                                            <FormControlLabel value="yes" control={<Radio />} label="yes" />
                                        </RadioGroup>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <span>{this.getString().currency}</span>
                                        <Select options={currencies} placeholder={this.state.countryName} name="currency" id="currency"
                                                value={this.state.details.default_currency} onChange={this.handleCurrency}  />
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <CardFooter>
                                <Button color="success" onClick={this.saveSettings} >{this.getString().save_settings}</Button>
                            </CardFooter>
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <Button xs={12} sm={12} md={2} color="primary" onClick={this.handleAddLocation}><i className="material-icons">my_location</i> {this.getString().add_location}</Button>
                                    </GridItem>
                                </GridContainer>

                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                { this.state.showForm ? this.renderForm() : null }
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader >
                                <h3 className={classes.cardTitleWhite}>{this.getString().location}</h3>
                            </CardHeader>
                            <Table
                                data = {this.state.locationData}
                                columns = {this.state.locationHeader}
                                onTableChange = {this.tableChange}
                                options={{
                                    search: false,
                                    serverSide: true,
                                    print: false,
                                    download: false,
                                    filter: false,
                                    viewColumns: false,
                                    selectableRows: false,
                                    pagination: false
                                }}
                            />
                        </Card>
                    </GridItem>

                </GridContainer>
            </div>
        );
    }
}

General_Info.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(General_Info);

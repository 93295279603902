import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import GridItem from "../../../components/common/Grid/GridItem.jsx";
import GridContainer from "../../../components/common/Grid/GridContainer.jsx";
import Button from "../../../components/common/CustomButtons/Button.jsx";
import Card from "../../../components/common/Card/Card.jsx";
import CardHeader from "../../../components/common/Card/CardHeader.jsx";
import CardBody from "../../../components/common/Card/CardBody.jsx";
import CardFooter from "../../../components/common/Card/CardFooter.jsx";
import frStrings from "../../../assets/js/locales_fr.js";
import enStrings from "../../../assets/js/locales_en.js";
import $ from "jquery";
import cookies from "react-cookies";
import baseUrl from "../../../assets/js/config/const.js";
import {ToastsContainer, ToastsContainerPosition, ToastsStore} from 'react-toasts';
import {getString} from "../../../assets/js/utils/utils.js";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import {displayError, validateNumberField} from "../../../assets/js/utils/utils";
import MenuItem from "@material-ui/core/MenuItem";
import ComboSelect from "../../common/CustomWidgets/ComboSelect";

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginBottom: 16,
    },
    menu: {
        width: 200,
    },
});

class AddLoanProduct extends React.Component {

    state = {

        product_name: '',
        business_age: '',
        administration_fee: '',
        interest_rate: '',
        interest_rate_frequency: '',
        interest_rate_frequencies: [
            {label: "Per Month", value: "Per Month"},
            {label: "Per Year", value: "Per Year"}
        ],
        processing_time: '',
        minimum_age: '',
        minimum_amount: '',
        loan_tenor: '',
        back: false,
        location: '',
        maximum_amount: '',
        payment_start_date: '',
        other_payment_date: '',
        penalty: '',
        legal_status: [],
        loan_purpose: [],
        client_nationality: '',
        business_location: [],
        repayment_frequency: '',
        application_form: {name: '', reference_number: '', file_name: ''},
        guarantor_form: {name: '', reference_number: '', file_name: ''},
        required_documents: [],
        orgId: this.props.orgId,
        callerName: this.props.callerName,
        callerProps: this.props.callerProps,
        callerState: this.props.callerState,
        client_nationalities: [
            {label: "Gambian", value: "Gambian"},
            {label: "Any", value: "Any"}
        ],
        repayment_frequencies: [
            {label: "Monthly", value: "Monthly"},
            {label: "Quarterly", value: "Quarterly"},
            {label: "Annually", value: "Annually"}
        ],
        start_dates: [
            {label: "One month after disbursement", value: "One month after disbursement"},
            {label: "Other", value: "Other"},
        ],
        productId: '',
        guarantor_form_id: '',
        application_form_id: '',
        application_form_file: '',
        guarantor_form_file: '',
    };

    getString = () => {
        var strings;
        var lang = window.navigator.language;
        if (lang === 'en' || lang === 'en-US') {
            strings = enStrings;
        } else {
            strings = frStrings;
        }
        return strings;
    };

    handleChange = (e) => {
        let name = e.target.name;
        let val = e.target.value;
        if ((
            (name === 'administration_fee' && val !== '') || (name === 'interest_rate' && val !== '') ||(name === 'loan_tenor' && val !== '') ||
            (name === 'business_age' && val !== '') || (name === 'minimum_age' && val !== '') || (name === 'processing_time' && val !== '') ||
            (name === 'minimum_amount' && val !== '') || (name === 'maximum_amount' && val !== '')) && !validateNumberField(val)) {
            this.displayToast("Only numbers allowed");
            return;
        }
        this.setState({
            [name]: val
        });
    };

    handleChangeRadio = (e) => {
        let docs = [...this.state.required_documents];
        let n = docs.length;
        if(n <= 0){
            docs.push({doc_name: e.target.name, status: e.target.value});
        }else{
            let exist = false;
            let i = 0
            for(; i < n; i++){
                if(docs[i].doc_name === e.target.name){
                    exist = true;
                    break;
                }
            }
            if(exist){
                docs[i] = {doc_name: e.target.name, status: e.target.value};
            }else{
                docs.push({doc_name: e.target.name, status: e.target.value});
            }
        }
        this.setState({
            required_documents: docs
        });
    };

    handleCheck = (e) => {
        let docs = [...this.state.required_documents];
        if (e.target.checked) {
            docs.push(e.target.name);
        } else {
            docs = docs.filter(item => item !== e.target.name)
        }
        this.setState({
            required_documents: docs
        });
    };

    saveLoanProduct = () => {
        let _this = this;
        let product_name = this.state.product_name;
        let administration_fee = this.state.administration_fee;
        let business_age = this.state.business_age;
        let minimum_age = this.state.minimum_age;
        let processing_time = this.state.processing_time;
        let maximum_amount = this.state.maximum_amount;
        let loan_tenor = this.state.loan_tenor;
        let payment_start_date = this.state.payment_start_date;
        let penalty = this.state.penalty;
        let minimum_amount = this.state.minimum_amount;
        let interest_rate = this.state.interest_rate;
        let interest_rate_frequency = this.state.interest_rate_frequency;
        let legal_status = this.state.legal_status;
        let loan_purpose = this.state.loan_purpose;
        let client_nationality = this.state.client_nationality;
        let business_location = this.state.business_location;
        let repayment_frequency = this.state.repayment_frequency;
        if (product_name === "") {
            _this.displayToast("Product name can't be empty", "error");
            document.getElementById("product_name").focus();
            return;
        }
        if (business_age === "" || !validateNumberField(business_age)) {
            _this.displayToast("Age of Business takes only numbers", "error");
            document.getElementById("business_age").focus();
            return;
        }

        if (minimum_amount === "" || !validateNumberField(minimum_amount)) {
            _this.displayToast("Minimum amount takes only numbers", "error");
            document.getElementById("minimum_amount").focus();
            return;
        }

        if (minimum_age === "" || !validateNumberField(minimum_age)) {
            _this.displayToast("Minimum Client age takes only numbers", "error");
            document.getElementById("minimum_age").focus();
            return;
        }

        if (processing_time === "" || !validateNumberField(processing_time)) {
            _this.displayToast("Processing time takes only numbers", "error");
            document.getElementById("processing_time").focus();
            return;
        }

        if (maximum_amount === "" || !validateNumberField(maximum_amount)) {
            _this.displayToast("Maximum amount takes only numbers", "error");
            document.getElementById("maximum_amount").focus();
            return;
        }

        if (administration_fee === "" || !validateNumberField(administration_fee)) {
            _this.displayToast("Maximum amount takes only numbers", "error");
            document.getElementById("administration_fee").focus();
            return;
        }

        if (interest_rate === "" || !validateNumberField(interest_rate)) {
            _this.displayToast("Interest rate takes only numbers", "error");
            document.getElementById("interest_rate").focus();
            return;
        }

        let _method = this.state.productId === '' ? "POST" : "PUT";

        $.ajax({
            method: _method,
            url: baseUrl + "/afrijula/loans/loan_products/"+this.state.productId,
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {
                name: product_name,
                administration_fee: administration_fee,
                processing_time: processing_time,
                maximum_amount: maximum_amount,
                tenor: loan_tenor,
                payment_start_date: payment_start_date,
                penalty: penalty,
                business_age: business_age,
                minimum_amount: minimum_amount,
                minimum_age: minimum_age,
                interest_rate: interest_rate,
                legal_status: legal_status,
                purpose: loan_purpose,
                nationality: client_nationality,
                required_documents: this.state.required_documents,
                business_location: business_location,
                repayment_frequency: repayment_frequency,
                interest_rate_frequency: interest_rate_frequency,
                forms: [this.state.application_form, this.state.guarantor_form]

            },
            success: function (result) {
                _this.displayToast(result.success, "success");
                if(_this.props.cancelAddLoanProduct){
                    _this.props.cancelAddLoanProduct();
                }
                if(_this.props.updateMe){
                    _this.props.updateMe(result.product);
                }
            }.bind(this),
            error: function (error) {
                _this.displayToast(error.responseText, "error");
            }
        });
    };

    handleChangeForm = (event, type) => {
        let _form = type === "application" ? {...this.state.application_form} : {...this.state.guarantor_form};
        _form.name = event.target.name;
        let file = event.target.files[0];
        _form.file_name = file.name;

        type === "application" ? this.setState({application_form: _form}) : this.setState({guarantor_form: _form});
        this.saveFile(file, type);
    };

    saveFile = (file, type) => {
        let _this = this;
        let formData = new FormData();
        formData.append('requirement', file);
        $.ajax({
            method: "POST",
            url: baseUrl + "/afrijula/loans/loan_documents",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key'),
            },
            data: formData,
            contentType: false,
            processData: false,
            success: function (result) {
                if(type === "application"){
                    let _form = {..._this.state.application_form};
                    _form.reference_number = result.reference;
                    this.setState({application_form: _form});
                }else{
                    let _form = {..._this.state.guarantor_form};
                    _form.reference_number = result.reference;
                    this.setState({guarantor_form: _form});
                }
            }.bind(this),
            error: function (error) {
                displayError(error.responseText);
            }
        })
    };

    displayToast = (message, status) => {
        if (status === "success")
            return ToastsStore.success(message, 6000);
        else
            return ToastsStore.error(message, 6000);
    };

    updateFields = (product) => {
        product.required_documents.forEach(doc => {
            if(doc.status === "Required"){
                document.getElementsByName(doc.doc_name)[0].checked = true;
            }else{
                document.getElementsByName(doc.doc_name)[1].checked = true;
            }
        });

        this.setState({
            product_name: product.name,
            administration_fee: parseFloat(product.administration_fee.cents)/100,
            business_age: product.business_age,
            minimum_age: product.minimum_age,
            processing_time: product.processing_time,
            maximum_amount: product.maximum_amount.cents/100,
            loan_tenor: product.tenor,
            payment_start_date: product.payment_start_date,
            penalty: product.penalty,
            minimum_amount: product.minimum_amount.cents/100,
            interest_rate: product.interest_rate,
            legal_status: product.legal_status,
            loan_purpose: product.purpose,
            client_nationality: product.nationality,
            business_location: product.business_location,
            repayment_frequency: product.repayment_frequency,
            interest_rate_frequency: product.interest_rate_frequency,
            productId: product._id.$oid,
            required_documents: product.required_documents,
        });
    };

    handleCheckLocation = (e) => {
        let docs = [...this.state.business_location];
        if (e.target.checked) {
            docs.push(e.target.name);
        } else {
            docs = docs.filter(item => item !== e.target.name)
        }
        this.setState({
            business_location: docs
        });
    };

    handleCheckStats = (e) => {
        let docs = [...this.state.legal_status];
        if (e.target.checked) {
            docs.push(e.target.name);
        } else {
            docs = docs.filter(item => item !== e.target.name)
        }
        this.setState({
            legal_status: docs
        });
    };

    handleCheckPurpose = (e) => {
        let docs = [...this.state.loan_purpose];
        if (e.target.checked) {
            docs.push(e.target.name);
        } else {
            docs = docs.filter(item => item !== e.target.name)
        }
        this.setState({
            loan_purpose: docs
        });
    };

    getForms = () => {
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/loans/loan_documents/"+this.props.productData._id.$oid,
            dataType: "json",
            headers: {"Authorization": "token " + cookies.load("token"), "UserProfile": cookies.load("profile"), "UserKey": cookies.load('User-Key'),},
            success: function (result) {
                if(result.loan_forms.length > 0 && (result.loan_forms[0].name === "application_form" || result.loan_forms[0].name === "guarantor_form")){
                    if(result.loan_forms.filter(f => f.name === "application_form")[0]){
                        this.setState({
                            application_form: {...result.loan_forms.filter(f => f.name === "application_form")[0]},
                            application_form_id: result.loan_forms.filter(f => f.name === "application_form")[0].reference_number,
                            application_form_file: result.loan_forms.filter(f => f.name === "application_form")[0].file_name,
                        })
                    }
                    if(result.loan_forms.filter(f => f.name === "guarantor_form")[0]){
                        this.setState({
                            guarantor_form_id: result.loan_forms.filter(f => f.name === "guarantor_form")[0].reference_number,
                            guarantor_form_file: result.loan_forms.filter(f => f.name === "guarantor_form")[0].file_name,
                            guarantor_form: {...result.loan_forms.filter(f => f.name === "guarantor_form")[0]}
                        })
                    }
                }
            }.bind(this),
            error: function (error) {
                displayError(error.responseText);
            }
        });
    };

    removeForm = (ref_no, id) => {
        let _this = this;;
        $.ajax({
            method: "DELETE",
            url: baseUrl + "/afrijula/loans/loan_documents/"+ref_no,
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key'),
            },
            data: {_type: "fsp_form"},
            success: function (result) {
                if(id === "application_form_id" ){
                    this.setState({
                        application_form_id: '',
                        application_form_file: '',
                    })
                }
                if(id === "guarantor_form_id"){
                    this.setState({
                        guarantor_form_id: '',
                        guarantor_form_file: '',
                    });
                }
            }.bind(this),
            error: function (error) {
                // displayError(error.responseText);
            }
        });
    };

    componentDidMount() {
        if(this.props.productData){
            this.updateFields(this.props.productData);
            this.getForms();
        }
    }

    render() {
        const {classes} = this.props;

        if (this.state.back) {
            let ChildComponent = this.state.callerName;
            return <ChildComponent state={this.state.callerState} returningProps={this.state.callerProps}/>
        }
        return (
            <div>
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT}/>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader>
                                <h4 className={classes.cardTitleWhite}>{this.props.productData ? "Edit Loan Product" : "Add Loan Product" }</h4>
                            </CardHeader>
                            <CardBody>
                                <div><h2>Loan Description</h2></div>
                                <GridContainer>
                                    <GridItem xs={12} sm={3} md={3}>
                                        <TextField
                                            id="product_name"
                                            label={'Product Name'}
                                            name="product_name"
                                            className={classes.textField}
                                            value={this.state.product_name}
                                            onChange={e => {
                                                this.handleChange(e)
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={3} md={3}>
                                        <TextField
                                            id="minimum_amount"
                                            label={'Mininum amount'}
                                            name="minimum_amount"
                                            className={classes.textField}
                                            value={this.state.minimum_amount}
                                            onChange={e => {
                                                this.handleChange(e)
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={3} md={3}>
                                        <TextField
                                            id="maximum_amount"
                                            label={'Maximum Amount'}
                                            name="maximum_amount"
                                            className={classes.textField}
                                            value={this.state.maximum_amount}
                                            onChange={e => {
                                                this.handleChange(e)
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={3} md={3}>
                                        <TextField
                                            id="processing_time"
                                            label={'Processing Time (Hours)'}
                                            name="processing_time"
                                            className={classes.textField}
                                            value={this.state.processing_time}
                                            onChange={e => {
                                                this.handleChange(e)
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={3} md={3}>
                                        <TextField
                                            id="loan_tenor"
                                            label={'Loan Tenor (Months)'}
                                            name="loan_tenor"
                                            className={classes.textField}
                                            value={this.state.loan_tenor}
                                            onChange={e => {
                                                this.handleChange(e)
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={3} md={3}>
                                        <TextField
                                            id="administration_fee"
                                            label="Administration Fee(%)"
                                            name="administration_fee"
                                            className={classes.textField}
                                            value={this.state.administration_fee}
                                            onChange={e => {
                                                this.handleChange(e)
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={3} md={3}>
                                        <TextField
                                            id="interest_rate"
                                            label={'Interest Rate(%)'}
                                            name="interest_rate"
                                            className={classes.textField}
                                            value={this.state.interest_rate}
                                            onChange={e => {
                                                this.handleChange(e)
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={3} md={3}>
                                        <TextField
                                            id="interest_rate_frequency"
                                            select
                                            label="Interest Rate Frequency"
                                            name="interest_rate_frequency"
                                            style={{width: '67%', marginLeft: 8}}
                                            value={this.state.interest_rate_frequency}
                                            onChange={e => {
                                                this.handleChange(e)
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                        >
                                            {this.state.interest_rate_frequencies.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={3} md={3}>
                                        <TextField
                                            id="repayment_frequency"
                                            select
                                            label="Repayment Frequency"
                                            name="repayment_frequency"
                                            style={{width: '67%', marginLeft: 8}}
                                            value={this.state.repayment_frequency}
                                            onChange={e => {
                                                this.handleChange(e)
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                        >
                                            {this.state.repayment_frequencies.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </GridItem>
                                    <GridItem xs={12} sm={3} md={3}>
                                        <TextField
                                            id="penalty"
                                            label="Penalty"
                                            name="penalty"
                                            className={classes.textField}
                                            value={this.state.penalty}
                                            onChange={e => {
                                                this.handleChange(e)
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={3} md={3}>
                                        <TextField
                                            id="payment_start_date"
                                            select
                                            label="Payment Start Date"
                                            name="payment_start_date"
                                            style={{width: '70%', marginLeft: 8}}
                                            value={this.state.payment_start_date}
                                            onChange={e => {
                                                this.handleChange(e)
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                        >
                                            {this.state.start_dates.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </GridItem>
                                    {
                                        (this.state.payment_start_date === 'Other') ?
                                            <GridItem xs={12} sm={3} md={3}>
                                                <TextField
                                                    id="other_payment_date"
                                                    label="Other Start Date"
                                                    name="other_payment_date"
                                                    className={classes.textField}
                                                    value={this.state.other_payment_date}
                                                    onChange={e => {
                                                        this.handleChange(e)
                                                    }}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            </GridItem> : null
                                    }
                                </GridContainer>
                                <div><h2>Eligibility Criteria</h2></div>
                                <GridContainer>
                                    <GridItem xs={12} sm={3} md={3}>
                                        <TextField
                                            id="client_nationality"
                                            select
                                            label={'Nationality of Client'}
                                            name="client_nationality"
                                            style={{width: '70%'}}
                                            value={this.state.client_nationality}
                                            onChange={e => {
                                                this.handleChange(e)
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                        >
                                            {this.state.client_nationalities.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </GridItem>
                                    <GridItem xs={12} sm={3} md={3}>
                                        <TextField
                                            id="business_age"
                                            label={'Age of business (Years)'}
                                            name="business_age"
                                            style={{width: '70%'}}
                                            className={classes.textField}
                                            value={this.state.business_age}
                                            onChange={e => {
                                                this.handleChange(e)
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={3} md={3}>
                                        <TextField
                                            id="minimum_age"
                                            label={'Minimum Age of Applicant'}
                                            name="minimum_age"
                                            style={{width: '70%'}}
                                            className={classes.textField}
                                            value={this.state.minimum_age}
                                            onChange={e => {
                                                this.handleChange(e)
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                </GridContainer><br/>
                                <GridContainer>
                                    <GridItem xs={12} sm={3} md={3}>
                                        <span>Legal Status</span>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <Checkbox
                                                checked={this.state.legal_status.includes("Sole Proprietorship")}
                                                name="Sole Proprietorship" onChange={e => {this.handleCheckStats(e)}}
                                            />
                                            Sole Proprietorship
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <Checkbox
                                                checked={this.state.legal_status.includes("LLC")}
                                                name="LLC" onChange={e => {this.handleCheckStats(e)}}
                                            />
                                            LLC
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <Checkbox
                                                checked={this.state.legal_status.includes("Holding Company")}
                                                name="Holding Company" onChange={e => {this.handleCheckStats(e)}}
                                            />
                                                Holding Company
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <Checkbox
                                                checked={this.state.legal_status.includes("Partnerships")}
                                                name="Partnerships" onChange={e => {this.handleCheckStats(e)}}
                                            />
                                                Partnerships
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <Checkbox
                                                checked={this.state.legal_status.includes("CBOs")}
                                                name="CBOs" onChange={e => {this.handleCheckStats(e)}}
                                            />
                                                CBOs
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <Checkbox
                                                checked={this.state.legal_status.includes("NGOs")}
                                                name="NGOs" onChange={e => {this.handleCheckStats(e)}}
                                            />
                                                NGOs
                                        </GridItem>
                                    </GridItem>
                                    <GridItem xs={12} sm={3} md={3}>
                                        <span>Loan Purpose</span>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <Checkbox
                                                checked={this.state.loan_purpose.includes("Personal")}
                                                name="Personal" onChange={e => {this.handleCheckPurpose(e)}}
                                            />
                                                Personal
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <Checkbox
                                                checked={this.state.loan_purpose.includes("Investment")}
                                                name="Investment" onChange={e => {this.handleCheckPurpose(e)}}
                                            />
                                                Investment
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <Checkbox
                                                checked={this.state.loan_purpose.includes("Working Capital")}
                                                name="Working Capital" onChange={e => {this.handleCheckPurpose(e)}}
                                            />
                                                Working Capital
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <Checkbox
                                                checked={this.state.loan_purpose.includes("Fixed Assets")}
                                                name="Fixed Assets" onChange={e => {this.handleCheckPurpose(e)}}
                                            />
                                                Fixed Assets
                                        </GridItem>
                                    </GridItem>
                                    <GridItem xs={12} sm={3} md={3}>
                                        <span style={{padding: 5}}>Location of business</span>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <Checkbox
                                                checked={this.state.business_location.includes("National")}
                                                name="National" onChange={e => {this.handleCheckLocation(e)}}
                                            />
                                                National
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <Checkbox
                                                checked={this.state.business_location.includes("International")}
                                                name="International" onChange={e => {this.handleCheckLocation(e)}}
                                            />
                                                International
                                        </GridItem>
                                    </GridItem>
                                </GridContainer>
     {/*                           <div><h2>Documents Required</h2></div>
                                <GridContainer>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <ComboSelect />
                                    </GridItem>
                                </GridContainer>*/}
                                <GridContainer style={{padding: 5}}>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>Business Registration   </span><input onChange={e=>this.handleChangeRadio(e)} type="radio" name="Business Registration" id="Required" value="Required"/>  Required <input onChange={e=>this.handleChangeRadio(e)} type="radio" name="Business Registration" id="Optional" value="Optional"/> Optional
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>GPPA Registration   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="GPPA Registration" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="GPPA Registration" value="Optional"/> Optional
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>GCCI Registration    </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="GCCI Registration/Business Registration" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="GCCI Registration/Business Registration" value="Optional"/> Optional
                                    </GridItem>
                                </GridContainer>
                                <GridContainer style={{padding: 5}}>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>Trade License   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Trade License" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Trade License" value="Optional"/> Optional
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>Guarantor's TIN   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Guarantor's TIN" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Guarantor's TIN" value="Optional"/> Optional
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>Business TIN   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Business TIN" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Business TIN" value="Optional"/> Optional
                                    </GridItem>
                                </GridContainer>
                                <GridContainer style={{padding: 5}}>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>Duty Receipts   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Duty Receipts" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Duty Receipts" value="Optional"/> Optional
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>GRA Tax Clearance   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="GRA Tax Clearance" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="GRA Tax Clearance" value="Optional"/> Optional
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>3 Months Payslip   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="3 Months Payslip" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="3 Months Payslip" value="Optional"/> Optional
                                    </GridItem>
                                </GridContainer>
                                <GridContainer style={{padding: 5}}>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>ID/Passport of Applicant   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="ID/Passport of Applicant" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="ID/Passport of Applicant" value="Optional"/> Optional
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>Applicant's TIN   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Applicant's TIN" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Applicant's TIN" value="Optional"/> Optional
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>All Bank Statements   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="All Bank Statements" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="All Bank Statements" value="Optional"/> Optional
                                    </GridItem>
                                </GridContainer>
                                <GridContainer style={{padding: 5}}>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>Collateral and collateral substitutes   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Collateral and collateral substitutes" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Collateral and collateral substitutes" value="Optional"/> Optional
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>Guarantor's ID   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Guarantor's ID" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Guarantor's ID" value="Optional"/> Optional
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>Employment status   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Employment status" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Employment status" value="Optional"/> Optional
                                    </GridItem>
                                </GridContainer>
                                <GridContainer style={{padding: 5}}>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>Commitment Letter   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Commitment Letter" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Commitment Letter" value="Optional"/> Optional
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>Irrecoverable salary domiciliation   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Irrecoverable salary domiciliation" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Irrecoverable salary domiciliation" value="Optional"/> Optional
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>Payment domiciliation of contract proceeds   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Payment domiciliation of contract proceeds" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Payment domiciliation of contract proceeds" value="Optional"/> Optional
                                    </GridItem>
                                </GridContainer>
                                <GridContainer style={{padding: 5}}>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>Solidarity group form   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Solidarity group form" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Solidarity group form" value="Optional"/> Optional
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>Contract Award   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Contract Award" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Contract Award" value="Optional"/> Optional
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>Application Form   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Application Form" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Application Form" value="Optional"/> Optional
                                    </GridItem>
                                </GridContainer>
                                <GridContainer style={{padding: 5}}>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>Guarantor Form   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Guarantor Form" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Guarantor Form" value="Optional"/> Optional
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>Offer Letter   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Offer Letter" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Offer Letter" value="Optional"/> Optional
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>Application Fee   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Application Fee" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Application Fee" value="Optional"/> Optional
                                    </GridItem>
                                </GridContainer>
                                <GridContainer style={{padding: 5}}>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>Savings Account   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Savings Account" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Savings Account" value="Optional"/> Optional
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>Lease Title Deed   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Lease Title Deed" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Lease Title Deed" value="Optional"/> Optional
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>Ownership Transfer   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Ownership Transfer" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Ownership Transfer" value="Optional"/> Optional
                                    </GridItem>
                                </GridContainer>
                                <GridContainer style={{padding: 5}}>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>Alkalo transfer with sketch plan   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Alkalo transfer with sketch plan" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Alkalo transfer with sketch plan" value="Optional"/> Optional
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>Copy of key   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Copy of key" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Copy of key" value="Optional"/> Optional
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>Photocopy of insurance   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Photocopy of insurance" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Photocopy of insurance" value="Optional"/> Optional
                                    </GridItem>
                                </GridContainer>
                                <GridContainer style={{padding: 5}}>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>Certificate from Central Bank   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Certificate from Central Bank" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Certificate from Central Bank" value="Optional"/> Optional
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>Valuation report   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Valuation report" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Valuation report" value="Optional"/> Optional
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>Affidavit   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Affidavit" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Affidavit" value="Optional"/> Optional
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <span>Collateral   </span><input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Collateral" value="Required"/>  Required <input type="radio" onChange={e=>this.handleChangeRadio(e)} name="Collateral" value="Optional"/> Optional
                                    </GridItem>
                                </GridContainer><br/>
                                <GridContainer>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <span><h4>Application Form</h4></span>
                                        <input
                                            color="primary"
                                            accept="image/*,.pdf"
                                            type="file"
                                            id="application_form"
                                            name="application_form"
                                            onChange={e =>this.handleChangeForm(e, "application")}
                                        />{this.state.application_form_file}<span id={this.state.application_form_id} onClick={(e) => this.removeForm(e.target.id, "application_form_id")} style={{cursor:'pointer', color: 'red'}}>{this.state.application_form_file  !== '' ? "  X" : ""}</span>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <span><h4>Guarantor Form</h4></span>
                                        <input
                                            color="primary"
                                            accept="image/*,.pdf"
                                            type="file"
                                            id="guarantor_form"
                                            name="guarantor_form"
                                            onChange={e =>this.handleChangeForm(e, "guarantor")}
                                        />{this.state.guarantor_form_file}<span id={this.state.guarantor_form_id} onClick={(e) => this.removeForm(e.target.id, "guarantor_form_id")} style={{cursor:'pointer', color: 'red'}}>{this.state.guarantor_form_file  !== '' ? "  X" : ""}</span>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <CardFooter>
                                <GridItem xs={12} sm={12} md={6}>
                                    <Button xs={12} sm={6} md={2} color="danger" onClick={this.props.cancelAddLoanProduct}
                                    >{getString().cancel}</Button>
                                    <Button color="success"
                                            onClick={this.saveLoanProduct}>{this.getString().save_settings}</Button>
                                </GridItem>
                            </CardFooter>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }

}

AddLoanProduct.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddLoanProduct);

import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import EmailIcon from '@material-ui/icons/Email';
import View from '@material-ui/icons/Visibility';
import AccessTime from "@material-ui/icons/AccessTime";
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import 'assets/css/bootstrap.css';
// core components
import Table from "@material-table/core";
import cookies from "react-cookies";
import Card from "../../../components/common/Card/Card.jsx";
import CardHeader from "../../../components/common/Card/CardHeader.jsx";
import CardBody from "../../../components/common/Card/CardBody.jsx";
import CardIcon from "../../../components/common/Card/CardIcon.jsx";
import CardFooter from "../../../components/common/Card/CardFooter.jsx";
import GridItem from "../../../components/common/Grid/GridItem.jsx";
import GridContainer from "../../../components/common/Grid/GridContainer.jsx";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import baseUrl from "assets/js/config/const.js";
import $ from "jquery";
import {ToastsContainer, ToastsContainerPosition, ToastsStore} from "react-toasts";
import Button from "../../common/CustomButtons/Button";
import Select from "react-select";
import {
    compileQuery,
    currencyFormat,
    setGlobal,
    dateFormat,
    showReportUtility,
    getString,
    displaySuccess, displayError
} from "../../../assets/js/utils/utils";
import AccountDetails from "../Super_Admin/AccountDetails";
import Vouchers from "components/afrijula/Super_Admin/Vouchers.jsx";
import AddUser from "components/afrijula/Settings/Add_User";
import Partner from "components/afrijula/Settings/Partners";
import jwt from "jsonwebtoken";

const packages = ["Bsc", "Std", "Pr", "Pp"];

//Temporary definition for the Coming Soon component
const ComingSoon = () => <h3>Dashboard Coming Soon!</h3>;

class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.tableAllOrgRef = React.createRef();
        this.tableActiveOrgRef = React.createRef();
        this.tableInActiveOrgRef = React.createRef();

        this.state = this.props.state || {
            products: [
                {
                    label: "Afrijula",
                    value: "Afrijula",
                },
                {
                    label: "Jangal",
                    value: "Jangal::JangalSchool",
                },
                {
                    label: "Kodo",
                    value: "Kodo",
                }
            ],
            lastPage: null,
            recalledPage: null,
            product: null,
            orgData: null,
            iframeUrl: null,
            vouchers: false,
            totalOrgs: null,
            totalInactiveOrgs: null,
            totalActiveOrgs: null,

            latestOrgs: [],
            edit: false,
            pendingUsers: false,
            partners: false
        }
        this.initDashboard();
    };

    createLegend = (json) => {
        let legend = [];
        let names = json["names"];
        for (let i = 0; i < names.length; i++) {
            let end = (i < names.length - 1) ? ", " : "";
            legend.push(packages[i] + ": " + names[i] + end);
        }
        return legend;
    };

    initDashboard = () => {

        var METABASE_SITE_URL = "https://analytics.lecket.gm";
        var METABASE_SECRET_KEY = "6eb957ffa2ebc936913f76af11032d5874274bc5a038fe83e04472106d3e4ce8";

        var payload = {
            resource: { dashboard: 2 },
            params: {
            }
        };
        var token = jwt.sign(payload, METABASE_SECRET_KEY);

        this.state.iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=true&titled=false";

    }

    resendActivate = (rowData) => {
        if(rowData.length === 0){
            return;
        }
        if(rowData.length > 1){
            alert("You may only send to one account a time")
            return;
        }
        let id = rowData[0]._id;
        let _this = this;
        $.ajax({
            method: "PUT",
            url: baseUrl + "/account/users/"+id+"/resend_activate",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data:{influence: this.state.product.value},
            dataType: "json",
            success: function (data) {
                alert('Message sent')
            }.bind(this),
            error: function(e){
                alert(e.responseText, "success");
            }
        });
    }

    getProps = () => {
        return this.props.returningProps || this.props;
    };

    loadOrgData = (query, resolve, reject) => {
        let _this = this;
        let filter = "_type: '" + this.state.product.value + "'";
        let url = compileQuery(query, (baseUrl + "/organizations_v2"),
            null, [{"by": "asc", "attr": "created_at"}],
            null, {"from_admin": true}, filter);

        this.state.lastPage = query.page;
        this.state.lastSort = query.orderBy


        fetch(url, {
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            }
        })
            .then(response => {
                return response.json()
            })
            .then(result => {
                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total
                })
            });
    };

    loadActiveOrgData = (query, resolve, reject) => {
        let filter = "_type: '" + this.state.product.value + "', status: 'active'";
        let url = compileQuery(query, (baseUrl + "/organizations_v2"),
            null, [{"by": "desc", "attr": "created_at"}],
            null, {"from_admin": true}, filter);

        fetch(url, {
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            }
        })
            .then(response => {
                return response.json()
            })
            .then(result => {
                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total
                })
            });
    };

    loadInactiveUserData = (query, resolve, reject) => {
        let url = compileQuery(query, (baseUrl + "/account/users/"),
            null, [{"by": "desc", "attr": "created_at"}],
            null, {"registered": 'pending'}, null);

        fetch(url, {
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            }
        })
            .then(response => {
                return response.json()
            })
            .then(result => {
                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total
                })
            });
    }

    loadInActiveOrgData = (query, resolve, reject) => {
        let filter = "_type: '" + this.state.product.value + "', status: 'unactive'";
        let url = compileQuery(query, (baseUrl + "/organizations_v2"),
            null, [{"by": "desc", "attr": "created_at"}],
            null, {"from_admin": true}, filter);

        fetch(url, {
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            }
        })
            .then(response => {
                return response.json()
            })
            .then(result => {
                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total
                })
            });
    };

    loadDashData = () => {
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/afrijula/dashboard",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            dataType: "json",
            data: {count: ['active_inactive_orgs']},
            success: function (data) {
                let dd = data.counts.active_inactive_orgs;

                _this.setState({totalInactiveOrgs: dd.active, totalActiveOrgs: dd.inactive});
            }.bind(this)
        });
    };

    rowSelectOrg = (event, colData) => {
        let id = colData._id.$oid;
        let _this = this;

        $.ajax({
            method: "GET",
            url: baseUrl + "/organizations_v2/" + id,
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            success: function (result) {
                _this.setState({orgData: result});
            }
        });

    };

    deleteOrg = (rowData) => {
        const conf = window.confirm("Are you sure you want delete to organization. All related records will be removed");
        let _this = this;
        if (conf) {
            rowData.forEach((data) => {
                let id = data._id.$oid;
                $.ajax({
                    method: "PUT",
                    url: baseUrl + "/organizations_v2/" + id,
                    dataType: "json",
                    headers: {
                        "Authorization": "token " + cookies.load("token"),
                        "UserProfile": cookies.load("profile"),
                        "UserKey": cookies.load('User-Key')
                    },
                    data: {status: 'deleted'},

                    success: function (result) {
                        _this.displayToast("account successfully updated ", "success");
                        _this.refreshOrganisation();
                    }.bind(this)
                });
            });
        }
    };


    scheduleAll = () => {
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/administration/schedules/reschedule_all",
            data: {for: 'invoice'},
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            success: function (result) {
                displaySuccess("all invoice reschedule");
            }
        });
    };

    setProduct = prod => {
        this.setState({
            product: prod
        });
    };

    loadVouchers = () => {
        this.setState({vouchers: true})
    };

    cancelAddUser = () => {
        this.setState(prevState => ({
            edit: !prevState.edit
        }))
    };

    recallPage = () =>{
        const page = this.state.recalledPage;
        this.state.recalledPage = null;
        return page;
    };
    recallSort = () => {
        const order = this.state.lastSort;
        this.state.lastSort = null;
        return order;
    };


    componentDidMount() {
        this.loadDashData();
        let prod = this.state.products[0];
        this.setProduct(prod);
        setGlobal(this);
    };

    render() {
        let _state = this.state;
        const {classes} = this.getProps();
        if (_state.vouchers) {
            _state.vouchers = false;
            this.state.recalledPage = this.state.lastPage;
            return <Vouchers callerState={_state} callerProps={this.getProps()}
                             callerName={Dashboard}
            />;
        }
        if (_state.orgData) {
            let data = _state.orgData;
            _state.orgData = null;
            this.state.recalledPage = this.state.lastPage;
            return <AccountDetails callerState={_state} callerProps={this.getProps()}
                                   callerName={Dashboard}
                                   orgData={data}
            />;
        } else if (_state.edit) {
            this.state.recalledPage = this.state.lastPage;
            return <AddUser callerState={_state} callerProps={this.getProps()}
                            callerName={Dashboard} for={'Admin'} cancelAddUser={this.cancelAddUser}
            />
        } else if (_state.partners) {
            _state.partners = false;
            this.state.recalledPage = this.state.lastPage;
            return <Partner callerState={_state} callerProps={this.getProps()}
                            callerName={Dashboard}
            />
        } else if (_state.pendingUsers) {
            return <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Button xs={12} sm={12} md={2} onClick={() => {
                        this.setState({pendingUsers: false})
                    }}>{"return"}</Button>
                    <Card>
                        <CardHeader color="info">
                            <h4 className={classes.cardTitleWhite}>Incomplete Registrations</h4>
                        </CardHeader>
                        <Table
                            data={query => new Promise((resolve, reject) => {
                                this.loadInactiveUserData(query, resolve, reject);
                            })}
                            tableRef={this.tableAllOrgRef}
                            columns={[
                                {
                                    title: getString().name,
                                    field: 'name',
                                    cellStyle: {padding: '4px'},
                                    headerStyle: {padding: '4px'}
                                },
                                {
                                    title: "Created On",
                                    field: 'created_at',
                                    render: rowData => dateFormat(rowData.created_at),
                                    cellStyle: {padding: '4px'},
                                    headerStyle: {padding: '4px'}
                                },
                                {
                                    title: getString().email,
                                    field: 'email',
                                    cellStyle: {padding: '4px'},
                                    headerStyle: {padding: '4px'}
                                },
                                {
                                    title: getString().number,
                                    field: 'number',
                                    cellStyle: {padding: '4px'},
                                    headerStyle: {padding: '4px'}
                                }
                            ]}
                            // onRowClick={}
                            options={{
                                exportButton: true, filtering: true,
                                grouping: false, sorting: true,
                                selection: true, showTitle: false,
                                pageSize: 20
                            }}
                            actions={[
                                {
                                    icon: 'refresh', tooltip: 'Refresh', isFreeAction: true,
                                    onClick: () => this.tableAllOrgRef.current && this.tableAllOrgRef.current.onQueryChange(),
                                },
                                {
                                    tooltip: 'Resend activation',icon: 'check',
                                    onClick: (event, rowData) =>{
                                        this.resendActivate(rowData)
                                    }
                                }
                            ]}
                        />
                    </Card>
                </GridItem>
            </GridContainer>
        } else {
            return (
                <div style={{marginTop: -40}}>
                    <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT}/>
                    <GridContainer>
                        <GridItem sm={12} md={8} style={{marginBottom: 20}}>
                            <h5>Schedules</h5>
                            {(cookies.load('userEmail') === 'obensouda@insistglobal.com') ?
                                <span><Button xs={12} sm={12} md={2} onClick={this.clearAll}>{"clear all"}</Button>
                                <Button xs={12} sm={12} md={2}
                                        onClick={this.scheduleAll}>{"schedule all"}</Button><Button xs={12} sm={12}
                                                                                                    md={2}
                                                                                                    onClick={this.loadVouchers}>{"vouchers"}</Button></span> : null}


                            <Button xs={12} sm={12} md={2} onClick={() => {
                                this.setState({edit: true})
                            }}>{"add admin"}</Button>
                            <Button xs={12} sm={12} md={2} onClick={() => {
                                this.setState({partners: true})
                            }}>{"partners"}</Button>
                            <Button xs={12} sm={12} md={2} onClick={() => {
                                this.setState({pendingUsers: true})
                            }}>{"show inactive"}</Button>
                        </GridItem>
                        <GridItem sm={12} md={2} style={{zIndex: 999}}>
                            <span>Product</span>
                            <Select options={_state.products} defaultValue={_state.products[0]} name="currency"
                                    id="currency"
                                    onChange={e => this.setProduct(e)}

                            />
                        </GridItem>
                    </GridContainer>
                    {
                        (_state.product !== null && (_state.product.value === "Kodo" || _state.product.value === "Jangal::JangalSchool")) ?
                            <ComingSoon/> :
                            <div>
                                <GridContainer style={{marginTop: 40}}>
                                    <GridItem xs={12} sm={6} md={3}>
                                        <Card>
                                            <CardHeader color="warning" stats icon>
                                                <CardIcon color="warning">
                                                    <Icon>people</Icon>
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Total Businesses</p>
                                                <h3 className={classes.cardTitle}>
                                                    {_state.totalOrgs}
                                                </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <span onClick={() => {
                                                        let end_date = new Date();
                                                        let start_date = new Date(end_date.getYear(),end_date.getMonth()-1,end_date.getDate());
                                                        let params = {organization_id: cookies.load("orgId"), org_name: cookies.load('orgName'), start_date: start_date, end_date: end_date};
                                                        let inject = {images: [{name: 'image', attr:'image', id: cookies.load('orgId'), model: 'Organization'}]};
                                                        showReportUtility('All Businesses', baseUrl + '/reports/1?url=/afrijula/*&profile='+cookies.load("profileId"),
                                                            {params: params, sub: '&report=all_businesses&dynamic=true', inject: inject}, cookies,
                                                            [{type: 'date', default: start_date, label: "From",name:'start_date'},{type:'date', default:end_date, label: 'To',name:'end_date'}]);
                                                    }} style={{cursor: 'pointer', color:'#800080'}}>view</span>
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={3}>
                                        <Card>
                                            <CardHeader color="success" stats icon>
                                                <CardIcon color="success">
                                                    <EmailIcon/>
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Messages(Support)</p>
                                                <h3 className={classes.cardTitle}>15</h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                      <span style={{
                                                          cursor: 'pointer',
                                                          color: '#800080'
                                                      }}>view</span>
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={3}>
                                        <Card>
                                            <CardHeader color="danger" stats icon>
                                                <CardIcon color="danger">
                                                    <TrendingUpIcon/>
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Completed Registrations</p>
                                                <h3 className={classes.cardTitle}>{_state.totalActiveOrgs}</h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <span onClick={() => {
                                                        let end_date = new Date();
                                                        let start_date = new Date(end_date.getYear(),end_date.getMonth()-1,end_date.getDate());
                                                        let params = {organization_id: cookies.load("orgId"), org_name: cookies.load('orgName'), start_date: start_date, end_date: end_date};
                                                        let inject = {images: [{name: 'image', attr:'image', id: cookies.load('orgId'), model: 'Organization'}]};
                                                        showReportUtility('Completed Registrations', baseUrl + '/reports/1?url=/afrijula/*&profile='+cookies.load("profileId"),
                                                            {params: params, sub: '&report=completed_registrations&dynamic=true', inject: inject}, cookies,
                                                            [{type: 'date', default: start_date, label: "From",name:'start_date'},{type:'date', default:end_date, label: 'To',name:'end_date'}]);
                                                        }} style={{cursor: 'pointer', color:'#800080'}}>view
                                                    </span>
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={3}>
                                        <Card>
                                            <CardHeader color="primary" stats icon>
                                                <CardIcon color="primary">
                                                    <TrendingDownIcon/>
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Pending Activations</p>
                                                <h3 className={classes.cardTitle}>{_state.totalInactiveOrgs}</h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <span onClick={() => {
                                                        let end_date = new Date();
                                                        let start_date = new Date(end_date.getYear(),end_date.getMonth()-1,end_date.getDate());
                                                        let params = {organization_id: cookies.load("orgId"), org_name: cookies.load('orgName'), start_date: start_date, end_date: end_date};
                                                        let inject = {images: [{name: 'image', attr:'image', id: cookies.load('orgId'), model: 'Organization'}]};
                                                        showReportUtility('Pending Registrations', baseUrl + '/reports/1?url=/afrijula/*&profile='+cookies.load("profileId"),
                                                            {params: params, sub: '&report=pending_registrations&dynamic=true', inject: inject}, cookies,
                                                            [{type: 'date', default: start_date, label: "From",name:'start_date'},{type:'date', default:end_date, label: 'To',name:'end_date'}]);
                                                    }} style={{cursor: 'pointer', color:'#800080'}}>view
                                                    </span>
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        <iframe
                                            id={"dashFrame"}
                                            onLoad={()=> {
                                            }}
                                            src={this.state.iframeUrl}
                                            frameBorder="0"
                                            width="100%"
                                            height="600px"
                                            overflow="hidden"
                                            allowTransparency
                                        >

                                        </iframe>

                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Card>
                                            <CardHeader color="info">
                                                <h4 className={classes.cardTitleWhite}>Organizations</h4>
                                                <p className={classes.cardCategoryWhite}></p>
                                            </CardHeader>
                                            <Table
                                                data={query => new Promise((resolve, reject) => {
                                                    let page =this.recallPage();
                                                    if(page){
                                                        query.page = page;
                                                    }
                                                    let sort = this.recallSort();
                                                    if(sort)
                                                        query.orderBy = sort;
                                                    this.loadOrgData(query, resolve, reject);
                                                })}
                                                tableRef={this.tableAllOrgRef}
                                                columns={[
                                                    {
                                                        title: getString().name,
                                                        field: 'name',
                                                        cellStyle: {padding: '4px'},
                                                        headerStyle: {padding: '4px'}
                                                    },
                                                    {
                                                        title: "Created On",
                                                        field: 'created_at',
                                                        render: rowData => dateFormat(rowData.created_at),
                                                        cellStyle: {padding: '4px'},
                                                        headerStyle: {padding: '4px'}
                                                    },
                                                    {
                                                        title: getString().email,
                                                        field: 'email',
                                                        cellStyle: {padding: '4px'},
                                                        headerStyle: {padding: '4px'}
                                                    },
                                                    {
                                                        title: getString().number,
                                                        field: 'number',
                                                        cellStyle: {padding: '4px'},
                                                        headerStyle: {padding: '4px'}
                                                    },
                                                    {
                                                        title: getString().balance,
                                                        field: 'balance',
                                                        cellStyle: {padding: '4px'},
                                                        headerStyle: {padding: '4px'},
                                                        render: rowData => {
                                                            return currencyFormat(rowData.balance)
                                                        }
                                                    },
                                                    {
                                                        title: getString().activity,
                                                        field: 'last_activity',
                                                        cellStyle: {padding: '4px'},
                                                        headerStyle: {padding: '4px'},
                                                        render: rowData => {
                                                            return dateFormat(rowData.last_activity)
                                                        }
                                                    },
                                                    {
                                                        title: getString().status,
                                                        field: 'status',
                                                        cellStyle: {padding: '4px'},
                                                        headerStyle: {padding: '4px'}
                                                    },
                                                ]}
                                                onRowClick={this.rowSelectOrg}
                                                options={{
                                                    exportButton: true, filtering: true,
                                                    grouping: true, sorting: true,
                                                    selection: true, showTitle: false,
                                                    pageSize: 10
                                                }}
                                                actions={[
                                                    {
                                                        icon: 'refresh', tooltip: 'Refresh', isFreeAction: true,
                                                        onClick: () => this.tableAllOrgRef.current && this.tableAllOrgRef.current.onQueryChange(),
                                                    },
                                                    {
                                                        tooltip: 'Remove Selected sale', icon: 'delete',
                                                        onClick: (event, rowData) => {
                                                            this.deleteOrg(rowData)
                                                        }
                                                    }
                                                ]}
                                            />
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <Card>
                                            <CardHeader color="success">
                                                <h4 className={classes.cardTitleWhite}>Active Organizations</h4>
                                                <p className={classes.cardCategoryWhite}></p>
                                            </CardHeader>
                                            <Table
                                                data={query => new Promise((resolve, reject) => {
                                                    this.loadActiveOrgData(query, resolve, reject);
                                                })}
                                                tableRef={this.tableActiveOrgRef}
                                                columns={[
                                                    {
                                                        title: getString().name,
                                                        field: 'name',
                                                        cellStyle: {padding: '4px'},
                                                        headerStyle: {padding: '4px'}
                                                    },
                                                    {
                                                        title: "Created On",
                                                        field: 'created_at',
                                                        render: rowData => dateFormat(rowData.created_at),
                                                        cellStyle: {padding: '4px'},
                                                        headerStyle: {padding: '4px'}
                                                    },
                                                    {
                                                        title: getString().email,
                                                        field: 'email',
                                                        cellStyle: {padding: '4px'},
                                                        headerStyle: {padding: '4px'}
                                                    },
                                                    {
                                                        title: getString().status,
                                                        field: 'status',
                                                        cellStyle: {padding: '4px'},
                                                        headerStyle: {padding: '4px'}
                                                    },
                                                ]}
                                                onRowClick={this.rowSelectOrg}
                                                options={{
                                                    exportButton: true, filtering: true,
                                                    grouping: true, sorting: true,
                                                    selection: true, showTitle: false,
                                                    pageSize: 10
                                                }}
                                                actions={[
                                                    {
                                                        icon: 'refresh', tooltip: 'Refresh', isFreeAction: true,
                                                        onClick: () => this.tableOrgRef.current && this.tableOrgRef.current.onQueryChange(),
                                                    },
                                                    {
                                                        tooltip: 'Remove Selected sale', icon: 'delete',
                                                        onClick: (event, rowData) => {
                                                            this.deleteOrg(rowData)
                                                        }
                                                    }
                                                ]}
                                            />
                                        </Card>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <Card>
                                            <CardHeader color="warning">
                                                <h4 className={classes.cardTitleWhite}>In-Active Organizations</h4>
                                                <p className={classes.cardCategoryWhite}></p>
                                            </CardHeader>
                                            <Table
                                                data={query => new Promise((resolve, reject) => {
                                                    this.loadInActiveOrgData(query, resolve, reject);
                                                })}
                                                tableRef={this.tableInActiveOrgRef}
                                                columns={_state.activeOrgsHeader}
                                                onRowClick={this.rowSelectOrg}
                                                options={{
                                                    exportButton: true, filtering: true,
                                                    grouping: true, sorting: true,
                                                    selection: true, showTitle: false,
                                                    pageSize: 10
                                                }}
                                                actions={[
                                                    {
                                                        icon: 'refresh', tooltip: 'Refresh', isFreeAction: true,
                                                        onClick: () => this.tableInActiveOrgRef.current && this.tableInActiveOrgRef.current.onQueryChange(),
                                                    },
                                                    {
                                                        tooltip: 'Remove Selected sale', icon: 'delete',
                                                        onClick: (event, rowData) => {
                                                            this.deleteOrg(rowData)
                                                        }
                                                    }
                                                ]}
                                            />
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                            </div>
                    }

                </div>
            );
        }
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Dashboard);

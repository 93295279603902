import React from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/common/Grid/GridItem.jsx";
import GridContainer from "../../../components/common/Grid/GridContainer.jsx";
import Table from "@material-table/core";
import Card from "../../../components/common/Card/Card.jsx";
import CardBody from "../../../components/common/Card/CardBody.jsx";
import Button from "../../../components/common/CustomButtons/Button.jsx";
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import $ from "jquery";
import {execPrint, compileQuery} from "../../../assets/js/utils/utils.js";
import frStrings from  "../../../assets/js/locales_fr.js";
import enStrings from  "../../../assets/js/locales_en.js";
import baseUrl from "../../../assets/js/config/const.js";
import cookies from "react-cookies";
import Rodal from "rodal";
import TextField from "@material-ui/core/TextField/TextField";
import Select from "react-select";
import countryList from "react-select-country-list";
import {dateFormat} from "../../../assets/js/utils/utils";


const currencies = countryList().getData();

const Country = require('country-js');


class Vouchers extends React.Component {

    constructor(props) {
        super(props);

        this.tableRef = React.createRef();
        this.printRef = React.createRef();
    }

    getString = () => {
        let strings;
        let lang = window.navigator.language;
        if(lang === 'en' || lang === 'en-US' ){
            strings = enStrings;
        }else{
            strings = frStrings;
        }
        return strings;
    };

    state = this.props.state || {
        back: false,
        createBatch: false,
        currency: 'GMD',
        amount: '',
        print: false,
        printable: '',
        number: '',
        callerState: this.props.callerState,
        callerProps: this.props.callerProps,
        callerName: this.props.callerName,
    };

    rowSelect = (event, colData)  => {
        let id = colData._id.$oid;
        let _this = this;

        $.ajax({
            method: "GET",
            url: baseUrl + "/kodo/vouchers/batch/"+id,
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            success: function (result) {
                _this.setState({print: true});
                _this.printRef.current.innerHTML = result;
            }
        });
    };

    loadBatches = (query,resolve,reject) => {
        let url = compileQuery(query, (baseUrl + "/kodo/vouchers/batch"),
            ['id','print_date', 'number', 'circulation_date', 'print_number',"printed_by",'created_at'], [{"by":"asc","attr":"circulation_date"}],
            "Kodo::Payments::Vouchers::VoucherBatch",{},null);

        fetch(url, {dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {return response.json()})
            .then(result => {
                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total
                })
            });
    };


    componentDidMount() {
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    getProps = () => {
        return this.props.returningProps || this.props;
    };

    addBatch = () => {
        this.setState({createBatch: true})
    }

    handleCurrency = value => {
        let curr = Country.search(value['value']);

        if(curr && curr[0]){
            let code = curr[0].currency.currencyCode;

            this.setState({
                currency: code
            });
        }
    };

    saveBatch = () => {
        if(this.state.number == ''){
            alert("Please enter a valid voucher count")
            return;
        }

        if(this.state.amount == ''){
            alert("Please enter a valid value for vouchers")
            return;
        }
        let _this = this;
        $.ajax({
            method: "POST",
            url: baseUrl + "/kodo/vouchers/batch",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data: {number: this.state.number, currency: this.state.currency, amount:this.state.amount},
            success: function(result){
                _this.setState({createBatch: false});
                _this.tableRef.current.onQueryChange();
                alert(result)
            },
            error: function (jqXHR, textStatus, errorThrown) {
                alert(jqXHR.responseText);

            }});
    }

    render() {

        const { classes } = this.getProps();
        if(this.state.back){
            let ChildComponent = this.state.callerName;
            let state = this.state.callerState;
            state.account = false;
            return <ChildComponent returningProps={this.state.callerProps} state={state} />
        }
        else{
            return (
                <div style={{marginTop:-40}}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={9}>
                                            <Button color="success" onClick={()=>{this.addBatch()}}>Add Batch</Button>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={3}>
                                            <Button color="danger" onClick={() => {this.setState({back: true})}}>{this.getString().back}
                                            </Button>
                                        </GridItem>
                                    </GridContainer>

                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <Table
                                    tableRef={this.tableRef}
                                    data={query=>new Promise((resolve,reject) => {
                                        this.loadBatches(query,resolve,reject);
                                    })}
                                    columns={[
                                        {title: "Created On", field: 'created_at', render: rowData => dateFormat(rowData.created_at), cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
                                        {title: this.getString().number, field:'number', cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
                                        {title: "Printed Date", field:'print_date', render: rowData => dateFormat(rowData.print_date), cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
                                        {title: "Printed By", field: 'printed_by', cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
                                    ]}
                                    onRowClick={this.rowSelect}
                                    options={{
                                        exportButton: true,filtering: true,
                                        grouping:true,sorting:true,
                                        selection:true,showTitle:false,
                                        pageSize:15
                                    }}
                                    actions={[
                                        {
                                            icon: 'refresh',tooltip: 'Refresh',isFreeAction: true,
                                            onClick: () => this.tableRef.current && this.tableRef.current.onQueryChange(),
                                        },
                                        {
                                            tooltip: 'Remove Selected sale',icon: 'delete',
                                            onClick: (evt, data) => alert('This si to be implemented ' + data.length + ' rows')
                                        }
                                    ]}
                                />
                            </Card>
                        </GridItem>
                    </GridContainer>
                    <Rodal visible={this.state.print} height={310} width={window.innerWidth-100} showMask={false} onClose={() => this.setState({print:false})}>
                        <div><button onClick={() => execPrint(this.printRef.current)}>print</button></div>
                        <div id="printVouchers" ref={this.printRef}>

                        </div>
                    </Rodal>
                    <Rodal visible={this.state.createBatch} height={310} width={window.innerWidth-100} showMask={false} onClose={() => this.setState({createBatch:false})}>
                        <GridContainer>
                            <GridItem sm={12} md={6}>
                                <TextField
                                    id="number"
                                    label={this.getString().count}
                                    name="number"
                                    className={classes.textField}
                                    value={this.state.number}
                                    onChange={e => {this.handleChange(e)}}
                                    type="number"
                                    margin="normal"
                                    variant="outlined"
                                />
                                <TextField
                                    id="amount"
                                    label={this.getString().value}
                                    name="amount"
                                    className={classes.textField}
                                    value={this.state.amount}
                                    onChange={e => {this.handleChange(e)}}
                                    type="amount"
                                    margin="normal"
                                    variant="outlined"
                                />
                            </GridItem>
                            <GridItem sm={12} md={6}>
                                <span>{this.getString().currency}</span>
                                <Select options={currencies} placeholder={this.state.countryName} name="currency" id="currency"
                                        value={this.state.currency} onChange={this.handleCurrency}  />
                            </GridItem>
                            <GridItem sm={12} >
                                <Button color="success" onClick={this.saveBatch.bind(this)}>{this.getString().save}</Button>
                                <Button color="danger" onClick={()=> {this.setState({createBatch:false})}}>{this.getString().cancel}</Button>
                            </GridItem>
                        </GridContainer>
                    </Rodal>
                </div>
            );
        }
    }
}

Vouchers.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Vouchers);

import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from '@material-ui/core/Button';
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import {currencyFormat, getString} from "assets/js/utils/utils.js";
import CardBody from "../../common/Card/CardBody";
import baseUrl from "assets/js/config/const.js";
import $ from "jquery";
import cookies from "react-cookies";
import {displayError, setGlobal, showReportUtility} from "../../../assets/js/utils/utils";
import {ToastsContainer, ToastsContainerPosition, ToastsStore} from 'react-toasts';
import Table from "@material-table/core";
import Rodal from "rodal";
import TextField from "@material-ui/core/TextField/TextField";

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

class LoanDetails extends React.Component {
    state = this.props.state || {
        back: false,
        // loanData: this.props.loanData,
        loanData: {},
        data: [],
        pageSize: 15,
        tag: "LoanDetails",
        callerName: this.props.callerName,
        callerProps: this.props.callerProps,
        callerState: this.props.callerState,
        loadId: this.props.loadId,
        reqDocs: [],
        reports: [],
        requiredDocuments: [],
        reqDocsHeader: [
            {
                title: getString().name,
                field: 'doc_name',
                cellStyle: {padding: '10px'},
                headerStyle: {padding: '4px'}
            },
            {
                title: "Status",
                field: 'status',
                cellStyle: {padding: '4px'},
                headerStyle: {padding: '4px'}
            },
            {
                title: "Available",
                field: 'selected',
                render: rowData => rowData.selected === "true" ? "Yes" : "No",
                cellStyle: {padding: '4px'},
                headerStyle: {padding: '4px'}
            }
        ],
        reportsHeader: [
            {
                title: getString().name,
                field: 'name',
                cellStyle: {padding: '10px'},
                headerStyle: {padding: '4px'}
            },
            {
                title: getString().type,
                field: 'doc_type',
                render: rowData => rowData.doc_type.charAt(0).toUpperCase() + rowData.doc_type.substring(1),
                cellStyle: {padding: '4px'},
                headerStyle: {padding: '4px'}
            },
            {
                title: getString().report,
                field: 'url',
                render: rowData => <button type="button" onClick={(event) => this.downloadReport(event, rowData)}>Download</button>,
                cellStyle: {padding: '4px'},
                headerStyle: {padding: '4px'}
            }
        ],
        processAction: '',
        printReport: false,
        showCommentsMsg: false,
        comments: '',
        action_type: '',
    };

    close = () => {this.setState({back: true, processAction: '', showCommentsMsg: false})};

    getProps = () => {
        return this.props.returningProps || this.props;
    };

    displayToast = (message, status) => {
        if (status === "success")
            return ToastsStore.success(message, 7000);
        else
            return ToastsStore.error(message, 7000);
    };

    processLoan = () => {
        let _this = this;
        $.ajax({
            method: "PUT",
            url: baseUrl + "/afrijula/loans/loans/"+this.state.loanData._id.$oid,
            dataType: "json",
            headers: {"Authorization": "token " + cookies.load("token"), "UserProfile": cookies.load("profile"), "UserKey": cookies.load('User-Key')},
            data: {
                status: this.state.action_type,
                merchant_id: this.state.loanData.organization_id.$oid,
                fsp_id: cookies.load("orgId"),
                comments: this.state.comments
            },
            success: function (result) {
                if (result.error) {
                    _this.displayToast(result.message, "error");
                } else {
                    _this.displayToast(result.success, "success");
                    if(_this.state.processAction === "Rejected"){
                        _this.closePrintReport();
                    }
                    _this.close();
                }
            }.bind(this),
            error: function (error) {
                displayError(error.responseText)
            }
        });
    };

    closePrintReport = () => {
        this.setState({
            printReport: false
        });
    };

    getRequiredDocs = () => {
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/loans/loan_documents",
            dataType: "json",
            headers: {"Authorization": "token " + cookies.load("token"), "UserProfile": cookies.load("profile"), "UserKey": cookies.load('User-Key'),},
            data: {loan_id: this.props.loanId},
            success: function (result) {
                _this.setState({reqDocs: result.loan_docs, reports: result.loan_reports});
            }.bind(this),
            error: function (error) {
                _this.displayToast(error.responseText, "error");
            }
        });
    };

    showReport = (name, baseUrl, parts, params) => {
        showReportUtility(name, baseUrl, parts, cookies, params);
    };

    downloadReport = (event, rowData) => {
        event.preventDefault();
        let e_date = rowData.end_date.split('-');
        let s_date = rowData.start_date.split('-');
        let end_date = new Date(parseInt(e_date[0]), parseInt(e_date[1]), parseInt(e_date[2]));
        let start_date = new Date(parseInt(s_date[0]), parseInt(s_date[1]), parseInt(s_date[2]));

        let params = {
            organization_id: rowData.organization_id.$oid,
            org_name: rowData.org_name,
            start_date: start_date,
            end_date: end_date
        };
        let inject = {
            images: [{
                name: 'image',
                attr: 'image',
                id: rowData.organization_id.$oid,
                model: 'Organization'
            }]
        };
        this.showReport(rowData.name, 'https://api.lecket.gm/reports/1?url=/afrijula/*&profile=' + rowData.profile_id,
            {params: params, sub: '&report='+rowData.report+'&dynamic=true', inject: inject},
            [
                {type: 'date', default:start_date, label: "From", name: 'start_date'},
                {type: 'date', default: end_date, label: 'To', name: 'end_date'}
                ]);
    };

    downloadDoc = (event, rowData) => {
        let fileName = null;
        event.preventDefault();
        fetch(baseUrl + "/afrijula/loans/loan_documents/download?loan_id="+this.state.loanData._id.$oid+"&ref_number="+rowData.reference_number, {
            method: 'GET',
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },

        })
            .then((response) => {
                fileName = response.headers.get("File-Name");
                return response.blob();
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.target = "_blank";
                link.setAttribute(
                    'download',
                    `${fileName}`,
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
    };

    displayCommentsRod = (action_type) => {
        this.setState({
            showCommentsMsg: !this.state.showCommentsMsg,
            action_type: action_type
        });
    };

    handleChangeReject = (e) => {this.setState({[e.target.name]: e.target.value})};

    getLoan = () => {
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/loans/loans/"+this.props.loanId,
            dataType: "json",
            headers: {"Authorization": "token " + cookies.load("token"), "UserProfile": cookies.load("profile"), "UserKey": cookies.load('User-Key')},
            success: function (result) {
                this.setState({loanData: result.loan, requiredDocuments: result.loan.required_documents});
            }.bind(this),
            error: function (error) {
                displayError(error.responseText)
            }
        });
    };

    notifyApplicant = () => {
        let response =  window.confirm("Do you want to notify applicant by sending them an email?");
        let _this = this;
        if(response){

            $.ajax({
                method: "POST",
                url: baseUrl + "/afrijula/loans/loans/send_applicant_email",
                dataType: "json",
                headers: {
                    "Authorization": "token " + cookies.load("token"),
                    "UserProfile": cookies.load("profile"),
                    "UserKey": cookies.load('User-Key')
                },
                data: {loan_id: this.state.loanData._id.$oid},
                success: function (result) {
                    _this.displayToast(result.success, "success");
                }, error: function (resp) {
                    _this.displayToast(resp.responseText, "error");
                }
            })
        }
    };

    componentDidMount() {
        this.getLoan();
        this.getRequiredDocs();
        this.setState({loanData: this.props.loanData});
        setGlobal(this);
    };

    render() {
        const loanStatus = this.state.loanData && this.state.loanData.status;
        const {classes} = this.getProps();
        if (this.state.back) {
            let ChildComponent = this.state.callerName;
            return <ChildComponent state={this.state.callerState} returningProps={this.state.callerProps}/>
        }
        else {
            return (
                <div>
                    <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT}/>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={9}>
                                    {
                                        (loanStatus === "Pending") ?
                                            <>
                                                <Button color="success"
                                                    onClick={() => this.displayCommentsRod("Approved")}
                                                    style={{
                                                        backgroundColor: "#4caf50",
                                                        margin: ".3125rem 1px",
                                                        minWidth: "auto",
                                                        minHeight: "auto",
                                                        fontSize: "12px",
                                                        color: "#ffffff",
                                                        padding: "12px 30px",
                                                        borderRadius: "3px",
                                                        verticalAlign: "middle"
                                                    }}
                                                ><i className="material-icons">check_circle_outline</i>
                                                    Approve
                                                </Button>
                                                <Button color="info"
                                                    onClick={() => this.notifyApplicant()}
                                                    style={{
                                                        backgroundColor: "#00acc1",
                                                        margin: ".3125rem 1px",
                                                        minWidth: "auto",
                                                        minHeight: "auto",
                                                        fontSize: "12px",
                                                        color: "#ffffff",
                                                        padding: "12px 30px",
                                                        borderRadius: "3px",
                                                        verticalAlign: "middle"
                                                    }}
                                                ><i className="material-icons">mail_outline</i>
                                                    Notify Applicant
                                                </Button>
                                                <Button
                                                    color={loanStatus === "Rejected" ? "#d5d5d5" : "success"}
                                                    onClick={() => this.displayCommentsRod("Rejected")}
                                                    style={{
                                                        backgroundColor: "#ff9800",
                                                        margin: ".3125rem 1px",
                                                        minWidth: "auto",
                                                        minHeight: "auto",
                                                        fontSize: "12px",
                                                        color: "#ffffff",
                                                        padding: "12px 30px",
                                                        borderRadius: "3px",
                                                        verticalAlign: "middle"
                                                    }}
                                                ><i className="material-icons">close</i>
                                                    Reject
                                                </Button>
                                            </>
                                            : null
                                    }
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                    <Button color="danger" onClick={() => {
                                        this.setState({back: true})
                                    }}
                                            style={{
                                                backgroundColor: "#f44336",
                                                margin: ".3125rem 1px",
                                                float: 'right',
                                                minWidth: "auto",
                                                minHeight: "auto",
                                                padding: "12px 30px",
                                                borderRadius: "3px",
                                                color: "#ffffff"
                                            }}
                                    >{getString().back}
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={6} md={12}>
                            <Card>
                                <CardHeader color="primary">
                                    <h4>{getString().loan_details}</h4>
                                </CardHeader>
                                <CardBody>
                                    <form>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b>{getString().name}:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b>{this.state.loanData && this.state.loanData.name}</b></p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b>{getString().applicant}:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b>{this.state.loanData && this.state.loanData.applicant}</b></p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b>{getString().applicant_contact}:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b>{this.state.loanData && this.state.loanData.applicant_contact}</b></p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b> {getString().amount}:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b>{currencyFormat(this.state.loanData && this.state.loanData.amount)}</b></p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b>{getString().status}:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                {this.state.loanData && this.state.loanData.status === "Rejected" ? <p style={{background: '#419644', width: 70}}><b>{this.state.loanData.status}</b></p> :
                                                    <p><b>{this.state.loanData && this.state.loanData.status}</b></p>}
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b>{getString().comments}:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b>{this.state.loanData && this.state.loanData.comments}</b></p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b>{getString().tenor}:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b>{this.state.loanData && this.state.loanData.tenor}</b></p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b>{getString().repayment_freq}:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b>{this.state.loanData && this.state.loanData.repayment_frequency}</b></p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b>Age of Applicant:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b>{this.state.loanData && this.state.loanData.applicant_age} years</b></p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b>Age of Business:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b>{this.state.loanData && this.state.loanData.age_of_business} years</b></p>
                                            </GridItem>
                                        </GridContainer>
                                    </form>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <Card>
                                <CardHeader color="success">
                                    <h4 className={classes.cardTitleWhite}>Required Document</h4>
                                    <p className={classes.cardCategoryWhite}></p>
                                </CardHeader>
                                <Table
                                    data={this.state.requiredDocuments}
                                    columns={this.state.reqDocsHeader}
                                    options={{
                                        exportButton: false, filtering: false,
                                        grouping: false, sorting: true,
                                        selection: false, showTitle: false,
                                        pageSize: 5,search: false
                                    }}
                                />
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <Card>
                                <CardHeader color="primary">
                                    <h4 className={classes.cardTitleWhite}>Applicant Reports</h4>
                                    <p className={classes.cardCategoryWhite}></p>
                                </CardHeader>
                                <Table
                                    data={this.state.reports}
                                    columns={this.state.reportsHeader}
                                    options={{
                                        exportButton: false, filtering: false,
                                        grouping: false, sorting: true,
                                        selection: false, showTitle: false,
                                        pageSize: 5,search: false
                                    }}
                                />
                            </Card>
                        </GridItem>
                    </GridContainer>
                    <Rodal visible={this.state.printReport}  height={820} showMask={false} onClose={this.closePrintReport.bind(this)}>
                        <span onClick={this.printReport} >Print</span>
                        <div id="test"></div>
                    </Rodal>
                    <Rodal visible={this.state.showCommentsMsg}  height={185} showMask={false} onClose={this.displayCommentsRod.bind(this)}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <TextField
                                    id="comments"
                                    label='Additional Comments'
                                    name="comments"
                                    className={classes.textField}
                                    value={this.state.comments}
                                    onChange={e => {
                                        this.handleChangeReject(e)
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                    helperText={"Additional comment for why this loan application is being "+ (this.state.action_type === "Approved" ? "approved." : "rejected.")}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <Button
                                    color={this.state.action_type === "Approved" ? "success" : "danger"}
                                    onClick={() => this.processLoan()}
                                    style={{
                                        backgroundColor: this.state.action_type === "Approved" ? "#4caf50" : "#f44336",
                                        margin: ".3125rem 1px",
                                        minWidth: "auto",
                                        minHeight: "auto",
                                        fontSize: "12px",
                                        color: "#ffffff",
                                        padding: "12px 30px",
                                        borderRadius: "3px",
                                        verticalAlign: "middle"
                                    }}
                                >
                                    {
                                        (this.state.action_type === "Approved") ? "Approve" : "Reject"
                                    }
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </Rodal>
                </div>
            )
        }
    }
}

export default withStyles(styles)(LoanDetails);

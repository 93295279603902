import LocalizedStrings from 'react-localization';
import React from "react";


const strings = new LocalizedStrings({

    en:{
        //for dashboard
        customers: "Customers",
        low_products: "Low Products",
        daily_sales: "Daily Sales",
        sale_text:{"one_time":"Cash Sales",'invoice':"Invoices",'quote':'Quotes'},
        daily_invoice: "Daily Invoice",
        daily_revenue: "Daily Revenue",
        details: "Details",
        monthly_expense: "Monthly Expense",
        stock: "Stock",
        due_invoices: "Due Invoices",
        increase_sales: "Increase in today sales.",
        record_monthly_expense: " Record of Monthly Expense",
        best_selling: "Best Sellings",
        category: "Category",
        sale_type: "Sale type",
        edit: "Edit",
        new_payment: "New Payment",
        deposit_amount: "Deposit",
        payment_type: "Payment type",
        initial_deposit: "Initial deposit",
        payment_method: "Payment method",
        amount_to_pay: "Amount to Pay",
        installments: "Installments",
        installment_period: "Installment period",
        start_on: "Start on",
        number_installments: "Number of Installments",
        installment_type: "Installment type",
        count: "Count",
        period: "Period",
        add_customer_sale: "New sale",

        status: 'Status',

        //for stocks
        low_stock: "Low Stock",
        //stock: "Stock",
        fast_moving_stock: "Fast Moving Stock",
        transfer_stock: 'Transfer Stock',
        add_stock: 'New Stock',
        qty_per_unit: "Quantity Per Unit",
        stock_hist: "Stock History",
        transfer_qty: "Enter transfer quantity",
        submit: "Submit",
        stock_qty: "Stock quantity",

        //for stock transfer
        save: "Save",
        cancel: "Cancel",
        select_source: "Select Source",
        select_destination: "Select Destination",
        source: "Source",
        destination: "Destination",
        received_by: "Received by",
        table_name: "Name",
        table_quantity: "Quantity",
        back: "Back",
        received_qty: "Received Qty",

        //for add stock

        product: "Product Name",
        product_number: "Product Number",
        wholesale_mteric: "Wholesale Metric",
        retail_metric: "Retail Metric",
        reorder_level: "Reorder Level",
        sellable_unit: "Sellable Unit",
        sellable: "Sellable",
        unit_price: "Unit Price",
        opening_balance: "Opening Balance",
        select_category: "Select Category",
        select_location: "Select Location",
        //add_stock: "Add Stock",
        stock_sales: "Stock Sales",
        stock_purchase: "Stock Purchase",


        //for customer
        total_customer: "Total Customers",
        // due_invoices: "Due Invoice",
        list_customers: 'List of Customers',
        aging_debtors: "Aging Debtors",

        //for Customer Details
        profile: "Profile",
        name: "Name",
        address: "Address",
        contact_person: 'Contact Person',
        made_by: "Paid by",
        phone: "Phone",
        email: "Email",
        balance: "Balance",
        currency: 'Currency',
        account_summary: "Account Summary",
        yearly_turnover: "Yearly TurnOver",
        last_date: "Last Transaction Date",
        purchase_history: "Purchase History",
        add_customer: "New Customer",

        //for supplier
        payables: "Payables",
        //aging_debtors: "Aging Debtor",
        list_suppliers: "List of Suppliers",
        billing_addr: "Billing Address",
        shipping_addr: "Shipping Address",

        //for  spplier details
        date: "Date",
        sub_total: "Subtotal",
        total_amount: "Total Amount",
        total: "Total",
        add_supplier: "New Supplier",
        pay_supplier: "Pay Supplier",
        supplier_account: "Supplier Account",
        supply_history: "Supply History",
        tax_amount: "Tax Amount",
        tax_percentage: "Tax %",
        shipping_address:'Shipping Address',
        billing_address: 'Billing Address',
        item: "Item",
        amount: "Amount",
        due: "Due",
        items: "Items",
        paid: "Paid",
        add_supp_invoice: "Add Supplier Invoice",
        add_supplierInv:"Supplier Invoice",


        //FOR INVOICE
        monthly_invoice: "Monthly Invoice",
        customer: "Customer",
        dailyInvoiceName: "Daily Invoice",

        //for invoice details
        bill_to: "Bill To",
        date_issued: "Date Issued",
        due_date: "Due Date",
        invoice_no: "Invoice N°",
        customer_id: "Customer ID",
        description: "Description",
        pricing:"Pricing",
        unit_cost: "Unit Cost",
        quantity: "Quantity",
        // total: "Total",
        payment_history: "Payment History",
        payment_plan: "Payment Plan",
        invoice: "Invoice",
        to_print: "Print",
        close: "Close",
        to_pdf: "Download",
        to_edit:"Edit",
        to_email: "Send email",

        //for SideBar (routes)
        dashboard: "Dashboard",
        sell: "Sell",
        cash_sales: "Cash Sales",
        invoices: "Invoices",
        //stock: "Stock",
        expenses: "Expenses",
        income: "Income",
        reports: "Reports",
        user_profile: "User Profile",
        settings: "Settings",
        supplier: "Supplier",

        available_packages: "Available packages",

        //for cash sales
        type: "Type",
        weekly_sales: "Weekly Sales",
        monthly_sales: "Monthly Sales",
        discount: "Discount",
        sales: "Sales",

        //for sell component
        discount_type: 'Discount Type',
        retail: "Retail",
        wholesale: "Wholesale",
        percentage: "Percentage",
        fixed: "Fixed",
        select_item: "Select Product",
        select_customer: "Select Customer",
        total_tax: "Total Tax",
        cash: "Cash",
        quote: "Quote",
        cheque: "Cheque",
        bank: "Bank",
        choose_category: "Choose category",

        sale_item: "Sold Items",

        //for settings page
        //settings: "Settings",
        company_name: "Company Name",
        telephone: "Telephone",
        location: "Location",
        edit_settings: "Edit",
        save_settings: "Save",
        add_user: "New User",
        add_location: "New Location",
        gen_info:"General Info",
        billing_info: "Billing",
        user_acc: "User Accounts",
        current_version: "Current Version",
        version: "Version",
        upgrade: "Upgrade",
        upgrade_version: "Upgrade Version",
        pay_bill: "Pay Bill",
        account_billing:"Account & Billing",
        select_version: "Please select package",
        pay: "Pay",
        manager: "Manager",
        insert_logo: "Insert logo",
        first_name: "First Name",
        last_name: "Last Name",
        user_name: "Username",
        user_type: "User type",
        select_user_type: "Select user type",
        password: "Password",
        logout: "Logout",
        login: "Login",
        powerful: "Powerful",
        user_friendly: "User friendly",
        african: "African",
        key_features: "Key Features",
        owner_gender: "Owner's Gender",
        owner_age: "Owner's Age",
        business_size: 'Business Type',
        region: 'Region',
        //for cashButton
        change: "Change",
        payment: "Payment",
        activate: "Activate",
        activity: "Activity",
        deactivate: "Deactivate",
        delete: "Delete",

        register: "Registration",
        reg: "Register",
        complete: "Complete",
        business_name: "Business Name",



       //for reports component
        dailysales_report: "Daily Sales Report",
        expense_eport: "Expense Report",
        stock_report: "Stock Report",
        monthlysales_report: "Monthly Sales Report",

        //Quotation
        price: "Price",
        item_code: "Item Code",
        default: "Default",

        locale: "Locale",
        package: "Package",
        term: "Term",
        term_charge: "Term Charge",
        max_users: "Max Users",
        default_currency: "Default Currency",
        country: "Country",
        yes: "Yes",
        no: "No",
        default_chooser:"Make default location",
        id:"Id",
        org_info: "Organisation details",

        sale_date: "Sale Date",
        delivery_date: "Delivery Date",
        // due_date: "Due Date",
        inv_number: "Invoice No",
        payment_term: "Payment Terms",
        last_payment: "Last Payment",
        vat: "VAT",
        other_tax: "Other tax",
        number: "Number",
        quantities: "Quantities",
        tax: "Tax",
        value: "Value",

        supplier_invoice: "Supplier Invoice",
        stock_level: "Stock Level",
        enable_notification: "Enable Notification",

        //for expense_manager
        bank_name: "Bank Name",
        cheque_number: "Cheque Number",
        purpose: "Purpose",
        expense: "Expense",
        expenses_table: "History of Expenses",
        supply: "Supply",
        asset: "Asset",
        voucher_number: "Voucher",
        summary: "Summary",
        expense_manager: "Add Expense",
        exchange_rate: "Exch Rate",
        paid_by: "Paid by",
        approved_by: "Approved by",
        signature: "Signature",
        refNo: "Reference Number",
        payee: "Payee",
        checkNum: "Cheque #",
        exchange: "Exchange",

        add_stock_inv: "Add Stock Invoice",
        select_supplier: "Select Supplier",
        select_country: "Select country",
        new_invoice: " New Invoice",
        unpaid_inv: "Unpaid invoices",

        payment_summary: "Payment summary",
        unable_to_complete_reg: "Unable to complete registration",
        account_activation:"Account activation in progress...",
        variance: "Variance",
        variable: "Variable",
        high_expenses: "High expenses",
        annually: "Annually",
        daily: "Daily",
        weekly: "Weekly",
        monthly: "Monthly",
        quarterly: "Quarterly",
        six_months: "Six Months",


        //Account and Billing
        package_name: "Package name",
        monthly_price: "Monthly price",
        last_paid: "Last paid",
        payment_mthd: "Payment method",
        charges: "Charges",

        //Super Admin
        bills: "Unpaid bills",
        users_login: "Users login",
        accounts: "Accounts",
        message: "Message",
        users:"Users",
        num_users: "Number of users",
        num_allowed_users: "Allowed users",
        num_transaction: "Number of transactions",
        allowed_transaction: "Allowed transactions",
        active_users: "Active users",
        billing: "Billing",
        payments: "Payments",
        weekly_account: "Weekly account turnover",
        open_logs: "Open logs",
        sale_stats: "Sale statistics",
        organisation: "Organisations",
        ip:"IP address",
        duration: " Duration",
        logs: "Logs",
        users_list: "List of Users",
        usage_params: "Usage parameters",
        subject: "Subject",
        previous_logs:"Previous logs",

        one_time: "One time",
        start_date: "Start date",
        end_date:"End date",
        subscriptions: "Subscriptions",
        new_sub: "New Subscription",

        select_duration: "Please select duration",
        block_acc: "Block account",
        bloc_user: "Block user",
        business_type: "Business type",
        reset_password:"Reset Password",
        block: "Block",
        reset: "Reset",
        parameters: "Parameters",
        old_password: "Old password",
        new_password: "New password",
        confirm_password: "Confirm password",
        active:"Active",
        last_active: "Last active",
        registration: "Registration",
        account_type: "Organization type",
        locations: "Locations",
        website: "Website",
        dob: "Date of Birth",
        login_attempts:"Login attempts",
        edit_details: "Edit details",
        editSettings: "Edit Settings",
        low_stock_threshold: "Low stock threshold",
        printer: "Printer Type",
        thermal_printer:"Thermal printer",
        large: "A4 Paper",
        notifications: "Notifications",
        tenor: "Tenor",
        applicant: "Applicant",
        loan_details: "Loan Details",
        applicant_contact: "Applicant Contact",
        repayment_freq: "Repayment Frequency",
        report: "Report",
        comments: "Comments",



    }

});
export default strings;

// strings.setLanguage('fr');
const lang = strings.getLanguage();

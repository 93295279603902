import LocalizedStrings from 'react-localization';


const strings = new LocalizedStrings({

    fr: {
        //for dashboard
        customers: "Les clients",
        low_products: "Produits bas",
        daily_sales: "Ventes Quotidiennes",
        daily_invoice: "Factures Quotidiennes",
        daily_revenue: "Revenu Quotidien",
        details: "Détails",
        sale_text:{"cash":"Ventes en espèces",'invoice':"Factures",'quote':'Quotes'},
        monthly_expense: "Dépense Mensuelle",
        stock: "Stock",
        due_invoices: "Factures Attendu",
        increase_sales: "augmentation des ventes aujourd'hui.",
        record_monthly_expense: "Enregistrement des dépenses mensuelles",
        best_selling: "Meilleure vente",
        category: "Catégorie",
        sale_type: "Type de vente",
        deposit_amount: "Montant deposé",
        payment_type: "Type de paiment ",
        initial_deposit: "Dépôt initial",
        number_installments: "Nombre de versement",
        installment_period: "Période de versement",
        installments: "Versements",
        start_on: "Démarrer",
        count: "Compter",
        period: "Période",
        add_customer_sale: "Nouvelle vente",

        activity: "Activity",

        //for stock
        low_stock: "Stock Faible",
        //stock: "Stock",
        fast_moving_stock: "Stock Rapide",
        transfer_stock: 'Transferer Stock',
        add_stock: 'Nouveau Stock',
        qty_per_unit: "Quantité Per Unitaire",
        stock_hist: "Historique du Stock",
        transfer_qty: "Entrer quantité à transférer ",
        submit : "Envoyer",
        stock_qty: "Quantité du stock",
        sale_item: "Articles vendus",

        //for stock transfer
        save: "Sauver",
        cancel: "Annuler",
        select_source: "Sélectionner Source",
        select_destination: "Sélectionner Destination",
        source: "Source",
        destination: "Destination",
        received_by: "Reçu by",
        table_name: "Nom du produit",
        table_quantity: "Quantité",
        back: "Retourner",
        received_qty: "Quantité reçue",
        stock_sales: "Ventes de Stock",
        stock_purchase: "Achat de Stock",
        //for add stock

        // save: "Sauver",
        // cancel: "Annuler",
        // back: "Retourner",
        product: "Nom du produit",
        product_number: "Numéro de Produit",
        wholesale_mteric: "Métrique de Gros",
        retail_metric: "Métrique de Détail",
        reorder_level: "Niveau de Restauration",
        sellable_unit: "Unité vendable",
        sellable: "Vendable",
        unit_price: "Prix Unitaire",
        opening_balance: "Solde d'ouverture",
        select_category: "Choisir une catégorie",
        select_location: "Choisir une location",
        // add_stock: "Ajouter Stock",

        //for customer
        total_customer: "Clients Total",
        // due_invoices: "Due Invoice",
        list_customers: 'Liste de Clients',
        aging_debtors: "Débiteur âgé",
        choose_category: "Choisir la catégorie",

        //for Customer Details
        profile: "Profil",
        name: "Nom complet",
        address: "Adresse",
        phone: "Téléphone",
        email: "Email",
        contact_person: 'Contact',
        shipping_address:'Shipping Address',
        billing_address: 'Billing Address',
        balance: "Solde",
        currency: 'Devise',
        account_summary: "Relevé de Compte",
        yearly_turnover: "Chiffre d'affaires Annuel",
        last_date: "Date de la dernière transaction",
        purchase_history: "Historique d'achat",
        add_customer: "Nouveau Client",

        //for supplier
        payables: "Payables",
        //aging_debtors: "Aging Debtor",
        list_suppliers: "Liste de Fournisseurs",
        billing_addr: "Adresse de facturation",
        shipping_addr: "Adresse de livraison",


        //for  spplier details
        date: "Date",
        sub_total: "Sous-Total",
        total_amount: "Montant Total",
        total: "Total",
        add_supplier: "Nouveau Fournisseur",
        pay_supplier: "Payer Fournisseur",
        supplier_account: "Compte Fournisseur",
        supply_history: "Historique de provision",
        tax_amount: "Montant de la taxe",
        item: "Produit",
        items: "Produits",
        amount: "Montant",
        due: "Dû",
        paid: "Payé",
        add_supp_invoice: "Ajouter facture du fournisseur",
        add_supplierInv:"Facture du fournisseur",

        //FOR INVOICE
        monthly_invoice: "Facture Mensuelle",
        customer: "Client",
        dailyInvoiceName: "Factures Quotidiennes",

        //for invoice details
        bill_to: "Facturer",
        date_issued: "Date d'émission",
        due_date: "Date d'échéance",
        invoice_no: "N° de facture",
        customer_id: "N° de client",
        description: "Description",
        unit_cost: "Coût unitaire",
        quantity: "Quantité",
        pricing: "Tarification",
        // total: "Total",
        payment_history: "Historique de paiement",
        payment_plan: "Plan de paiement",
        invoice: "Facture",
        to_print: "Imprimer",
        close: "Fermer",
        to_pdf: "Télécharger",
        to_edit: "Editer",
        to_email: "Envoyer par email",

        //for SideBar (routes)
        dashboard: "Tableau de bord",
        sell: "Vendre",
        cash_sales: "Ventes en espèces",
        invoices: "Factures",
        //stock: "Stock",
        expenses: "Dépenses",
        income: "Revenu",
        reports: "Rapports",
        user_profile: "Profil de l'utilisateur",
        settings: "Paramètres",
        supplier: "Fournisseur",

        available_packages: "Forfaits disponibles",


        //for cash sales
        type: "Type",
        weekly_sales: "Ventes hebdomadaires",
        monthly_sales: "Ventes Mensuelles",
        discount: "Remise",
        sales: "Ventes",
        //for sell component
        discount_type: 'Type de remise',
        retail: "Détail",
        wholesale: "Gros",
        percentage: "Pourcentage",
        fixed: "Fixé",
        select_item: "Selectionner Produit",
        select_customer: "Selectionner Client",
        total_tax: "Taxe total",
        cash: "Espèces",
        quote: "Quote",
        cheque: "Chéquier",
        bank: "Banque",
        last_payment: "Last Payment",

        //for Landing page
        login: "Connecter",
        powerful: "Puissante",
        user_friendly: "Conviviale",
        african: "Africaine",
        key_features: "Principales caractéristiques",

        //settings page
        //settings: "Réglages",
        company_name: "Nom de l'Entreprise",
        telephone: "Téléphone",
        // address: "Adresse",
        location: "Location",
        // email: "Email",
        edit_settings: "Modifier",
        save_settings: "Sauver",
        add_user: "Nouveau Utilisateur",
        add_location: "Nouveau Location",
        gen_info:"Information",
        billing_info: "Facturation",
        user_acc: "Compte Utilisateurs",
        current_version: "Version Actuelle",
        upgrade: "Améliorer",
        upgrade_version: "Améliorer la version",
        pay_bill: "Payer facture",
        account_billing:"Compte & Facturation",
        select_version: "Selectionner la version",
        version: "Version",
        pay: "Payer",
        manager: "L'administrateur",
        insert_logo: "Inserer logo",
        first_name: "Prénom",
        last_name: "Nom",
        user_name: "Nom d'utilisateur",
        user_type: "Type d'utilisateur",
        select_user_type: "Selectionner type d'utilisateur",
        password: "Mot de passe",
        logout: "",
        change: "Change",
        payment: "Paiement",

        activate: "Activer",
        deactivate: "Deactiver",
        delete: "Supprimer",

        register: "S'inscrire",
        reg: "s'inscrire",
        complete: "Complete",
        business_name: "Nom de l'entreprise",

        //for reports component
        dailysales_report: "Rapport de vente quotidien",
        expense_eport: "Rapport de dépenses",
        stock_report: "Rapport de stock",
        monthlysales_report: "Rapport de vente mensuel",

        //Quotation
        price: "Prix",
        item_code: "Code du produit",
        default: "Défaut",


        locale: "Locale",
        package: "Paquet",
        term: "Terme",
        term_charge: "Coût du terme",
        max_users: "Max utilisateurs",
        default_currency: "Devise par défaut",
        country: "Pays",
        yes: "Oui",
        no: "Non",
        default_chooser:"Location par défaut",
        id: "Id",
        org_info: "Détails d'organisation",
        number:"Numéro",
        quantities: "Quantités",
        tax: "Impôt",
        value: "Valeur",
        supplier_invoice: "Facture du fournisseur",
        inv_number: "No facture",
        sale_date: "Date de vente",
        delivery_date: "date de livraison",
        other_tax: "Autre tax",
        vat: "T.V.A",
        payment_term: "Détails de paiement",
        stock_level: "Niveau de stock",
        enable_notification: "Activer la notification",
        owner_gender: "Sexe du propriétaire",
        owner_age: "Âge du propriétaire",
        business_size: "Type d'entreprise",
        region: 'Région',

        //for expense_manager
        bank_name: "Nom de banque",
        cheque_number: "Numéro du chèque",
        purpose: "Objectif",
        expense: "Frais",
        expenses_table: "Histoire des dépenses",
        supply: "Fournir",
        asset: "Les biens",
        voucher_number: "Bon",
        summary: "Résumé",
        expense_manager: "Ajouter dépenses",
        exchange_rate: "Taux",
        add_stock_inv: "Facture du Stock",
        select_supplier: "Selectionner Fournisseur",
        select_country: "Selectionner pays",
        new_invoice: " Nouvelle Facture",
        unpaid_inv: "Factures impayées",

        payment_summary: "Payment summary",
        unable_to_complete_reg: "Impossible de terminer l'inscription",
        account_activation:"Activation du compte en cours ...",
        variance: "Variance",
        variable: "Variable",
        high_expenses: "Dépenses élevées",
        annually: "Annuel",
        daily: "Quotidien",
        weekly: "Hebdomadaire",
        monthly: "Mensuel",
        quarterly: "Trimestriel",
        six_months: "Six mois",

        //Account and Billing
        package_name: "Nom du paquet",
        monthly_price: "Prix mensuel",
        last_paid: "Dernier payé",
        payment_mthd: "Mode de paiement",
        charges: "Des charges",

        //Super Admin
        bills: "Facture non payées",
        users_login: "Identificateur d'Utilisateur",
        accounts: "Comptes",
        message: "Message",

        users: "Utilisateurs",
        num_users: "Nombre d'utilisateur",
        num_transaction: "Nombre de transaction",
        num_allowed_users: "Utilisateurs permise",
        allowed_transaction: "Transactions permise",
        active_users:"Utilisateurs actifs",
        billing: "Facturation",
        payments: "Payements",
        weekly_account: "Compte hebdomadaire",
        open_logs: "Journaux ouverts",
        sale_stats: "Statistiques de vente",
        organisation: "Organisations",
        ip:"Adresse IP",
        duration: " Durée",
        logs: "Les journaux",
        users_list: "Liste des utilisateurs",
        usage_params: "Paramètres d'utilisation",
        subject: "Sujet",
        previous_logs:"Journaux précédents",

        one_time: "Une fois",
        start_date: "Date de début",
        end_date:"Date de fin",
        subscriptions: "Abonnements",
        new_sub: "Nouvel abonnement",
        select_duration: "Selectionner la durée",
        block_acc: "Bloquer compte",
        bloc_user: "Bloquer utilisateur",
        business_type: "Type d'entreprise",
        reset_password:"Réinitialiser mot de passe",
        block: "Bloquer",
        reset: "Réinitialiser",
        parameters: "paramètres",
        old_password: "Ancien mot de passe",
        new_password: "Nouveau mot de passe",
        confirm_password: "Confirmez le mot de passe",
        active:"Actif",
        last_active: "Dernier actif",
        registration: "enregistrement",
        account_type: "Type de compte",
        locations: "Locations",
        website: "Site web",
        dob: "Date de naissance",
        login_attempts:"Tentatives de connexion",
        edit_details: "Modifier les détails",
        editSettings:"Modifier les paramètres",
        low_stock_threshold: "Low stock threshold",
        printer: "Printer Type",
        thermal_printer:"Thermal printer",
        large: "A4 Paper",
        notifications: "Notifications",
        tenor: "Ténor",
        applicant: "Demandeur",
        loan_details: "Loan Details",
        applicant_contact: "Contact du candidat",
        repayment_freq: "Fréquence de remboursement",
        report: "Rapport",
        comments: "Commentaires",


    },

});
export default strings;

//strings.setLanguage('fr');
const lang = strings.getLanguage();

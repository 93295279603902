import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import baseUrl from "assets/js/config/const.js";
import GridContainer from "components/common/Grid/GridContainer";
import GridItem from "components/common/Grid/GridItem";
import Card from "components/common/Card/Card.jsx";
import CardBody from "components/common/Card/CardBody";
import Button from "components/common/CustomButtons/Button";
import CardHeader from "../../common/Card/CardHeader";
import MaterialTable from "@material-table/core";
import {
    compileQuery,
    phonenumber,
    validateEmail
} from "../../../assets/js/utils/utils";
import cookies from "react-cookies";
import $ from "jquery";
import TextField from "@material-ui/core/TextField/TextField";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import enStrings from "../../../assets/js/locales_en";
import frStrings from "../../../assets/js/locales_fr";
import {ToastsStore} from "react-toasts";

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
});

class Partners extends React.Component {
    constructor(props) {
        super(props);
        this.tablePartnerRef = React.createRef();
    }

    getString = () => {
        var strings;
        var lang = window.navigator.language;
        if (lang === 'en' || lang === 'en-US') {
            strings = enStrings;
        } else {
            strings = frStrings;
        }
        return strings;
    };

    state = {

        back: false,
        callerName: this.props.callerName,
        callerProps: this.props.callerProps,
        callerState: this.props.callerState,
        partner: {name: '',contact_person:'',contact_email:'',contact_number: '', access:'', partner_type: '',last_activity: '', status:'',id:''},
        partnerId: '',
        addUser: false
    };

    handleChange = (event) => {
        let _partner = this.state.partner;
        _partner[event.target.name] = event.target.value;

        this.setState({ partner: _partner});
    };

    handleUChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    updateRecord = () => {

    }

    loadPartnersTable = (query,resolve,reject) =>{
        let filter = "_type:{'$eq':'Partners::Partner'}";

        let url = compileQuery(query, (baseUrl + "/partners/partner"),
            ["name",'contact_person','partner_type','access','id'],
            ([{"by":"desc","attr":"date"}]),
            "Partners::Partner", null, filter,
            null);

        fetch(url, {dataType: "json",
            headers:{'Accept': 'application/json',
                "Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {return response.json()})
            .then(result => {

                let data = (result.data !== undefined) ?
                    result.data.map((data)=>{
                        return data;
                    }) : [];
                resolve({
                    data: data,
                    page: query.page,
                    totalCount: result.total
                })
            });
    };

    selectPartner = (event, colData) => {
        const id = colData._id.$oid;
        let _this = this;
        $.ajax({
            method: 'GET',
            url: baseUrl + "/partners/partner/"+id,
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            success: function (result) {
                _this.setState({partner: result, add:false});
            },error: function(error){
                alert(error.responseText);
            }
        });
    }

    addUser = (event,colData) => {
        if(colData.length != 1){
            alert("You must select only one partner to add user");
            return;
        }
        this.setState({partnerId: colData[0]._id.$oid, addUser:true});
    }

    displayToast = (message, status) => {
        if (status === "success")
            return ToastsStore.success(message, 6000);
        if (status === "error")
            return ToastsStore.error(message, 6000);
    };

    saveUser = () => {

        let first_name = this.state.first_name;
        let last_name = this.state.last_name;
        let status = 'active';
        let phone = this.state.phone;
        let email = this.state.email;
        let _this = this;
        if (email === "" || !validateEmail(email)) {
            _this.displayToast("Please enter a valid email address", "error");
            document.getElementById("email").focus();
            return;
        }

        if (phone === "" || !phonenumber(phone)) {
            _this.displayToast("Please enter telephone number", "error");
            document.getElementById("phone").focus();
            return;
        }

        $.ajax({
            method: "POST",
            url: baseUrl + "/account/users/",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {
                "email": email, "user_type": 'partner', org_id: this.state.partnerId, auto_gen: true, for_account: true,
                'first_name': first_name, phone: phone, 'last_name': last_name, influence: 'Partner', status: status
            },

            success: function (result) {
                _this.displayToast("user successfully created ", "success");
                _this.setState({addUser: false});

            }.bind(this),
            error: function(error){
                alert(error.responseText);
            }
        });

    }

    savePartner = () => {
        let _this = this;

        const method = (this.state.partner.id === '') ? 'POST' : 'PUT';

        $.ajax({
            method: method,
            url: baseUrl + "/partners/partner/"+this.state.partner.id,
            data:this.state.partner,
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            success: function (result) {
                _this.setState({add: false});
                _this.tablePartnerRef.current && _this.tablePartnerRef.current.onQueryChange();
            },error: function(error){
                alert(error.responseText);
            }
        });
    }

    editPartner = () => {
        const {classes} = this.props;
        let partner = this.state.partner;
        return <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
                <TextField
                    autoFocus
                    id="name"
                    label={'name'}
                    name="name"
                    value={partner.name}
                    onChange={e => {
                        this.handleChange(e)
                    }}
                    margin="normal"
                    variant="outlined"
                />

            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
                <TextField
                    autoFocus
                    id="contact_person"
                    label={'contact person'}
                    name="contact_person"
                    value={partner.contact_person}
                    onChange={e => {
                        this.handleChange(e)
                    }}
                    margin="normal"
                    variant="outlined"
                />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
                <TextField
                    autoFocus
                    id="contact_email"
                    label={'contact email'}
                    name="contact_email"
                    value={partner.contact_email}
                    onChange={e => {
                        this.handleChange(e)
                    }}
                    margin="normal"
                    variant="outlined"
                />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
                <TextField
                    autoFocus
                    id="contact_number"
                    label={'contact number'}
                    name="contact_number"
                    value={partner.contact_number}
                    onChange={e => {
                        this.handleChange(e)
                    }}
                    margin="normal"
                    variant="outlined"
                />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
                <TextField
                    autoFocus
                    id="access"
                    label={'access'}
                    name="access"
                    style={{width: '50%'}}
                    value={partner.access}
                    select
                    onChange={e => {
                        this.handleChange(e)
                    }}
                    SelectProps={{
                        MenuProps: {
                            className: classes.menu,
                        },
                    }}
                    margin="normal"
                    variant="outlined"
                >
                    {["afrijula","jangal","kodo"].map(option => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
                <TextField
                    autoFocus
                    id="partner_type"
                    label={'partner type'}
                    name="partner_type"
                    style={{width: '100%'}}
                    value={partner.partner_type}
                    select
                    onChange={e => {
                        this.handleChange(e)
                    }}
                    SelectProps={{
                        MenuProps: {
                            className: classes.menu,
                        },
                    }}
                    margin="normal"
                    variant="outlined"
                >
                    {["strategic","paid","fsp"].map(option => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
                <TextField
                    autoFocus
                    id="status"
                    label={'status'}
                    select
                    name="status"
                    className={classes.textField}
                    value={partner.status}
                    style={{width: '100%'}}
                    onChange={e => {
                        this.handleChange(e)
                    }}
                    SelectProps={{
                        MenuProps: {
                            className: classes.menu,
                        },
                    }}
                    margin="normal"
                    variant="outlined"
                >
                    {["active","inactive"].map(option => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            </GridItem>
            <GridItem md={12}>
                <Button onClick={() => {this.savePartner()}}>Save</Button>
                <Button onClick={() => {this.setState({add: false})}}>Cancel</Button>
            </GridItem>
        </GridContainer>

    };

    addUserView = () => {
        let _state = this.state;
        const {classes} = this.props;
      return <GridContainer>
              <GridItem xs={12} sm={4} md={4}>
                  <TextField
                      autoFocus
                      id="first_name"
                      label={this.getString().first_name}
                      name="first_name"
                      className={classes.textField}
                      value={_state.first_name}
                      onChange={e => {
                          this.handleUChange(e)
                      }}
                      margin="normal"
                      variant="outlined"
                  />

              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                  <TextField
                      autoFocus
                      id="last_name"
                      label={this.getString().last_name}
                      name="last_name"
                      className={classes.textField}
                      value={_state.last_name}
                      onChange={e => {
                          this.handleUChange(e)
                      }}
                      margin="normal"
                      variant="outlined"
                  />

              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <TextField
                    id="email"
                    label={this.getString().email}
                    name="email"
                    className={classes.textField}
                    value={_state.email}
                    onChange={e => {
                        this.handleUChange(e)
                    }}
                    type="email"
                    margin="normal"
                    variant="outlined"
                        />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
                <TextField
                    id="phone"
                    label={this.getString().telephone}
                    name="phone"
                    className={classes.textField}
                    value={_state.phone}
                    onChange={e => {
                        this.handleUChange(e)
                    }}
                    margin="normal"
                    variant="outlined"
                />
            </GridItem>
          <GridItem xs={12}>
              <Button onClick={()=>{this.saveUser()}}>save</Button>
              <Button onClick={()=>{this.setState({addUser: false})}}>cancel</Button>
          </GridItem>
        </GridContainer>

    };

    render() {
        const { classes } = this.props;
        const { value } = this.state;

        if(this.state.back){
            let ChildComponent = this.state.callerName;
            let state = this.state.callerState;
            state.edit = false;
            return <ChildComponent state = {state} returningProps = {this.state.callerProps} />
        }

        return (
            <div className={classes.root}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Button color="danger" onClick={() => {this.setState({back: true})}} style={{float:'right'}}>
                            {'back'}
                        </Button>
                        <Button color="danger" onClick={() => {this.setState({add: true})}} style={{float:'right'}}>
                            {'add'}
                        </Button>
                        <Button color="danger" onClick={() => {this.updateRecord()}} style={{float:'right'}}>
                            {'update'}
                        </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12}>
                                        {(this.state.add) ? this.editPartner() : null}
                                        {(this.state.addUser) ? this.addUserView(): null}
                                    </GridItem>
                                    <GridItem xs={12} sm={12}>
                                        <Card>
                                        <CardHeader color="warning">
                                            <h4 className={classes.cardTitleWhite}>Partners</h4>
                                            <p className={classes.cardCategoryWhite}></p>
                                        </CardHeader>
                                        <CardBody>
                                            <MaterialTable
                                                tableRef={this.tablePartnerRef}
                                                data={query=>new Promise((resolve,reject) => {

                                                    this.loadPartnersTable(query,resolve,reject);
                                                })}
                                                columns={[
                                                    {title: 'name', field: 'name',cellStyle: {padding: '2px'}, headerStyle: {padding: '2px'}},
                                                    {title: 'contact_person', field:'contact_person', cellStyle:{padding:'2px'}, headerStyle:{padding:'2px'}},
                                                    {title: 'type', field:'partner_type', cellStyle:{padding:'2px'}, headerStyle:{padding:'2px'}},
                                                    {title: 'access', field: 'access', cellStyle: {padding: '2px'},headerStyle: {padding: '2px'}},
                                                ]}
                                                onRowClick={this.selectPartner}
                                                options={{
                                                    filtering: true,
                                                    debounceInterval: 1000,
                                                    grouping:false,sorting:true,
                                                    paging:true,
                                                    selection:true,showTitle:false,
                                                    pageSize:5,rowStyle:{height:'4px'}
                                                }}
                                                actions={[
                                                    {
                                                        icon: 'refresh',tooltip: 'Refresh',isFreeAction: true,
                                                        onClick: () => { this.tablePartnerRef.current && this.tablePartnerRef.current.onQueryChange()},
                                                    },
                                                    {
                                                        icon: 'add',tooltip: 'Add User',
                                                        onClick: (event,colData) => {this.addUser(event,colData);},
                                                    }
                                                ]}
                                                title = "Partners"
                                            />
                                        </CardBody>
                                    </Card>
                                    </GridItem>
                                </GridContainer>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

Partners.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Partners);

import React from "react";
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
// core components
import Button from "components/common/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import cookies from "react-cookies";

class HeaderLinks extends React.Component {
    state = {
        openNotice: false,
        openLogout: false,
        notificationsList: []
    };

    handleToggle = (state) => {
        this.setState(state);
    };

    handleClose = event => {
        if (this.anchorEl.contains(event.target)) {
            return;
        }

        this.setState({openNotice: false, openLogout: false});
    };

    handleAdmin = () => {
        localStorage.clear();
        window.location.href = '/login';
    };

    render() {
        const {classes} = this.props;
        const {openNotice, openLogout} = this.state;

        return (
            <div>
                <Button
                    color={window.innerWidth > 959 ? "transparent" : "white"}
                    justIcon={window.innerWidth > 959}
                    simple={!(window.innerWidth > 959)}
                    aria-label="Person"
                    className={classes.buttonLink}
                    ref={node => {
                        this.anchorEl = node;
                    }}
                    aria-owns={openLogout ? "menu-list-grow" : null}
                    aria-haspopup="true"
                    onClick={() => {
                        this.handleToggle({openLogout: true})
                    }}
                >
                    <Person className={classes.icons}/>
                    <h6>{cookies.load('userName')}</h6>
                    <Hidden mdUp implementation="css">
                        <p className={classes.linkText}>Profile</p>
                    </Hidden>
                </Button>
                <Poppers
                    open={openLogout}
                    anchorEl={this.anchorEl}
                    transition
                    disablePortal
                    className={
                        classNames({[classes.popperClose]: !openLogout}) +
                        " " +
                        classes.pooperNav
                    }
                >
                    {({TransitionProps, placement}) => (
                        <Grow
                            {...TransitionProps}
                            id="menu-list-grow"
                            style={{
                                transformOrigin:
                                    placement === "bottom" ? "center top" : "center bottom"
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <MenuList role="menu">
                                        <MenuItem
                                            onClick={this.handleAdmin}
                                            className={classes.dropdownItem}
                                        >
                                            Logout
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Poppers>
            </div>
        );
    }
}

export default withStyles(headerLinksStyle)(HeaderLinks);

/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "../components/common/Header/Header.jsx";

import superAdmin from "../routes/admin.jsx";
import FinanceRoutes from "../routes/finance.jsx"
import PartnerRoutes from "../routes/partners.jsx"
import FspRoutes from "../routes/fsp.jsx"
import dashboardStyle from "../assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";
import cookies from 'react-cookies';


const returnRoutes = (routes) => {
    let array= [];
    routes.forEach((prop, key) => {
        if (prop.redirect)
            array.push(<Redirect from={prop.path} to={prop.to} key={key}/>);
        else if(prop.children)
            prop.children.forEach((childProp,childKey) => {
                array.push(<Route path={childProp.path} render={(props) => <childProp.component {...props} tag={childProp.tag} />} key={childKey}/>);
            });
        else
            array.push(<Route path={prop.path} component={prop.component} key={key}/>);
    });
    return array;
};

const switchRoutes = (routes) =>{
    return (
        <Switch>
            { returnRoutes(routes)
            }
        </Switch>
    )};

class App extends React.Component {

    constructor(props){
        super(props);

        this.state = this.props.history.location.state;
        let user = undefined;
        if(!this.state){
            const rehydrate = JSON.parse(localStorage.getItem('lecket.insist.state'));
            this.setState(rehydrate);
            this.props.history.location.state = this.state = rehydrate;
        }
        if(this.state) {
            user = this.props.history.location.state.user;
            this.state.showlogin = false;
        }

        this.resizeFunction = this.resizeFunction.bind(this);
    }

    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    };

    determineRoute = (user_type,partner_type) => {
        let routes = superAdmin;
        if(user_type === "InSISTFinance"){
            routes = FinanceRoutes;
        }else if(user_type === 'PartnerProfile'){
            routes = PartnerRoutes;
            if (partner_type === 'fsp')
                routes = FspRoutes;
        }

        return routes;
    };

    resizeFunction() {
        if (window.innerWidth >= 960) {
            this.setState({ mobileOpen: false });
        }
    }
    componentDidMount() {
        if(this.props.reload){
            window.location.reload();
        }
        localStorage.setItem('lecket.insist.state', JSON.stringify(this.state));

        if (navigator.platform.indexOf("Win") > -1) {
            const ps = new PerfectScrollbar(this.refs.mainPanel);
        }
        window.addEventListener("resize", this.resizeFunction);
        this.checkSession();
    }
    componentDidUpdate(e) {
        if (e.history.location.pathname !== e.location.pathname) {
            this.refs.mainPanel.scrollTop = 0;
            if (this.state.mobileOpen) {
                this.setState({ mobileOpen: false });
            }
        }
    }
    componentWillUnmount() {window.removeEventListener("resize", this.resizeFunction)};

    checkSession = () =>{
        setInterval(() => {
            if(cookies.load('token') === undefined){
                this.setState({
                    showLogin: true
                })
            }
        }, 30000);
    };

    render() {

        const { classes, ...rest } = this.props;
        if(!this.state){
            return <Redirect to='/login' path='/login'  reload={true} />
        }else if (this.state.user === undefined || !cookies.load("token")){
            return <Redirect to='/login' path='/login' reload={true} />
        }if(this.state.showLogin){
            return <Redirect to='/login' path='/login'  reload={true} />
        }

        return (
            <div className={classes.wrapper}>
                <div className={classes.mainPanel} ref="mainPanel">
                    <Header
                        routes={this.determineRoute(this.state.user.user_type)}
                        handleDrawerToggle={this.handleDrawerToggle}
                        {...rest}
                    />
                    {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
                    { <div className={classes.content}>
                        <div className={classes.container}>{switchRoutes( this.determineRoute(this.state.user.user_type,this.state.user.partner_type))}</div>
                    </div>}
                </div>
            </div>
        );
    }
}

App.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(App);

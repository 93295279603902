import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import CardFooter from "components/common/Card/CardFooter.jsx";
import MenuItem from '@material-ui/core/MenuItem';
import {numberFormat, currencyFormat,compileQuery} from "assets/js/utils/utils.js";
import frStrings from  "assets/js/locales_fr.js";
import enStrings from  "assets/js/locales_en.js";
import $ from "jquery";
import cookies from "react-cookies";
import baseUrl from "assets/js/config/const.js";
import {ToastsStore} from "react-toasts";



const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});


class UpgradeVersion extends React.Component {

    getString = () => {
        var strings;
        var lang = window.navigator.language;
        if(lang === 'en' || lang === 'en-US' ){
            strings = enStrings;
        }else{
            strings = frStrings;
        }
        return strings;
    };

    state = {
        versions: [],
        amount: this.props.price,
        currentPackage: this.props.package,

        pay_bill: false,
        upgrade_version: false,
        back_acc_bill: false,
        packages:[],
        prices:[],
        orgData: this.props.orgData,
        callerName: this.props.callerName,
        callerProps: this.props.callerProps,
        callerState: this.props.callerState,
    };

    cancelPayment = () =>{
        this.setState({currentPackage: ''});
        this.setState({amount: ''});
        this.props.cancelUpgrade();
    };

    displayToast = (message) => {
        return  ToastsStore.success(message,6000);
    };

    upgradeVersion = () => {
        let upgradeDetails = {"package": this.state.currentPackage,org_id: this.state.orgData._id.$oid};
        let _this = this;
        const id = this.state.callerState.settings._id.$oid;
        $.ajax({
            method: "PUT",
            url: baseUrl + "/account/settings/"+id,
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: upgradeDetails,

            success: function (result) {
                cookies.save('package',_this.state.currentPackage);
                let setting = _this.state.callerState.settings;
                setting.package = _this.state.currentPackage;
                _this.setState({callerState: setting});
                _this.displayToast("package successfully upgraded ","success");
                _this.props.cancelUpgrade();
            },error: function(error){
                _this.displayToast(error.responseText);
            }
        });
        this.setState({upgrade:  false});
    };

    loadVersions = () =>{
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/afrijula/packages",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            success: function (result) {
                let versions = [];
                let i = 0;
                for (let [key, value] of Object.entries(result)) {
                    versions.push({value: key, label: key.replace('_',' ')});
                }
                _this.setState({versions: versions,prices: result});
            }
        });

    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            amount: (this.state.prices[e.target.value].price),
        });

    };

    componentDidMount() {
       this.loadVersions();
    }


    render() {
        const { classes } = this.props;

        if(this.state.back_acc_bill){
            let ChildComponent = this.state.callerName;
            return <ChildComponent state = {this.state.callerState} returningProps = {this.state.callerProps} />
        }

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader >
                                <h4 className={classes.cardTitleWhite}>{this.getString().upgrade_version}</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <TextField
                                            id="version"
                                            name="currentPackage"
                                            select
                                            label={this.getString().version}
                                            className={classes.textField}
                                            value={this.state.currentPackage}
                                            onChange={e => {this.handleChange(e)}}
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                            helperText={this.getString().select_version}
                                            margin="normal"
                                            variant="outlined"
                                        >
                                            {this.state.versions.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <p>{currencyFormat(this.state.amount)} Monthly</p>
                                    </GridItem>
                                </GridContainer>

                            </CardBody>
                            <CardFooter>
                            <Button color="danger" onClick={this.cancelPayment}>{this.getString().cancel}</Button>
                            <Button color="success" onClick={this.upgradeVersion} >{this.getString().upgrade}</Button>
                            </CardFooter>
                        </Card>
                    </GridItem>

                </GridContainer>
            </div>
        );
    }
}

UpgradeVersion.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UpgradeVersion);

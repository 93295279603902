import MainLayout from "layout/MainLayout.jsx";
import Login from "components/afrijula/Login/LogIn.jsx";
import Dashboard from "components/afrijula/Dashboard/Dashboard.jsx";
import "assets/css/bootstrap.css";

const indexRoutes = [
    { path: "/login", component: Login},
    { path: "/",component: MainLayout}

];

export default indexRoutes;

import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Rodal from 'rodal';

// core components
import GridItem from "../../../components/common/Grid/GridItem.jsx";
import GridContainer from "../../../components/common/Grid/GridContainer.jsx";
import Table from "@material-table/core";
import Button from "../../../components/common/CustomButtons/Button.jsx";
import Card from "../../../components/common/Card/Card.jsx";
import CardHeader from "../../../components/common/Card/CardHeader.jsx";
import CardBody from "../../../components/common/Card/CardBody.jsx";
import frStrings from  "../../../assets/js/locales_fr.js";
import enStrings from  "../../../assets/js/locales_en.js";
import $ from "jquery";
import cookies from "react-cookies";
import baseUrl from "../../../assets/js/config/const.js";
import UserInfo from "../../../components/afrijula/Settings/Settings.jsx";
import {dateFormat, currencyFormat,compileQuery} from "../../../assets/js/utils/utils.js";
import TextField from "@material-ui/core/TextField";
import CardFooter from "../../common/Card/CardFooter";
import UserDetails from "../../../components/afrijula/Super_Admin/User_Details";
import AddUser from "../../../components/afrijula/Settings/Add_User.jsx";
import ReactTimeAgo from "react-time-ago";
import Upgrade from "../../../components/afrijula/Settings/UpgradeVersion.jsx";
import 'rodal/lib/rodal.css';
import Radio from '@material-ui/core/Radio';
import Select from "react-select";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import countryList from 'react-select-country-list';
import RadioGroup from '@material-ui/core/RadioGroup';
import {ToastsStore} from "react-toasts";

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

const currencies = countryList().getData();

const Country = require('country-js');
const countries = countryList().getData();

class AccountDetails extends React.Component{
    constructor(props) {
        super(props);

        this.tableRef = React.createRef();
        this.chargeRef = React.createRef();
    }

    getString = () => {
        var strings;
        var lang = window.navigator.language;
        if(lang === 'en' || lang === 'en-US' ){
            strings = enStrings;
        }else{
            strings = frStrings;
        }
        return strings;
    };

    state = this.props.state || {

        back: false,
        editDetails: false,
        editSetting: false,
        userData: null,
        countryName: 'Gambia (GMD)',
        packages:[],
        billing:{charges:[], payments:[]},
        header:  [
            {title: this.getString().name, field: 'name'},
            {title: this.getString().email, field:'email'},
            {title: this.getString().last_active, field: 'last_login_date', render: rowData=>{return <ReactTimeAgo date={new Date(rowData.last_login_date)} locale="en" />}},
            {title: this.getString().status, field: 'status'},
        ],
        paymentHeader:  [
            {title: this.getString().amount, field: 'amount',render: rowData => {return currencyFormat(rowData.amount)}, cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
            {title: this.getString().date, field: 'date', render: rowData => dateFormat(rowData.date), cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
        ],
        redeem: false,
        chargesHeader:  [
            {title: this.getString().date, field: 'date', render: rowData => dateFormat(rowData.date), cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
            {title: this.getString().amount, field: 'amount',render: rowData => currencyFormat(rowData.amount), cellStyle:{padding:'4px'}, headerStyle:{padding:'4px'}},
        ],
        voucher: '',
        status:[
            {
                value: 'activate',
                label: 'Activate',
            },
            {
                value: 'unactivate',
                label: 'Unactivate',
            },

        ],

        activate: false,
        addUser: false,
        upgrade: false,

        settings: {},
        users_list:[],


        metrics:{
           users: 0,
            active: 0,
            tran_count: 0,
            package:{
               price: 0,
                max_users: 0,
                extra_users: 0,
                term: '',
                max_sales: 0,
            }
        },
        printerType:  [
            {
                value: 'thermal',
                label: this.getString().thermal_printer,
            },
            {
                value: 'a4',
                label: this.getString().large,
            },
        ],

        genderList: [
            { value: 'Female', label: 'Female',_type: 'owner_gender'},
            { value: 'Male', label: 'Male',_type: 'owner_gender' }
        ],

        regionList: [
            { value: 'BJL', label: 'BJL',_type: 'lga'},
            { value: 'CRR', label: 'CRR',_type: 'lga'},
            { value: 'KMC', label: 'KMC',_type: 'lga'},
            { value: 'LRR', label: 'LRR',_type: 'lga'},
            { value: 'NBR', label: 'NBR',_type: 'lga'},
            { value: 'URR', label: 'URR',_type: 'lga'},
            { value: 'WCR', label: 'WCR',_type: 'lga'}
        ],

        ageBrackets: [
            { value: '15-24', label: '15-24',_type: 'owner_age'},
            { value: '25-35', label: '25-35',_type: 'owner_age' },
            { value: 'Over 35', label: 'Over 35',_type: 'owner_age' }
        ],

        businessTypes: [
            { value: 'MSME', label: 'MSME',_type: 'business_type'},
            { value: 'Large Corporation', label: 'Large Corporation',_type: 'business_type' }
        ],

        callerName: this.props.callerName,
        callerProps: this.props.callerProps,
        callerState: this.props.callerState,
        orgData: {name: '',address:'',email:'',contact_number:'',
            website:'',owner_age:'',owner_gender:'',business_type:'',lga:'',_id:''}

    };

    deactivateAccount = () => {
        this.updateAccount({status:'inactive'});
    };

    displayToast = (message) => {
        return  ToastsStore.success(message,6000);
    };

    updateAccount = (data) => {
        let _this = this;

        let id = this.state.orgData._id.$oid;
        $.ajax({
            method: "PUT",
            url: baseUrl + "/organizations_v2/"+id,
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data: data,

            success: function (result) {
                _this.displayToast("account successfully updated ","success");

                console.log(result.data)
                _this.setState({editDetails: false});
            }
        });
    };

    updateSettings = (data) => {
        let _this = this;

        let id = this.state.settings._id.$oid;
        $.ajax({
            method: "PUT",
            url: baseUrl+"/account/settings/"+id,
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data: data,

            success: function (result) {
                _this.displayToast("settings successfully updated ","success");
                _this.loadSettings();

            }.bind(this)
        });
    };

    activateAccount = () =>{
        this.updateAccount({status: 'active'});
    };

    close = () => {
        this.setState({back: true})
    };

    rowSelectUsers = (event, colData)  => {

        let id = colData._id;
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/account/users/"+id,
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            success: function (result) {
                _this.setState({userData: result});
            }
        });
    };

    loadUsers = () => {
        let orgID = this.state.orgData._id.$oid;
        $.ajax({
            method: "GET",
            url: baseUrl + "/organizations_v2/users",
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            data:{"organization_id": orgID},

            success: function (result) {
                this.setState({users_list:result});
            }.bind(this)
        });
    };

    handleOrgChange = (e) =>{
        let _details = this.state.orgDetails;
        _details[e.target.name] = e.target.value;
        this.setState({
            orgDetails: _details
        });
    };

    loadVersions = () =>{
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/afrijula/packages",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            success: function (result) {
                let versions = [];
                let i = 0;
                for (let [key, value] of Object.entries(result)) {
                    versions.push({value: key, label: key.replace('_',' ')});
                }
                _this.setState({versions: versions,prices: result});
            }
        });

    };

    cancelUpgrade = () =>{
        this.setState({
            currentPackage: '',
            amount: '',
            upgrade: false,
        });
    };

    upgrade = (classes) =>{
        return(
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader >
                            <h4 className={classes.cardTitleWhite} style={{color: "#000"}}>{this.getString().upgrade_version}</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                    <TextField
                                        id="version"
                                        name="currentPackage"
                                        select
                                        label={this.getString().version}
                                        className={classes.textField}
                                        value={this.state.currentPackage}
                                        onChange={e => {this.handlePackageChange(e)}}
                                        SelectProps={{
                                            MenuProps: {
                                                className: classes.menu,
                                            },
                                        }}
                                        helperText={this.getString().select_version}
                                        margin="normal"
                                        variant="outlined"
                                    >
                                    </TextField>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <p>{currencyFormat(this.state.amount)} Monthly</p>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <GridItem xs={12} sm={12} md={6}>
                                <Button xs={12} sm={12} md={2} color="danger" onClick={this.cancelUpgrade}>{this.getString().cancel}</Button>
                                <Button xs={12} sm={12} md={2} color="success" onClick={this.upgradeVersion} >{this.getString().upgrade}</Button>
                            </GridItem>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    };

    loadBilling = ()  => {
        let id = this.state.orgData._id.$oid;
        let _this = this;

        $.ajax({
            method: "GET",
            url: baseUrl + "/organizations_v2/"+id,
            dataType: "json",
            data:{billing:true},
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            success: function (result) {
                let bill = _this.state.billing;
                bill.balance = result.balance;
                bill.charges = result.charges;
                bill.payments = result.payments;
                _this.setState({billing: bill});
            }
        });

    };

    loadSettings = () =>{
        let id = this.state.orgData._id.$oid;
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl+"/account/settings/"+id,
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            success: function (result) {

                let _settingData = _this.state.settings;
                let _result = result[0];

                if(_result){
                    _settingData._id = _result._id;
                    _settingData.default_currency = _result.default_currency;
                    _settingData.locale = _result.locale;
                    _settingData.enable_notifications = _result.enable_notifications;
                    _settingData.tax_percentage = _result.tax_percentage;
                    _settingData.printer_type = _result.printer_type;
                    _settingData.low_stock_level = _result.low_stock_level;
                    _settingData.term = _result.term;
                    _settingData.term_charge = _result.term_charge;
                    _settingData.package = _result.package;
                    _settingData.max_users = _result.max_users;

                    let metric = _this.state.metrics;
                    metric.active = _result.metrics.active;
                    metric.users = _result.metrics.users;
                    metric.tran_count = _result.metrics.tran_count;
                    metric.package.price  = _result.metrics.package.price;
                    metric.package.max_sales  = _result.metrics.package.max_sales;
                    metric.package.max_user  = _result.metrics.package.max_user;


                    _this.setState({
                        settings: _settingData,
                        metrics:metric,
                    });
                }
            }.bind(this)
        });
    };

    editOrgDetails = () =>{
        this.setState({
            editDetails: true,
        });
    };

    updateDetails = ()=>{
        const org = this.state.orgData;
        this.updateAccount({name: org.name,address:org.address,email:org.contact_email,contact_number:org.contact_number,
            website:org.website,owner_age:org.owner_age,owner_gender:org.owner_gender,business_type:org.business_type,lga:org.lga});
    };

    updateOrgSettings = () =>{
        let settings = this.state.settings;
        this.updateSettings({printer_type:settings.printer_type, enable_notifications:settings.enable_notifications,default_currency:settings.default_currency, locale:settings.locale,
            low_stock_level:settings.low_stock_level, tax_percentage:settings.tax_percentage,org_id: this.state.orgData._id.$oid});

        this.setState({
            editSetting: false,
        });
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleChangeDetails = (e) =>{
        let _data = this.state.orgData;
        _data[e.target.name] = e.target.value;
        this.setState({
            orgData: _data,
        });
    };

    editDetails = (classes) =>{
        return(
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader >
                            <h4 className={classes.cardTitleWhite} style={{color: "#000"}}>{this.getString().edit_details}</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                    <TextField
                                        autoFocus
                                        id="name"
                                        label={this.getString().name}
                                        name="name"
                                        className={classes.textField}
                                        value={this.state.orgData.name}
                                        onChange={e => {this.handleChangeDetails(e)}}
                                        margin="normal"
                                        variant="outlined"
                                    />

                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <TextField
                                        id="address"
                                        label={this.getString().address}
                                        name="address"
                                        className={classes.textField}
                                        value={this.state.orgData.address}
                                        onChange={e => {this.handleChangeDetails(e)}}
                                        margin="normal"
                                        variant="outlined"
                                    />

                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <TextField
                                        id="contact_number"
                                        label={this.getString().phone}
                                        name="contact_number"
                                        className={classes.textField}
                                        value={this.state.orgData.contact_number}
                                        onChange={e => {this.handleChangeDetails(e)}}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                    <TextField
                                        id="email"
                                        label={this.getString().email}
                                        name="email"
                                        className={classes.textField}
                                        value={this.state.orgData.contact_email}
                                        onChange={e => {this.handleChangeDetails(e)}}
                                        type="email"
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <TextField
                                        id="website"
                                        label={this.getString().website}
                                        name="website"
                                        className={classes.textField}
                                        value={this.state.orgData.website}
                                        onChange={e => {this.handleChangeDetails(e)}}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <span>{this.getString().owner_gender}</span>
                                    <Select options={this.state.genderList} placeholder={this.state.orgData.owner_gender} name="owner_gender" id="owner_gender"
                                            defaultValue={this.state.orgData.owner_gender} onChange={opt => {this._handleChange(opt['_type'],opt['value'])}}  />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                    <span>{this.getString().owner_age}</span>
                                    <Select options={this.state.ageBrackets} placeholder={this.state.orgData.owner_age} name="owner_age" id="owner_age"
                                            defaultValue={this.state.orgData.owner_age} onChange={opt => {this._handleChange(opt['_type'],opt['value'])}}  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <span>{this.getString().business_size}</span>
                                    <Select options={this.state.businessTypes} placeholder={this.state.orgData.business_type} name="business_type" id="business_type"
                                            defaultValue={this.state.orgData.business_type} onChange={opt => {this._handleChange(opt['_type'],opt['value'])}}  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <span>{this.getString().region}</span>
                                    <Select options={this.state.regionList} placeholder={this.state.orgData.lga} name="lga" id="lga"
                                            defaultValue={this.state.orgData.lga} onChange={opt => {this._handleChange(opt['_type'],opt['value'])}}  />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <GridItem xs={12} sm={12} md={12}>
                                <Button xs={12} sm={6} md={2} color="danger" onClick={() =>{this.setState({editDetails: false})}} >{this.getString().cancel}</Button>
                                <Button xs={12} sm={6} md={2} color="success" onClick={this.updateDetails.bind(this)} >{this.getString().save_settings}</Button>
                            </GridItem>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    };

    onChangePrinter = (opt) =>{
        let _details = this.state.settings;
        _details['printer_type'] = opt.value;
        this.setState({
            settings: _details
        });
    };

    _handleChange =(opt,val) => {
        let _data = this.state.orgData;
        _data[opt] = val;
        this.setState({
            orgData: _data,
        });
    }

    handleCurrency = value => {
        let curr = Country.search(value['value']);

        let data;
        if(curr && curr[0]){
            data = curr[0].currency.currencyCode;
            let _name = curr[0].name+ '('+data+')';
            let _details = this.state.settings;
            _details['default_currency'] = data;

            this.setState({
                settings: _details,
                countryName: _name,
            });
        }
    };

    handleChangeSettings = (e) =>{
        let _details = this.state.settings;
        _details[e.target.name] = e.target.value;
        this.setState({
            settings: _details
        });
    };

    editSettings = (classes) =>{
       return(
           <GridContainer>
               <GridItem xs={12} sm={12} md={12}>
                   <Card>
                       <CardHeader >
                           <h4 className={classes.cardTitleWhite} style={{color: "#000"}}>{this.getString().editSettings}</h4>
                       </CardHeader>
                       <CardBody>
                           <GridContainer>
                               <GridItem xs={12} sm={4} md={4}>
                                   <span>{this.getString().locale}</span>
                                   <Select options={this.state.locales} placeholder={this.state.settings.locale} name="locale" id="locale"
                                           defaultValue={this.state.settings.locale} onChange={opt => {this.onChange(opt)}}  />
                               </GridItem>
                               <GridItem xs={12} sm={4} md={4} style={{marginTop: -14+'px'}}>
                                   <TextField
                                       autoFocus
                                       id="low_stock_level"
                                       label={this.getString().low_stock_threshold}
                                       name="low_stock_level"
                                       className={classes.textField}
                                       value={this.state.settings.low_stock_level}
                                       onChange={e => {this.handleChangeSettings(e)}}
                                       type="number"
                                       margin="normal"
                                       variant="outlined"
                                   />
                               </GridItem>
                               <GridItem xs={12} sm={4} md={4} style={{marginTop: -15+'px'}}>
                                   <h4>{this.getString().enable_notification}</h4>

                                   <RadioGroup
                                       aria-label="Enable Notification"
                                       name="enable_notifications"
                                       className={classes.group}
                                       value={this.state.settings.enable_notifications ? 'yes' : 'no'}
                                       onChange={e => this.handleNotification(e)}
                                       row
                                   >
                                       <FormControlLabel value="no" control={<Radio />} label="no" />
                                       <FormControlLabel value="yes" control={<Radio />} label="yes" />
                                   </RadioGroup>
                               </GridItem>
                               <GridItem xs={12} sm={6} md={4}>
                                   <span>{this.getString().currency}</span>
                                   <Select options={currencies} placeholder={this.state.countryName} name="currency" id="currency"
                                           value={this.state.settings.default_currency} onChange={this.handleCurrency}  />
                               </GridItem>
                               <GridItem xs={12} sm={4} md={4}>
                                   <span>{this.getString().printer}</span>
                                   <Select options={this.state.printerType} placeholder={this.state.settings.printer_type} name="printer" id="printer"
                                           defaultValue={this.state.settings.printer_type} onChange={opt => {this.onChangePrinter(opt)}}  />
                               </GridItem>
                               <GridItem xs={12} sm={4} md={4}>
                                   <span>{this.getString().vat}</span>
                                   <TextField
                                       name="tax_percentage"
                                       id="tax_percentage"
                                       className={classes.textField}
                                       value={this.state.settings.tax_percentage}
                                       onChange={e => {this.handleChangeSettings(e)}}
                                       type="number"
                                       margin="normal"
                                       style={{marginTop: 1+"px"}}
                                       variant="outlined"/>
                               </GridItem>
                           </GridContainer>
                       </CardBody>
                       <CardFooter>
                           <GridItem xs={12} sm={12} md={12}>
                               <Button xs={12} sm={6} md={2} color="danger" onClick={() =>{this.setState({editSetting: false})}} >{this.getString().cancel}</Button>
                               <Button xs={12} sm={6} md={2} color="success" onClick={this.updateOrgSettings} >{this.getString().save_settings}</Button>
                           </GridItem>
                       </CardFooter>
                   </Card>
               </GridItem>
           </GridContainer>
       );
    };

    handleNotification = (e)=>{
        let _details = this.state.settings;
        let enable_notif = (e.target.value === 'yes' )? true : false;
        _details[e.target.name] = enable_notif;
        this.setState({
            settings: _details,
        });
    };

    onChange = (opt) =>{
        this.setState({locale: opt.label})
    };

    refreshList = () =>{
        this.loadUsers();
    };

    deleteCharge = (items) => {
        if(window.confirm("Are you sure you want to delete charges")){
            let _this = this;
            let ids = items.map((item) => {return item._id.$oid});
            let id = this.state.orgData._id.$oid;
            $.ajax({
                method: "DELETE",
                url: baseUrl + "/organizations_v2/"+id+"/billing",
                data: {charges: ids},
                dataType: "json",
                headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
                success: function (result) {
                    _this.loadBilling();
                },error: function(error){
                    alert(error.responseText);
                }
            });
        }
    }

    cancelAddUser = () =>{
        this.setState({addUser: false});
        this.loadUsers();
    };



    componentDidMount() {
        this.loadUsers();
        this.loadVersions();
        this.loadSettings();
        this.loadBilling();
        let org = this.props.orgData;
        this.setState({orgData: {
            name: org.name,
            address: org.address,
            email: org.contact_email,
            contact_number: org.contact_number,
            website: org.website,
            status: org.status,
            contact_person: org.contact_person,
            contact_email: org.contact_email,
            owner_age: org.owner_age,
            owner_gender: org.owner_gender,
            business_type: org.business_type,
            lga: org.lga,
            _id: org._id
        }});
    };

    getProps = ()=>{
        return this.props.returningProps || this.props;
    };

    upgradePackage = () =>{
        this.setState({upgrade: true})
    };

    reedeemVoucher = () => {
        this.setState({redeem: true})
    }

    cancelRedeem = () => {
        this.setState({redeem: false});
    }

    postRedeemVoucher = () => {
        if(this.state.voucher == ''){
            alert("please enter a valid voucher")
            return;
        }

        if (window.confirm("Are you sure you want to redeem voucher?")){
            let _this = this;
            $.ajax({
                method: "PUT",
                url: baseUrl + "/kodo/vouchers/vouchers/"+this.state.voucher,
                data: {org_id: this.state.orgData._id.$oid},
                headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
                success: function(result){
                    _this.setState({redeem: false});
                    _this.loadBilling()
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    alert(jqXHR.responseText);
                }});

        }
    }

    render() {
        const {classes} = this.getProps();

        if(this.state.userData){
            return <UserDetails callerState={this.state} callerProps={this.getProps()} callerName={AccountDetails} userData={this.state.userData}/>;
        }
        if(this.state.edit){
            return <UserInfo callerState={this.state} callerProps={this.getProps()} callerName={AccountDetails} />
        }

        if(this.state.back){
            let ChildComponent = this.state.callerName;
            let state = this.state.callerState;
            return <ChildComponent state = {state} returningProps = {this.state.callerProps} />
        }
        else{
            return (
                <div>
                    {(this.state.activate) ? this.activate(classes) : null}
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={11}>
                                            {(this.state.orgData.status == 'inactive') ? <Button xs={12} sm={12} md={2} color="info" onClick={() =>{this.activateAccount()}}
                                            ><i className="material-icons">how_to_reg</i> {this.getString().activate}</Button> : <Button xs={12} sm={12} md={2} color="info" onClick={this.deactivateAccount.bind(this)}
                                            ><i className="material-icons">person_add_disabled</i> {this.getString().deactivate}</Button>}
                                            <Button xs={12} sm={12} md={2} color="danger" style={{display:'none'}}>
                                                <i className="material-icons">delete</i> {this.getString().delete}</Button>
                                            <Button xs={12} sm={12} md={2} color="success" onClick={() =>{this.setState({addUser: true})}}
                                            ><i className="material-icons">person_add</i> {this.getString().add_user}</Button>
                                            <Button xs={12} sm={12} md={2} color="warning" onClick={() => {this.upgradePackage()}}
                                            ><i className="material-icons">shop</i> {this.getString().upgrade}</Button>
                                            <Button xs={12} sm={12} md={2} onClick={() => {this.reedeemVoucher()}}
                                            >Add Voucher</Button>
                                        </GridItem>


                                        <GridItem xs={12} sm={12} md={1}>
                                            <Button color="danger" onClick={() => {this.setState({back: true})}} style={{float:'right'}}>
                                                {this.getString().back}
                                            </Button>
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={5}>
                            {(this.state.editDetails) ? this.editDetails(classes)
                                :
                                <Card>
                                    <CardHeader style={{color:"#000"}}>
                                        <h4 >{this.getString().details}</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <form>
                                            <GridContainer>
                                                <GridItem xs={12} sm={4} md={4}>
                                                    <p><b>{this.getString().name}:</b></p>
                                                </GridItem>
                                                <GridItem xs={12} sm={8} md={8}>
                                                    <p id="name"><b>{this.state.orgData.name}</b></p>
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={4} md={4}>
                                                    <p><b>{this.getString().address}:</b></p>
                                                </GridItem>
                                                <GridItem xs={12} sm={8} md={8}>
                                                    <p id="name"><b>{this.state.orgData.address}</b></p>
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={4}>
                                                    <p><b> {this.getString().phone}:</b></p>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={8}>
                                                    <p id="phone"><b>{this.state.orgData.contact_number}</b></p>
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={4} md={4}>
                                                    <p><b>{this.getString().email}:</b></p>
                                                </GridItem>
                                                <GridItem xs={12} sm={8} md={8}>
                                                    <p id="email"><b>{this.state.orgData.contact_email}</b></p>
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={4} md={4}>
                                                    <p><b>{this.getString().website}:</b></p>
                                                </GridItem>
                                                <GridItem xs={12} sm={8} md={8}>
                                                    <p id="website"><b>{this.state.orgData.website}</b></p>
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={4} md={4}>
                                                    <p><b>{this.getString().contact_person}:</b></p>
                                                </GridItem>
                                                <GridItem xs={12} sm={8} md={8}>
                                                    <p id="contact_person"><b>{this.state.orgData.contact_person}</b></p>
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={4} md={4}>
                                                    <p><b>{this.getString().status}:</b></p>
                                                </GridItem>
                                                <GridItem xs={12} sm={8} md={8}>
                                                    <p id="status"><b>{this.state.orgData.status}</b></p>
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={4} md={4}>
                                                    <p><b>{this.getString().owner_gender}:</b></p>
                                                </GridItem>
                                                <GridItem xs={12} sm={8} md={8}>
                                                    <p id="contact_person"><b>{this.state.orgData.owner_gender}</b></p>
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={4} md={4}>
                                                    <p><b>{this.getString().owner_age}:</b></p>
                                                </GridItem>
                                                <GridItem xs={12} sm={8} md={8}>
                                                    <p id="contact_person"><b>{this.state.orgData.owner_age}</b></p>
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={4} md={4}>
                                                    <p><b>{this.getString().business_size}:</b></p>
                                                </GridItem>
                                                <GridItem xs={12} sm={8} md={8}>
                                                    <p id="contact_person"><b>{this.state.orgData.business_type}</b></p>
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={4} md={4}>
                                                    <p><b>{this.getString().region}:</b></p>
                                                </GridItem>
                                                <GridItem xs={12} sm={8} md={8}>
                                                    <p id="contact_person"><b>{this.state.orgData.lga}</b></p>
                                                </GridItem>
                                            </GridContainer>
                                        </form>
                                    </CardBody>
                                    <CardFooter>
                                        <Button xs={12} sm={12} md={2} color="info" onClick={this.editOrgDetails.bind(this)}
                                        ><i className="material-icons">edit</i> {this.getString().edit}</Button>
                                    </CardFooter>
                                </Card>
                            }

                        </GridItem>
                        <GridItem xs={12} sm={12} md={7}>
                            {(this.state.editSetting) ? this.editSettings(classes)
                                :
                                <Card>
                                    <CardHeader style={{color:"#000"}}>
                                        <h4 >{this.getString().settings}</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <form>
                                            <GridContainer>
                                                <GridItem xs={12} sm={6} md={6}>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={6} md={7}>
                                                            <p><b> {this.getString().package}:</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={6} md={5}>
                                                            <p id="package"><b>{(this.state.settings) ? this.state.settings.package : ''}</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={6} md={7}>
                                                            <p><b> {this.getString().num_users}:</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={6} md={5}>
                                                            <p id="num_users"><b>{(this.state.metrics) ? this.state.metrics.users : ''}</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={6} md={7}>
                                                            <p><b>{this.getString().num_allowed_users}:</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={6} md={5}>
                                                            <p id="num_allowed_users"><b>{(this.state.metrics) ? this.state.metrics.active : ''}</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={6} md={7}>
                                                            <p><b>{this.getString().num_transaction}:</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={6} md={5}>
                                                            <p id="num_transaction"><b>{(this.state.metrics) ? this.state.metrics.tran_count : ''}</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={6} md={7}>
                                                            <p><b>{this.getString().allowed_transaction}:</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={6} md={5}>
                                                            <p id="allowed_transaction"><b>{(this.state.metrics.package.max_sales === 0) ? 'Unlimited' : this.state.metrics.package.max_sales}</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={6} md={7}>
                                                            <p><b>{this.getString().low_stock_threshold}:</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={6} md={5}>
                                                            <p id="low_stock_threshold"><b>{(this.state.settings) ? this.state.settings.low_stock_level : ''}</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={6} md={7}>
                                                            <p><b>{this.getString().notifications}:</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={6} md={5}>
                                                            <p id="notifications"><b>{(this.state.settings) ? this.state.settings.enable_notifications : ''}</b></p>
                                                        </GridItem>
                                                    </GridContainer>
                                                </GridItem>
                                                <GridItem xs={12} sm={6} md={6}>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={6} md={6}>
                                                            <p><b> {this.getString().balance}:</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={6} md={6}>
                                                            <p id="bal"><b>{(this.state.billing) ? currencyFormat(this.state.billing.balance) : ''}</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={6} md={6}>
                                                            <p><b> {this.getString().term}:</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={6} md={6}>
                                                            <p id="term"><b>{(this.state.settings) ? this.state.settings.term : ''}</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={6} md={6}>
                                                            <p><b>{this.getString().currency}:</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={6} md={6}>
                                                            <p id="currency"><b>{(this.state.settings) ? this.state.settings.default_currency : ''}</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={6} md={6}>
                                                            <p><b>{this.getString().locale}:</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={6} md={6}>
                                                            <p id="locale"><b>{(this.state.settings) ? this.state.settings.locale : ''}</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={6} md={6}>
                                                            <p><b>{this.getString().printer}:</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={6} md={6}>
                                                            <p id="printerType"><b>{(this.state.settings) ? this.state.settings.printer_type : ''}</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={6} md={6}>
                                                            <p><b>{this.getString().vat}:</b></p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={6} md={6}>
                                                            <p id="vat"><b>{(this.state.settings) ? this.state.settings.tax_percentage : ''}</b></p>
                                                        </GridItem>
                                                    </GridContainer>
                                                </GridItem>
                                            </GridContainer>
                                        </form>
                                    </CardBody>
                                    <CardFooter>
                                        <Button xs={12} sm={12} md={2} color="info" onClick={()=>{this.setState({editSetting: true})}}
                                        ><i className="material-icons">edit</i> {this.getString().edit}</Button>
                                    </CardFooter>
                                </Card>
                            }

                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <Card>
                                <CardHeader color="warning">
                                    <h3 className={classes.cardTitleWhite}>{this.getString().users}</h3>
                                </CardHeader>
                                <Table
                                    tableRef={this.tableRef}
                                    data={this.state.users_list}
                                    columns={this.state.header}
                                    onRowClick={this.rowSelectUsers}
                                    options={{
                                        exportButton: true,filtering: true,
                                        grouping:true,sorting:true,
                                        selection:true,showTitle:false,
                                        pageSize:15
                                    }}
                                    actions={[
                                        {
                                            icon: 'refresh',
                                            tooltip: 'Refresh',
                                            isFreeAction: true,
                                            onClick: () => this.refreshList()
                                        },
                                        {
                                            tooltip: 'Not implemented',icon: 'delete',
                                            onClick: (evt, data) => alert('This is not implemented ' + data.length + ' rows')
                                        }
                                    ]}
                                />
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <Card>
                                <CardHeader color="success">
                                    <h3 className={classes.cardTitleWhite}>{this.getString().charges}</h3>
                                </CardHeader>
                                <Table
                                    data={this.state.billing.charges}
                                    tableRef={this.chargeRef}
                                    columns={this.state.chargesHeader}
                                    onRowClick={this.rowSelectUsers}
                                    options={{
                                        exportButton: true,filtering: true,
                                        grouping:true,sorting:true,
                                        selection:true,showTitle:false,
                                        pageSize:15
                                    }}
                                    actions={[
                                        {
                                            tooltip: 'Remove Selected charge',icon: 'delete',
                                            onClick: (evt, data) => {this.deleteCharge(data)}
                                        }
                                    ]}
                                />
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <Card>
                                <CardHeader color="primary">
                                    <h3 className={classes.cardTitleWhite}>{this.getString().payments}</h3>
                                </CardHeader>
                                <Table
                                    data={this.state.billing.payments}
                                    columns={this.state.paymentHeader}
                                    onRowClick={this.rowSelectUsers}
                                    options={{
                                        exportButton: true,filtering: true,
                                        grouping:true,sorting:true,
                                        selection:true,showTitle:false,
                                        pageSize:15
                                    }}
                                    actions={[
                                        {
                                            tooltip: 'Remove Selected sale',icon: 'delete',
                                            onClick: (evt, data) => alert('This is not implemented ' + data.length + ' rows')
                                        }
                                    ]}
                                />
                            </Card>
                        </GridItem>
                    </GridContainer>
                    <Rodal visible={this.state.upgrade} height={310} width={window.innerWidth-100} showMask={false} onClose={this.cancelUpgrade.bind(this)}>
                        <Upgrade callerName={AccountDetails} callerState={this.state} callerProps={this.getProps()} cancelUpgrade = {this.cancelUpgrade} settings ={this.state.settings} package = {this.state.settings.package} orgData={this.state.orgData} />
                    </Rodal>
                    <Rodal visible={this.state.addUser} height={520} width={window.innerWidth-100} showMask={false} onClose={this.cancelAddUser.bind(this)}>
                        <AddUser orgId={this.state.orgData._id} closeRodal={this.cancelAddUser.bind(this)}/>
                    </Rodal>
                    <Rodal visible={this.state.redeem} height={120} width={window.innerWidth-200} showMask={false} onClose={this.cancelRedeem.bind(this)}>
                        <GridContainer>
                            <GridItem sm={12} md={6}>
                                <TextField
                                    id="voucher"
                                    label={"Voucher"}
                                    name="voucher"
                                    className={classes.textField}
                                    value={this.state.voucher}
                                    onChange={e => {this.handleChange(e)}}
                                    type="text"
                                    margin="normal"
                                    variant="outlined"
                                />
                            </GridItem>
                            <GridItem sm={12} >
                                <Button color="success" onClick={this.postRedeemVoucher.bind(this)}>{this.getString().save}</Button>
                                <Button color="danger" onClick={()=> {this.setState({redeem:false})}}>{this.getString().cancel}</Button>
                            </GridItem>
                        </GridContainer>
                    </Rodal>
                </div>
            );
        }
    }
}


export default withStyles(styles)(AccountDetails);

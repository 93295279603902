import React from "react";
import PropTypes from "prop-types";
import 'assets/css/bootstrap.css';
import jwt from 'jsonwebtoken';
// core components
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

const packages = ["Bsc", "Std", "Pr", "Pp"];

class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.divRef = React.createRef();

        this.initDashboard();
    };

    state = this.props.state || {
        iframeUrl: null
    };

    initDashboard = () => {

        var METABASE_SITE_URL = "https://analytics.lecket.gm";
        var METABASE_SECRET_KEY = "6eb957ffa2ebc936913f76af11032d5874274bc5a038fe83e04472106d3e4ce8";

        var payload = {
            resource: { dashboard: 2 },
            params: {
            },
            exp: Math.round(Date.now() / 1000) + (60 * 60)
        };
        var token = jwt.sign(payload, METABASE_SECRET_KEY);

        this.state.iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=true&titled=true";

    };

    getProps = () => {
        return this.props.returningProps || this.props;
    };

    getFrameHeight = () => {
        return (this.divRef.current != null) ? this.divRef.current.parentNode.parentNode.offsetHeight : 800;
    };

    render() {
        const {classes} = this.getProps();
         {
            return (
                <div className={classes.root} ref={this.divRef} style={{height:this.getFrameHeight()}}>
                    <h5>Welcome to our partner page. Let us know if you would like to see more information</h5>
                    <iframe
                        id={"dashFrame"}
                        onLoad={()=> {
                        }}
                        src={this.state.iframeUrl}
                        frameBorder="0"
                        width="100%"
                        height="1024px"
                        overflow="hidden"
                        allowTransparency
                    >
                        <html>
                            <body>
                                <p>We are loading please give some time...</p>
                            </body>
                        </html>
                    </iframe>

                </div>
            );
        }
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Dashboard);
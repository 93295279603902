import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Rodal from 'rodal';

// core components
import GridItem from "../../../components/common/Grid/GridItem.jsx";
import GridContainer from "../../../components/common/Grid/GridContainer.jsx";
import frStrings from  "../../../assets/js/locales_fr.js";
import enStrings from  "../../../assets/js/locales_en.js";
import $ from "jquery";
import cookies from "react-cookies";
import baseUrl from "../../../assets/js/config/const.js";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from "../../../components/common/CustomButtons/Button.jsx";
import 'rodal/lib/rodal.css';
import countryList from 'react-select-country-list';
import {ToastsStore} from "react-toasts";
import {
    currencyFormat,
    dateFormat,

} from "../../../assets/js/utils/utils";
import TextField from "@material-ui/core/TextField/TextField";


const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

const currencies = countryList().getData();

const Country = require('country-js');
const countries = countryList().getData();

class ClaimDetails extends React.Component{
    constructor(props) {
        super(props);

        this.tableRef = React.createRef();
        this.chargeRef = React.createRef();
    }

    getString = () => {
        var strings;
        var lang = window.navigator.language;
        if(lang === 'en' || lang === 'en-US' ){
            strings = enStrings;
        }else{
            strings = frStrings;
        }
        return strings;
    };

    state = this.props.state || {

        claimData: {orders: [], account_name: '',account_number:'',amount:0, bank_name: '',bban_number: '', s_type: '', transaction_complete: false, transaction_fee: 0, payment_applied: false,
        request_proceessed_by:'', request_processed_on:'', request_sent_time:'', request_status: '',payable_to:'',check_number: '',organization:{contact_email: '',contact_person:'',contact_number:'',name:''}},

        pay_to: '',
        summary:'',
        bank_name:'',
        check_number: '',
        callerName: this.props.callerName,
        callerProps: this.props.callerProps,
        callerState: this.props.callerState,
        orgData: this.props.orgData,

    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    displayToast = (message) => {
        return  ToastsStore.success(message,6000);
    };

    close = () => {
        this.setState({back: true})
    };

    sendCheck = ()=> {
        if(this.state.claimData.s_type === 'check' || window.confirm("Merchant requested transfer. Are you sure?") ){
            if(this.state.bank_name === ''){
                alert("You must specify a valid bank name");
            }
            if(this.state.check_number === ''){
                alert("You must specify a valid check number");
            }
            let _this = this;

            $.ajax({
                method: "PUT",
                url: baseUrl + "/finance/revenue/"+this.props.claimId,
                data:{type:'claim', check_number: this.state.check_number, bank_name: this.state.bank_name, pay_to: this.state.pay_to, s_type: 'check', summary: this.state.summary},
                dataType: "json",
                headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
                success: function (result) {
                    _this.setState({claimData: result});
                }
            });
        }
    };

    sendRTGS = () => {

    };

    confirmPicked= () => {
        let _this = this;

        $.ajax({
            method: "PUT",
            url: baseUrl + "/finance/revenue/"+this.props.claimId,
            data:{type:'claim_picked'},
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            success: function (result) {
                _this.displayToast(result.success);
                _this.setState({back: true});
            }
        });
    }

    componentDidMount() {
         this.loadData();
    }

    getProps = ()=>{
        return this.props.returningProps || this.props;
    };

    loadData = () => {
        let id = this.props.claimId;

        let _this = this;

        $.ajax({
            method: "GET",
            url: baseUrl + "/finance/revenue/"+id,
            data:{type:'claim'},
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            success: function (result) {
                _this.setState({claimData: result});
            }
        });
    };

    render() {
        const {classes} = this.getProps();

        if(this.state.back){
            let ChildComponent = this.state.callerName;
            let state = this.state.callerState;
            return <ChildComponent state = {state} returningProps = {this.state.callerProps} />
        }
        else{
            return (
                <GridContainer>
                    <GridItem xs={12}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        Merchant Name:
                                    </TableCell>
                                    <TableCell>
                                        {this.state.claimData.organization.name}
                                    </TableCell>
                                    <TableCell>
                                        Contact Person:
                                    </TableCell>
                                    <TableCell>
                                        {this.state.claimData.organization.contact_person}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        Contact Email:
                                    </TableCell>
                                    <TableCell>
                                        {this.state.claimData.organization.contact_email}
                                    </TableCell>
                                    <TableCell>
                                        Contact Number:
                                    </TableCell>
                                    <TableCell>
                                        {this.state.claimData.organization.contact_number}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </GridItem>
                    <GridItem xs={12}>
                        <Table style={styles.table}>
                            <TableBody>
                                <TableRow>
                                    <TableCell  colSpan={1} style={{fontSize: 15+'px'}}>{'Amount'}</TableCell>
                                    <TableCell colSpan={2} style={{fontSize: 15+'px'}}>{currencyFormat(this.state.claimData.amount)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1} style={{fontSize: 15+'px'}}>{'Account Number'}</TableCell>
                                    <TableCell colSpan={1} style={{fontSize: 15+'px'}}>{this.state.claimData.account_number}</TableCell>
                                    <TableCell colSpan={1} style={{fontSize: 15+'px'}}>{'Account Name'}</TableCell>
                                    <TableCell colSpan={1} style={{fontSize: 15+'px'}}>{this.state.claimData.account_name}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1} style={{fontSize: 15+'px'}}>{'Bank name'}</TableCell>
                                    <TableCell colSpan={1} style={{fontSize: 15+'px'}}>{/*this.state.claimData.mbank_name*/}</TableCell>
                                    <TableCell colSpan={1} style={{fontSize: 15+'px'}}>{'B-Ban Number'}</TableCell>
                                    <TableCell colSpan={1} style={{fontSize: 15+'px'}}>{this.state.claimData.bban_number}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={4} style={{fontSize: 15+'px'}}>{'Orders'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={4}>
                                        <Table>
                                            <TableBody>
                                                {this.state.claimData.orders.map((order) => {
                                                    return <TableRow>
                                                        <TableCell>{'Customer: '+order.customer_name}</TableCell>
                                                        <TableCell>{'Order Number: '+order.shop_order_id}</TableCell>
                                                        <TableCell>{'Order Status: '+order.merchant_status}</TableCell>
                                                        <TableCell>{'Payment Type: '+order.payment_method}</TableCell>
                                                        <TableCell>{'Amount: '+order.order_amount+order.currency}</TableCell>
                                                        <TableCell>{'Order Date: '+dateFormat(order.date_purchased)}</TableCell>
                                                    </TableRow>
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        ________________________________________________________________________
                        <h5>Fees</h5>
                        <Table>
                            <TableBody>
                                <TableCell>Transaction Fee</TableCell>
                                <TableCell>{currencyFormat(this.state.claimData.transaction_fee)}</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>{this.state.claimData.request_status}</TableCell>
                            </TableBody>
                        </Table>
                        <GridContainer>
                            <GridItem>
                                <Table>
                                    <TableBody>
                                        <TableCell>
                                            Claim Type:
                                        </TableCell>
                                        <TableCell>
                                            {this.state.claimData.s_type}
                                        </TableCell>
                                    </TableBody>
                                </Table>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem sm={12}>
                        {(this.state.claimData.request_status === 'sent') ? <GridContainer>
                            <GridItem xs={12} sm={6} md={4} lg={2}>
                                <TextField
                                    autoFocus
                                    id="check_number"
                                    label={'Check Number'}
                                    name="check_number"
                                    className={classes.textField}
                                    value={this.state.check_number}
                                    onChange={e => {
                                        this.handleChange(e)
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4} lg={2}>
                                <TextField
                                    autoFocus
                                    id="bank_name"
                                    label={'Bank name'}
                                    name="bank_name"
                                    className={classes.textField}
                                    value={this.state.bank_name}
                                    onChange={e => {
                                        this.handleChange(e)
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4} lg={2}>
                                <TextField
                                    autoFocus
                                    id="pay_to"
                                    label={'Pay Check To'}
                                    name="pay_to"
                                    className={classes.textField}
                                    value={this.state.pay_to}
                                    onChange={e => {
                                        this.handleChange(e)
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} lg={8}>
                                <TextField
                                    autoFocus
                                    id="summary"
                                    label={'Comments'}
                                    name="summary"
                                    fullWidth
                                    value={this.state.summary}
                                    multiline
                                    rowsMax={6}
                                    onChange={e => {
                                        this.handleChange(e)
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </GridItem>
                            <GridItem  xs={12}  >
                                <Button onClick={()=> {this.sendCheck();}}>process check</Button>
                                <Button onClick={()=>{this.sendRTGS();}}>send rtgs</Button>
                                <Button onClick={() => {this.setState({back: true})}}>
                                    close
                                </Button>
                            </GridItem>
                        </GridContainer>: <GridContainer>
                            <GridItem xs={12} sm={6} md={3} lg={2}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <strong>Check No.</strong>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <strong>{this.state.claimData.check_number}</strong>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={3} lg={2}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <strong>Bank Name</strong>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <strong>{this.state.claimData.bank_name}</strong>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={3} lg={2}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <strong>Payable To</strong>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <strong>{this.state.claimData.payable_to}</strong>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </GridItem>
                            <GridItem  xs={12} sm={6} md={3} lg={2}>
                                <Table>
                                    <TableRow>
                                        <TableCell>
                                            <strong>RTGS REFERENCE</strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <strong>{this.state.claimData.rtgs_reference_number}</strong>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            </GridItem>
                            <GridItem xs={12}>
                                <strong>{this.state.claimData.comments}</strong>
                            </GridItem>
                            <GridItem xs={12}>
                                <Button onClick={() => {this.setState({back: true})}}>
                                    close
                                </Button>
                                <Button onClick={() => {this.confirmPicked()}}>
                                    check delivered
                                </Button>
                            </GridItem>
                            </GridContainer>}
                    </GridItem>

                </GridContainer>
            );
        }
    }
}


export default withStyles(styles)(ClaimDetails);

import React from 'react';
import ReactDOM from 'react-dom';
import "assets/css/bootstrap.css";
import en from 'javascript-time-ago/locale/en';
import JavascriptTimeAgo from 'javascript-time-ago'
import indexRoutes from "routes/index.jsx";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";


const hist = createBrowserHistory();
JavascriptTimeAgo.locale(en);

ReactDOM.render( <Router history={hist}>
                    <Switch>
                         {indexRoutes.map((prop, key) => {
                             return <Route path={prop.path} component={prop.component} key={key} />;
                         })}
                    </Switch>
                 </Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA





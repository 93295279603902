import Dashboard from "components/afrijula/Fsp/Dashboard.jsx";


const dashboardRoutes = [
    {
        path: "/dashboard",
        sidebarName: "Dashboard",
        icon: Dashboard,
        component: Dashboard
    },

    { redirect: true, path: "/", to: "/dashboard", navbarName: "Redirect" , th: 'yoyo'}
];

export default dashboardRoutes;

import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridItem from "components/common/Grid/GridItem.jsx";
import GridContainer from "components/common/Grid/GridContainer.jsx";
import Button from "components/common/CustomButtons/Button.jsx";
import Card from "components/common/Card/Card.jsx";
import CardHeader from "components/common/Card/CardHeader.jsx";
import CardBody from "components/common/Card/CardBody.jsx";
import frStrings from  "assets/js/locales_fr.js";
import enStrings from  "assets/js/locales_en.js";
import $ from "jquery";
import cookies from "react-cookies";
import baseUrl from "assets/js/config/const.js";
import TextField from "@material-ui/core/TextField";
import CardFooter from "components/common/Card/CardFooter.jsx";
import {ToastsStore} from "react-toasts";
import ReactTimeAgo from "react-time-ago";




const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};


class User_Details extends React.Component{
    constructor(props) {
        super(props);

        this.tableRef = React.createRef();
    }

    getString = () => {
        var strings;
        var lang = window.navigator.language;
        if(lang === 'en' || lang === 'en-US' ){
            strings = enStrings;
        }else{
            strings = frStrings;
        }
        return strings;
    };


    state = {

        customerSale: false,
        back: false,
        old_password: "",
        confirm_password: "",
        new_password: "",

        header:  [
            {title: this.getString().name, field: 'name'},
            {title: this.getString().email, field:'email',render: rowData => "beta@science.com"},
            {title: this.getString().type, field: 'type',render: rowData => "cashier"},
        ],


        historydetails: false,
        customerHistory: [],
        currnetCustomerDetails: this.props.rowData,
        pageSize:15,

        user_name: "",
        login_as: false,

        complete: false,
        callerName: this.props.callerName,
        callerProps: this.props.callerProps,
        callerState: this.props.callerState,
        userData: this.props.userData,
        password: this.props.userData.password,
        email: this.props.userData.email,
    };

    rowSelect = (event, colData)  => {
        let id = colData._id.$oid;
        let _this = this;

        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/sales/sales/"+id,
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            success: function (result) {
                _this.setState({salesData: result});
            }
        });
    };

    getProps = ()=>{
        return this.props.returningProps || this.props;
    };

    backButton = ()=>{
       this.setState({back: true});
    };

    savePassword= () =>{
        let _this= this;

        let new_password = this.state.new_password;
        let confirm_password = this.state.confirm_password;
        if(new_password !== confirm_password){
            alert("Pasword mismatch, try again!");
        }

        this.updateUser({new_password: new_password, confirm_password: confirm_password}, this.cancelEdit.bind(this));
    };

    cancelEdit = () =>{
        this.setState({
            edit: false,
            confirm_password: '',
            old_password: '',
            new_password: ''
        });
    };

    resetPassword = (classes)=>{
        return(
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader >
                            <h4 className={classes.cardTitleWhite} style={{color:"#000"}}>{this.getString().reset_password}</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                    <TextField
                                        id="new_password"
                                        label={this.getString().new_password}
                                        name="new_password"
                                        className={classes.textField}
                                        value={this.state.new_password}
                                        onChange={e => {this.handleChange(e)}}
                                        type="password"
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <TextField
                                        id="confirm_password"
                                        label={this.getString().confirm_password}
                                        name="confirm_password"
                                        className={classes.textField}
                                        value={this.state.confirm_password}
                                        onChange={e => {this.handleChange(e)}}
                                        type="password"
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <GridItem xs={12} sm={12} md={6}>
                                <Button xs={12} sm={12} md={2} color="danger" onClick={this.cancelEdit} >{this.getString().cancel}</Button>
                                <Button xs={12} sm={12} md={2} color="success" onClick={this.savePassword} >{this.getString().reset}</Button>
                            </GridItem>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    };

    handleChange = (e) =>{
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    loginAs = (classes) =>{
        return(
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader >
                            <h4 className={classes.cardTitleWhite} style={{color: "#000"}}>{this.getString().login}</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                    <TextField
                                        id="email"
                                        label={this.getString().email}
                                        name="email"
                                        className={classes.textField}
                                        value={this.state.email}
                                        onChange={e => {this.handleChange(e)}}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <TextField
                                        id="password"
                                        label={this.getString().password}
                                        name="password"
                                        className={classes.textField}
                                        value={this.state.password}
                                        onChange={e => {this.handleChange(e)}}
                                        type="password"
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <GridItem xs={12} sm={12} md={6}>
                                <Button xs={12} sm={6} md={2} color="danger" onClick={() =>{this.setState({login_as: false})}} >{this.getString().cancel}</Button>
                                <Button xs={12} sm={6} md={2} color="success" onClick={this.connect} >{this.getString().login}</Button>
                            </GridItem>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    };

    displayToast = (message) => {
        return  ToastsStore.error(message,6000);
    };

    connect = (e) => {
        e.preventDefault();
        let user_email = document.getElementById("email").value;
        let user_pswd = document.getElementById("password").value;
        let _this = this;
        //sbensouda: I am adding another security layer. Not that react has huge security
        //risk if not deployed with HTTPS. the key and token are sent in flight making man
        //in middle attach very likely.
        let r = Math.random().toString(36);
        cookies.save('User-Key',r);
        $.ajax({
            method: "POST",
            url: baseUrl + "/account/authenticate_v2/authenticate",
            headers:{"UserKey":r},
            data: {"email": user_email, "password": user_pswd,influence:'afrijula',r:r},
            dataType:"json",
            success: function(result){
                let expires = new Date();
                expires = new Date(expires.getTime() + ((result.age-30)*1000));
                cookies.save('token',result.auth_token, {path:'/',expires: expires});
                cookies.save('profile',result.profile, {path:'/',expires: expires});
                cookies.save('profileId',result.profile_id, {path:'/',expires:expires});
                _this.props.history.push({
                    pathname: '',

                    state: {user: {username: user_email, user_type: _this.getUserType(result.user_type)[2]},mobileOpen: false}
                });
            },
            error: function (jqXHR, textStatus, errorThrown) {
                _this.displayToast(errorThrown+ "," + jqXHR.responseText);

            }
        });

    };

    blockUser = () => {
        this.updateUser({status: 'blocked'});
    }

    unblockUser = () => {
        this.updateUser({status: 'activated'});
    }

    updateUser = (data,cback) =>{
        let _this = this;
        $.ajax({
            method: "PUT",
            url: baseUrl + "/account/users/"+this.state.userData.id,
            data:data,
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            success: function (result) {
                let data = _this.state.userData;

                data.middle_names = result.user.middle_names;
                data.last_name = result.user.last_name;
                data.first_name = result.user.first_name;
                data.status = result.user.status;
                data.user_type = result.user.user_type;
                data.email = result.user.email;
                data.date_of_birth = result.user.date_of_birth;
                data.locale = result.user.locale
                _this.setState({salesData: data});
                if(cback)
                    cback();
            }
        });
    };

    render() {
        const {classes} = this.getProps();
        let middleName = (this.state.userData.middle_names === null) ? "" : this.state.userData.middle_names;
        let fullName = this.state.userData.first_name+" "+middleName+" "+this.state.userData.last_name;

        if(this.state.back){
            let ChildComponent = this.state.callerName;
            let state = this.state.callerState;
            state.userData = null;
            return <ChildComponent state = {state} returningProps = {this.state.callerProps} />
        }
        else{
            return (

                <div>
                    {(this.state.edit) ? this.resetPassword(classes) : null}
                    {(this.state.login_as) ? this.loginAs(classes) : null}
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={8}>
                                            {(this.state.userData.status == 'activated') ?
                                                <Button xs={12} sm={12} md={2} color="success" onClick={this.blockUser.bind(this)}><i className="material-icons">block</i> {this.getString().bloc_user}</Button> :
                                                <Button xs={12} sm={12} md={2} color="success" onClick={this.unblockUser.bind(this)}><i className="material-icons">unblock</i> Unblock user</Button>}
                                            <Button xs={12} sm={12} md={2} color="primary" onClick={() =>{this.setState({edit:true})}}><i className="material-icons">edit</i> {this.getString().reset_password}</Button>
                                            <Button xs={12} sm={12} md={2} color="warning" onClick={()=>{this.setState({login_as: true})}} ><i className="material-icons">perm_identity</i> {"login as"}</Button>
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={2}>

                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={2}>
                                            <Button color="danger" onClick={this.backButton} style={{float:'right'}}>
                                                {this.getString().back}
                                            </Button>
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <Card>
                                <CardHeader color="primary">
                                    <h4 >{this.getString().details}</h4>
                                </CardHeader>
                                <CardBody>
                                    <form>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b>{this.getString().name}:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p id="name"><b>{fullName}</b></p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b> {this.getString().dob}:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p id="date_of_birth"><b>{this.state.userData.date_of_birth}</b></p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b>{this.getString().email}:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p id="email"><b>{this.state.userData.email}</b></p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b>{this.getString().user_type}:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p id="user_type"><b>{this.state.userData.user_type}</b></p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b>{this.getString().ip}:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p id="recall_ip"><b>{this.state.userData.recall_ip}</b></p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b>{this.getString().status}:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p id="status"><b>{this.state.userData.status}</b></p>
                                            </GridItem>
                                        </GridContainer>
                                    </form>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <Card>
                                <CardHeader color="primary">
                                    <h4 >{this.getString().parameters}</h4>
                                </CardHeader>
                                <CardBody>
                                    <form>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b> {this.getString().last_active}:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p id="last_login_date"><b>{<ReactTimeAgo date={new Date(this.state.userData.last_login_date)} locale="en" />}</b></p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b>{this.getString().organisation}:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p id="organization"><b>{this.state.userData.organization}</b></p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b>{this.getString().locale}:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p id="prefered_locale"><b>{this.state.userData.prefered_locale}</b></p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b>{this.getString().currency}:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p id="type"><b>{this.state.userData.account_currency}</b></p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b>{this.getString().balance}:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p id="account_balance"><b>{this.state.userData.account_balance}</b></p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p><b>{this.getString().login_attempts}:</b></p>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <p id="login_attempts"><b>{this.state.userData.login_attempts}</b></p>
                                            </GridItem>
                                        </GridContainer>
                                    </form>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            );
        }
    }
}


export default withStyles(styles)(User_Details);

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import GridItem from "../../../components/common/Grid/GridItem.jsx";
import GridContainer from "../../../components/common/Grid/GridContainer.jsx";
import Button from "../../../components/common/CustomButtons/Button.jsx";
import Card from "../../../components/common/Card/Card.jsx";
import CardHeader from "../../../components/common/Card/CardHeader.jsx";
import CardBody from "../../../components/common/Card/CardBody.jsx";
import CardFooter from "../../../components/common/Card/CardFooter.jsx";
import {numberFormat, currencyFormat, dateFormat,compileQuery} from "../../../assets/js/utils/utils.js";

import Account_Billing from "../../../components/afrijula/Settings/Account_Billing.jsx";

import frStrings from  "../../../assets/js/locales_fr.js";
import enStrings from  "../../../assets/js/locales_en.js";
import $ from "jquery";
import cookies from "react-cookies";
import baseUrl from "../../../assets/js/config/const.js";



const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});

class PayBill extends React.Component {

    getString = () => {
        var strings;
        var lang = window.navigator.language;
        if(lang === 'en' || lang ===  'en-US' ){
            strings = enStrings;
        }else{
            strings = frStrings;
        }
        return strings;
    };

    state = {


        amount: this.props.price,
        currentPackage: this.props.package,
        pay_bill: false,
        upgrade_version: false,
        back_acc_bill: false,
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    cancelPayment = () =>{

        this.setState({currentPackage: ''});
        this.setState({amount: ''});

        this.setState({back_acc_bill: true});
    };

    payBill = () => {

        let paybill = {"amount": this.state.amount, "package": this.state.currentPackage};

        let _this = this;
        $.ajax({
            method: "POST",
            url: baseUrl + "/afrijula/afrijula/paybills",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: paybill,

            success: function (result) {

            }
        });
        this.setState({back_acc_bill:  true});
    };




    render() {
        const { classes } = this.props;

        if(this.state.back_acc_bill){
            return <Account_Billing />;
        }

        return (

            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader >
                                <h4 className={classes.cardTitleWhite}>{this.getString().pay_bill}</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <TextField
                                            id="version"
                                            name="currentPackage"
                                            label={this.getString().version}
                                            className={classes.textField}
                                            value={this.state.currentPackage}
                                            onChange={e => {this.handleChange(e)}}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <TextField
                                            id="Amount"
                                            label={this.getString().amount}
                                            name="amount"
                                            className={classes.textField}
                                            value={currencyFormat(this.state.amount)}
                                            onChange={e => {this.handleChange(e)}}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                </GridContainer>

                            </CardBody>
                            <CardFooter>
                                <Button color="danger" onClick={this.cancelPayment}>{this.getString().cancel}</Button>
                                <Button color="success" onClick={this.payBill} >{this.getString().pay}</Button>
                            </CardFooter>
                        </Card>
                    </GridItem>

                </GridContainer>
            </div>
        );
    }
}

PayBill.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PayBill);

import React from "react";
import PropTypes from "prop-types";
import Icon from "@material-ui/core/Icon";
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import 'assets/css/bootstrap.css';
import Table from "@material-table/core";
import cookies from "react-cookies";
import Card from "../../../components/common/Card/Card.jsx";
import CardHeader from "../../../components/common/Card/CardHeader.jsx";
import CardIcon from "../../../components/common/Card/CardIcon.jsx";
import CardFooter from "../../../components/common/Card/CardFooter.jsx";
import GridItem from "../../../components/common/Grid/GridItem.jsx";
import GridContainer from "../../../components/common/Grid/GridContainer.jsx";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import baseUrl from "assets/js/config/const.js";
import $ from "jquery";
import {ToastsContainer, ToastsContainerPosition, ToastsStore} from "react-toasts";
import * as utils from "../../../assets/js/utils/utils";
import {currencyFormat, dateFormat, getString, setGlobal} from "../../../assets/js/utils/utils";
import AddLoan from "./AddLoanProduct"
import LoanDetails from "./LoanDetails";
import LoanProductDetails from "./LoanProductDetails";

//Temporary definition for the Coming Soon component
const ComingSoon = () => <h3>Dashboard Coming Soon!</h3>;

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.tableRefProcLoan = React.createRef();
        this.tableRefPendLoan = React.createRef();
        this.tableRefLoanProd = React.createRef();

        this.state = this.props.state || {
            loansHeader: [
                {title: getString().name, field: 'name', cellStyle: {padding: '10px'}, headerStyle: {padding: '4px'}},
                {
                    title: 'Amount',
                    field: 'amount',
                    render: rowData => currencyFormat(rowData.amount),
                    cellStyle: {padding: '4px'},
                    headerStyle: {padding: '4px'}
                },
                {title: 'Applicant', field: 'applicant', cellStyle: {padding: '4px'}, headerStyle: {padding: '4px'}},
                {
                    title: 'Applied On',
                    field: 'created_at',
                    render: rowData => dateFormat(rowData.created_at),
                    cellStyle: {padding: '4px'},
                    headerStyle: {padding: '4px'}
                },
                {title: 'Status', field: 'status', cellStyle: {padding: '4px'}, headerStyle: {padding: '4px'}},
            ],
            pendingLoans: [],
            processedLoans: [],
            lastPage: null,
            recalledPage: null,
            product: null,
            edit: false,
            partners: false,
            processData: '',
            tilesCountData: {
                pending: '',
                approved: '',
                rejected: '',
                total: '',
            },
            loanData: null,
            loanProdData: null,
            addLoanProduct: false,
            loanId: null
        }
    };

    getProps = () => {
        return this.props.returningProps || this.props;
    };

    loadLoanProductsData = (query, resolve, reject) => {
        let url = utils.compileQuery(query, (baseUrl + "/afrijula/loans/loan_products/"),
            ["id", 'name', 'business_age', 'minimum_amount', 'legal_status', 'minimum_age',
                'processing_time', 'maximum_amount', 'purpose', 'nationality', 'business_location',
                'tenor', 'payment_start_date', 'administration_fee', 'interest_rate',
                'repayment_frequency', 'penalty', 'required_documents'], [{
                "by": "desc",
                "attr": "name"
            }],
            "Afrijula::Loans::LoanProduct", null, null, null);
        fetch(url, {
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            }
        })
            .then(response => {
                return response.json()
            })
            .then(result => {
                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total
                })
            });
    };

    loadAppliedLoans = (query, resolve, reject, status=null) => {
        let operator = status ? '$eq' : '$ne';
        let filter = "status: {'"+operator+"':'Pending'}, fsp_id: {'$eq':'" + cookies.load("orgId") + "'}";
        query.scope = true;
        let url = utils.compileQuery(query, (baseUrl + "/afrijula/loans/loans"),
            ["id", 'applicant', 'comments','applicant_contact', 'repayment_frequency', 'tenor', 'required_documents', 'applicant_age', 'age_of_business', 'name', 'status', 'amount', 'created_at', 'rejection_reason'], [{"by": "asc", "attr": "created_at"}],
            "Afrijula::Loans::Loan", null, filter, null, null, null);
        fetch(url, {
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            }
        })
            .then(response => {
                return response.json()
            })
            .then(result => {
                if(result.data && result.data.length > 0){
                    resolve({
                        data: result.data.filter(d => d.status !== 'Unfinished'),
                        page: query.page,
                        totalCount: result.total
                    })
                }else{
                    resolve({
                        data: result.data,
                        page: query.page,
                        totalCount: result.total
                    })
                }
            });
    };

    loadTilesData = () => {
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/afrijula/dashboard",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {count: ['fsp_loans_count_data'], chart: []},
            success: function (result) {
                let temp = {...this.state.tilesCountData};
                let data = result.counts.fsp_loans_count_data;
                temp.approved = data.approved;
                temp.rejected = data.rejected;
                temp.pending = data.pending;
                temp.total = data.total_loan_products;
                this.setState({tilesCountData: temp});
            }.bind(this)
        });
    };

    rowSelectPending = (e, rowData) => {
        this.setState({loanId: rowData._id.$oid, loanData: rowData});
    };

    rowSelectLoanProd = (event, colData) => {
        let id = colData._id.$oid;
        let _this = this;
        $.ajax({
            method: "GET",
            url: baseUrl + "/afrijula/loans/loan_products/" + id,
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            success: function (result) {
                _this.setState({loanProdData: result});
            }
        });

    };

    cancelAddLoanProduct = () => {
        this.loadTilesData();
        this.setState(prevState => ({addLoanProduct: !prevState.addLoanProduct}));
    };

    addLoanProduct = () => {this.setState({addLoanProduct: true})};

    componentDidMount() {
        this.loadTilesData();
        setGlobal(this);
    };

    render() {
        let _state = this.state;
        const {classes} = this.getProps();
        if (_state.loanProdData) {
            let data = _state.loanProdData;
            _state.loanProdData = null;
            this.state.recalledPage = this.state.lastPage;
            return <LoanProductDetails callerState={_state} callerProps={this.getProps()}
                                       callerName={Dashboard}
                                       loanProdData={data}
                                       fromDashboard={true}
            />;
        }
        else if (_state.addLoanProduct) {
            this.state.recalledPage = this.state.lastPage;
            return <AddLoan callerState={_state} callerProps={this.getProps()}
                            callerName={Dashboard} for={'Admin'} cancelAddLoanProduct={this.cancelAddLoanProduct}
            />
        }
        else if (_state.loanId) {
            let _loanId = _state.loanId;
            _state.loanId = null;
            return <LoanDetails callerState={_state} callerProps={this.getProps()}
                                callerName={Dashboard}
                                loanId={_loanId}
            />;
        }
        else {
            return (
                <div style={{marginTop: -40}}>
                    <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT}/>
                    {
                        (_state.product !== null && (_state.product.value === "Kodo" || _state.product.value === "Jangal::JangalSchool")) ?
                            <ComingSoon/> :
                            <div>
                                <GridContainer style={{marginTop: 40}}>
                                    <GridItem xs={12} sm={6} md={3}>
                                        <Card>
                                            <CardHeader color="warning" stats icon>
                                                <CardIcon color="warning">
                                                    <Icon>people</Icon>
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Pending Loan Applications</p>
                                                <h3 className={classes.cardTitle}>
                                                    {_state.tilesCountData.pending}
                                                </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>

                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={3}>
                                        <Card>
                                            <CardHeader color="success" stats icon>
                                                <CardIcon color="success">
                                                    <Icon>done_all</Icon>
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Successful Applications</p>
                                                <h3 className={classes.cardTitle}>
                                                    {_state.tilesCountData.approved}
                                                </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>

                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={3}>
                                        <Card>
                                            <CardHeader color="danger" stats icon>
                                                <CardIcon color="danger">
                                                    <Icon>block</Icon>
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Declined Applications</p>
                                                <h3 className={classes.cardTitle}>
                                                    {_state.tilesCountData.rejected}
                                                </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>

                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={3}>
                                        <Card>
                                            <CardHeader color="primary" stats icon>
                                                <CardIcon color="primary">
                                                    <TrendingDownIcon/>
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Total Loan Products</p>
                                                <h3 className={classes.cardTitle}>
                                                    {_state.tilesCountData.total}
                                                </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>

                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <Card>
                                            <CardHeader color="success">
                                                <h4 className={classes.cardTitleWhite}>Pending Applications</h4>
                                                <p className={classes.cardCategoryWhite}></p>
                                            </CardHeader>
                                            <Table
                                                data={query => new Promise((resolve, reject) => {
                                                    this.loadAppliedLoans(query, resolve, reject, "Pending");
                                                })}
                                                tableRef={this.tableRefPendLoan}
                                                columns={_state.loansHeader}
                                                onRowClick={(event, rowData) => this.rowSelectPending(event, rowData)}
                                                options={{
                                                    exportButton: true, filtering: true,
                                                    grouping: true, sorting: true,
                                                    selection: false, showTitle: false,
                                                    pageSize: 10
                                                }}
                                                actions={[
                                                    {
                                                        icon: 'refresh', tooltip: 'Refresh', isFreeAction: true,
                                                        onClick: () => this.tableRefPendLoan.current && this.tableRefPendLoan.current.onQueryChange(),
                                                    },
                                                ]}
                                            />
                                        </Card>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <Card>
                                            <CardHeader color="warning">
                                                <h4 className={classes.cardTitleWhite}>Processed Applications</h4>
                                                <p className={classes.cardCategoryWhite}></p>
                                            </CardHeader>
                                            <Table
                                                data={query => new Promise((resolve, reject) => {
                                                    this.loadAppliedLoans(query, resolve, reject);
                                                })}
                                                tableRef={this.tableRefProcLoan}
                                                onRowClick={(event, rowData) => this.rowSelectPending(event, rowData)}
                                                columns={_state.loansHeader}
                                                options={{
                                                    exportButton: true, filtering: true,
                                                    grouping: true, sorting: true,
                                                    selection: false, showTitle: false,
                                                    pageSize: 10
                                                }}
                                                actions={[
                                                    {
                                                        icon: 'refresh', tooltip: 'Refresh', isFreeAction: true,
                                                        onClick: () => this.tableRefProcLoan.current && this.tableRefProcLoan.current.onQueryChange(),
                                                    },
                                                ]}
                                            />
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Card>
                                            <CardHeader color="info">
                                                <h4 className={classes.cardTitleWhite}>Loan Products</h4>
                                                <p className={classes.cardCategoryWhite}></p>
                                            </CardHeader>
                                            <Table
                                                data={query => new Promise((resolve, reject) => {
                                                    this.loadLoanProductsData(query, resolve, reject);
                                                })}
                                                tableRef={this.tableRefLoanProd}
                                                columns={[
                                                    {
                                                        title: 'Name',
                                                        field: 'name',
                                                        cellStyle: {padding: '10px'},
                                                        headerStyle: {padding: '4px'}
                                                    },
                                                    {
                                                        title: "Purpose",
                                                        field: 'purpose',
                                                        cellStyle: {padding: '4px'},
                                                        headerStyle: {padding: '4px'}
                                                    },
                                                    {
                                                        title: "Minimum Amount",
                                                        field: 'minimum_amount',
                                                        cellStyle: {padding: '4px'},
                                                        render: rowData => {
                                                            return currencyFormat(rowData.minimum_amount)
                                                        },
                                                        headerStyle: {padding: '4px'}
                                                    },
                                                    {
                                                        title: "Maximum Amount",
                                                        field: 'maximum_amount',
                                                        render: rowData => {
                                                            return currencyFormat(rowData.maximum_amount)
                                                        },
                                                        cellStyle: {padding: '4px'},
                                                        headerStyle: {padding: '4px'}
                                                    },
                                                    {
                                                        title: "Tenor (Months)",
                                                        field: 'tenor',
                                                        cellStyle: {padding: '4px'},
                                                        headerStyle: {padding: '4px'}
                                                    },
                                                    {
                                                        title: "Interest Rate",
                                                        field: 'interest_rate',
                                                        cellStyle: {padding: '4px'},
                                                        headerStyle: {padding: '4px'}
                                                    }
                                                ]}
                                                onRowClick={this.rowSelectLoanProd}
                                                options={{
                                                    exportButton: true, filtering: true,
                                                    grouping: true, sorting: true,
                                                    selection: false, showTitle: false,
                                                    pageSize: 5
                                                }}
                                                actions={[
                                                    {
                                                        icon: 'refresh', tooltip: 'Refresh', isFreeAction: true,
                                                        onClick: () => this.tableRefLoanProd.current && this.tableRefLoanProd.current.onQueryChange(),
                                                    },
                                                    {
                                                        icon: 'add', tooltip: 'Add Loan Product', isFreeAction: true,
                                                        onClick: () => this.addLoanProduct(),
                                                    },
                                                ]}
                                            />
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                            </div>
                    }
                </div>
            );
        }
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Dashboard);

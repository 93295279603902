import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import GridItem from "../../../components/common/Grid/GridItem.jsx";
import GridContainer from "../../../components/common/Grid/GridContainer.jsx";
import Button from "../../../components/common/CustomButtons/Button.jsx";
import Card from "../../../components/common/Card/Card.jsx";
import CardHeader from "../../../components/common/Card/CardHeader.jsx";
import CardBody from "../../../components/common/Card/CardBody.jsx";
import CardFooter from "../../../components/common/Card/CardFooter.jsx";

import Async from 'react-select/async';
import MenuItem from '@material-ui/core/MenuItem';

import frStrings from "../../../assets/js/locales_fr.js";
import enStrings from "../../../assets/js/locales_en.js";
import $ from "jquery";
import cookies from "react-cookies";
import baseUrl from "../../../assets/js/config/const.js";
import {ToastsContainer, ToastsContainerPosition, ToastsStore} from 'react-toasts';
import {getString, phonenumber, validateEmail} from "../../../assets/js/utils/utils.js";


const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});

class Add_User extends React.Component {

    getString = () => {
        var strings;
        var lang = window.navigator.language;
        if (lang === 'en' || lang === 'en-US') {
            strings = enStrings;
        } else {
            strings = frStrings;
        }
        return strings;
    };

    state = {

        first_name: '',
        last_name: '',
        phone: '',
        address: '',
        email: '',
        back: false,
        location: '',

        user_name: "",
        user_type: "",
        password: "",
        selected_status: "activate",
        selected_user_type: 'cashier',
        add_user: false,
        user_types: [
            {
                value: 'cashier',
                label: 'Cashier',
            },
            {
                value: 'admin',
                label: 'Admin',
            },
            {
                value: 'store manager',
                label: 'Store Manager',
            },
            {
                value: 'accountant',
                label: 'Accountant',
            }
        ],
        status: [
            {
                value: 'activate',
                label: 'Activate',
            },
            {
                value: 'unactivate',
                label: 'Unactivate',
            },

        ],

        orgId: this.props.orgId,
        callerName: this.props.callerName,
        callerProps: this.props.callerProps,
        callerState: this.props.callerState,

    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    saveProfile = () => {
        let _this = this;
        let first_name = this.state.first_name;
        let last_name = this.state.last_name;
        let password = this.state.password;
        let user_type = this.state.selected_user_type.toLowerCase();
        let status = this.state.selected_status;
        let phone = this.state.phone;
        let email = this.state.email;

        if (email === "" || !validateEmail(email)) {
            _this.displayToast("Please enter a valid email address", "error");
            document.getElementById("email").focus();
            return;
        }

        if (phone === "" || !phonenumber(phone)) {
            _this.displayToast("Please enter telephone number", "error");
            document.getElementById("phone").focus();
            return;
        }

        let id = (this.props.for == 'Admin') ? null : this.state.orgId.$oid;

        $.ajax({
            method: "POST",
            url: baseUrl + "/account/users/",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {
                "email": email, "password": password, "user_type": user_type, org_id: id, auto_gen: true, for_account: true,
                'first_name': first_name, phone: phone, 'last_name': last_name, influence: 'InSIST', status: status
            },

            success: function (result) {
                _this.displayToast("user successfully created ", "success");
                _this.cancelAdding();

            }.bind(this),
            error: function(error){
                alert(error.responseText);
            }
        });


    };

    displayToast = (message, status) => {
        if (status === "success")
            return ToastsStore.success(message, 6000);
        if (status === "error")
            return ToastsStore.error(message, 6000);
    };

    cancelAdding = () => {
        if (this.props.closeRodal)
            this.props.closeRodal();
        else
            this.setState({back: true});
    };

    locationsOptions = (name) => {
        const url = baseUrl + '/afrijula/locations/location?attrs=["name"]&model=Afrijula::Locations::Location&unique=name&match=' + name;
        return fetch(url, {
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            }
        })
            .then(response => {
                return response.json();
            }).then(json => {
                let opts = [];
                let i = 0;

                for (; i < json.length; i++) {
                    opts[i] = {label: json[i], value: json[i]};
                }
                return opts;
            })
            .catch(err => {

            });
    };

    render() {
        const {classes} = this.props;

        if (this.state.back) {
            let ChildComponent = this.state.callerName;
            return <ChildComponent state={this.state.callerState} returningProps={this.state.callerProps}/>
        }
        return (

            <div>
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT}/>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader>
                                <h4 className={classes.cardTitleWhite}>{this.getString().add_user}</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <TextField
                                            autoFocus
                                            id="first_name"
                                            label={this.getString().first_name}
                                            name="first_name"
                                            className={classes.textField}
                                            value={this.state.first_name}
                                            onChange={e => {
                                                this.handleChange(e)
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                        />

                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <TextField
                                            autoFocus
                                            id="last_name"
                                            label={this.getString().last_name}
                                            name="last_name"
                                            className={classes.textField}
                                            value={this.state.last_name}
                                            onChange={e => {
                                                this.handleChange(e)
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                        />

                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <TextField
                                            id="address"
                                            label={this.getString().address}
                                            name="address"
                                            className={classes.textField}
                                            value={this.state.address}
                                            onChange={e => {
                                                this.handleChange(e)
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                </GridContainer>

                                <GridContainer>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <TextField
                                            id="email"
                                            label={this.getString().email}
                                            name="email"
                                            className={classes.textField}
                                            value={this.state.email}
                                            onChange={e => {
                                                this.handleChange(e)
                                            }}
                                            type="email"
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <TextField
                                            id="phone"
                                            label={this.getString().telephone}
                                            name="phone"
                                            className={classes.textField}
                                            value={this.state.phone}
                                            onChange={e => {
                                                this.handleChange(e)
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                    {(this.props.for == 'Admin') ? <div/> :
                                        <GridItem xs={12} sm={6} md={2}>
                                            <Async loadOptions={this.locationsOptions} defaultOptions
                                                   placeholder={this.getString().location} name="location" id="location"
                                                   onChange={opt => {
                                                       this.setState({location: opt.label})
                                                   }}/>
                                        </GridItem>}
                                </GridContainer>

                                <GridContainer>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <TextField
                                            id="password"
                                            label={this.getString().password}
                                            name="password"
                                            className={classes.textField}
                                            value={this.state.password}
                                            onChange={e => {
                                                this.handleChange(e)
                                            }}
                                            type="password"
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        {(this.props.for == 'Admin') ? <TextField
                                                id="user_type"
                                                name="user_type"
                                                select
                                                label={'Admin Type'}
                                                className={classes.textField}
                                                value={this.state.selected_user_type}
                                                onChange={e => {
                                                    this.setState({selected_user_type: e.target.value})
                                                }}
                                                SelectProps={{
                                                    MenuProps: {
                                                        className: classes.menu,
                                                    },
                                                }}
                                                helperText={"Select a admin type"}
                                                margin="normal"
                                                variant="outlined"
                                            >
                                                {["Admin","Finance","Support"].map(option => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </TextField> :

                                            <TextField
                                                id="user_type"
                                                name="user_type"
                                                select
                                                label={this.getString().user_type}
                                                className={classes.textField}
                                                value={this.state.selected_user_type}
                                                onChange={e => {
                                                    this.handleChange(e)
                                                }}
                                                SelectProps={{
                                                    MenuProps: {
                                                        className: classes.menu,
                                                    },
                                                }}
                                                helperText={this.getString().select_user_type}
                                                margin="normal"
                                                variant="outlined"
                                            >
                                                {this.state.user_types.map(option => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        }
                                    </GridItem>
                                </GridContainer>

                            </CardBody>
                            <CardFooter>
                                <GridItem xs={12} sm={12} md={6}>
                                    <Button xs={12} sm={6} md={2} color="danger" onClick={this.props.cancelAddUser}
                                    >{getString().cancel}</Button>
                                    <Button color="success"
                                            onClick={this.saveProfile}>{this.getString().save_settings}</Button>
                                </GridItem>

                            </CardFooter>
                        </Card>
                    </GridItem>

                </GridContainer>
            </div>
        );
    }
}

Add_User.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Add_User);

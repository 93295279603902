import React from 'react';
import cookies from 'react-cookies';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import {ToastsContainer, ToastsContainerPosition, ToastsStore} from 'react-toasts';
import frStrings from  "assets/js/locales_fr.js";
import enStrings from  "assets/js/locales_en.js";
import baseUrl from "assets/js/config/const.js";

import $ from "jquery";


const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
});


class SignIn extends React.Component{

    getString = () => {
        let strings;
        let lang = window.navigator.language;
        if(lang === 'en' || lang ===  'en-US' ){
            strings = enStrings;
        }else{
            strings = frStrings;
        }
        return strings;
    };

    displayToast = (message) => {
        return  ToastsStore.error(message,6000);
    };
    getUserType = (userType) => {
        let names = userType.split('::');
        let x = [names[0], names.splice(1, names.length - 2, names[names.length-1])];
        return names;
    };
    validation = (e) => {
        e.preventDefault();
        let user_email = document.getElementById("email").value;
        let user_pswd = document.getElementById("password").value;
        let _this = this;
        //sbensouda: I am adding another security layer. Not that react has huge security
        //risk if not deployed with HTTPS. the key and token are sent in flight making man
        //in middle attach very likely.
        let r = Math.random().toString(36);
        cookies.save('User-Key',r);
        const hostname = window.location.hostname;
        const influence = (hostname.includes('partner')) ? 'Partner' : 'InSIST'
        // const influence = 'Partner'
        $.ajax({
            method: "POST",
            url: baseUrl + "/account/authenticate_v2/authenticate",
            headers:{"UserKey":r},
            data: {"email": user_email, "password": user_pswd,influence:influence,r:r},
            dataType:"json",
            success: function(result){
                let expires = new Date();
                expires = new Date(expires.getTime() + ((result.age-30)*1000));
                cookies.save('token',result.auth_token, {path:'/',expires: expires});
                cookies.save('orgId',result.org.id, {path:'/',expires: expires});
                cookies.save('profile',result.profile, {path:'/',expires: expires});
                cookies.save('profileId',result.profile_id, {path:'/',expires:expires});
                cookies.save('userName',result.user_name, {path:'/',expires:expires});
                cookies.save('userEmail',user_email);
                _this.props.history.push({
                    pathname: '/dashboard',
                    state: {user: {username: user_email,partner_type: result.partner_type, user_type: _this.getUserType(result.user_type)[2]},mobileOpen: false}
                });
            },
            error: function (jqXHR, textStatus, errorThrown) {
                _this.displayToast(errorThrown+ "," + jqXHR.responseText);

            }
        });

    };

    render(){
        const { classes } = this.props;
        return(

            <main className={classes.main}>
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT}/>
                <CssBaseline />
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <form className={classes.form}>

                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="email">{this.getString().email}</InputLabel>
                            <Input id="email" name="email" autoFocus />
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="password">{this.getString().password}</InputLabel>
                            <Input name="password" type="password" id="password"  />
                        </FormControl>
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={this.validation}
                        >
                            Sign in
                        </Button>
                    </form>
                </Paper>
            </main>
        );
    }
}

SignIn.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);

import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
// @material-ui/core
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import EmailIcon from '@material-ui/icons/Email';
import View from '@material-ui/icons/Visibility';
import AccessTime from "@material-ui/icons/AccessTime";
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import 'assets/css/bootstrap.css';
// core components
import MaterialTable from '@material-table/core'
import cookies from "react-cookies";
import Card from "../../../components/common/Card/Card.jsx";
import CardHeader from "../../../components/common/Card/CardHeader.jsx";
import CardBody from "../../../components/common/Card/CardBody.jsx";
import CardIcon from "../../../components/common/Card/CardIcon.jsx";
import GridItem from "../../../components/common/Grid/GridItem.jsx";
import GridContainer from "../../../components/common/Grid/GridContainer.jsx";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import baseUrl from "assets/js/config/const.js";
import {ToastsContainer, ToastsContainerPosition, ToastsStore} from "react-toasts";

import {
    compileQuery,
    currencyFormat,
    setGlobal,
    dateFormat,
    showReportUtility,
    getString,
    displaySuccess, displayError
} from "../../../assets/js/utils/utils";
import ClaimDetails from "components/afrijula/Finance/ClaimDetails";
import Vouchers from "components/afrijula/Super_Admin/Vouchers.jsx";
import {completedTasksChart} from "variables/charts.jsx";
import $ from "jquery";

const packages = ["Bsc", "Std", "Pr", "Pp"];

class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.tablePendingCashRef = React.createRef();
        this.tableSubsRef = React.createRef();
        this.tableClaimedRef = React.createRef();
        this.tableUnclaimedRef = React.createRef();

        this.state = {
            subscriptionRev: 0,
            shopRevenue: 0,
            pendingClaims: 0,
            pendingRevenue: 0,
            claimId: null,
        }
    };

    exportSubs = function() {
        // let filter = "date: {'$gte':"+date"}, date: {'$lte': true}";
        let filter = "";
        let url = compileQuery({}, (baseUrl + "/finance/revenue"),
            ["date","amount","rev_type","id","description","customer_name","confirmed","confirmed_on","confirmed_by"],
            ([{"by": "asc", "attr": "date"}]),
            "Lecket::Finance::Revenue", null, filter,
            null);
        url += '&download_as=true';

        fetch(url, {dataType: 'blob',
            headers:{'Accept': 'application/json',
                "Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {return response.blob()})
            .then(result => {
                var windowUrl = window.URL || window.webkitURL;
                var url = windowUrl.createObjectURL(result);
                var anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = 'revenue.xls';
                anchor.click();
                if(anchor.parentNode)
                    anchor.parentNode.removeChild(anchor);
                windowUrl.revokeObjectURL(url);
            });

    }

    loadSubscriptionTable = (query,resolve,reject) =>{
        let filter = "";

        let url = compileQuery(query, (baseUrl + "/finance/revenue"),
            ["date","amount","rev_type","id","description","customer_name","confirmed","confirmed_on","confirmed_by"],
            ([{"by":"desc","attr":"date"}]),
            "Lecket::Finance::Revenue", null, filter,
            null);

        fetch(url, {dataType: "json",
            headers:{'Accept': 'application/json',
                "Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {return response.json()})
            .then(result => {

                let data = (result.data !== undefined) ?
                    result.data.map((data)=>{
                        return ({ customer_name: data.customer_name, description: data.description,
                            date: dateFormat(data.date), amount: currencyFormat(data.amount),
                            status: data.status, _id: data._id, confirmed_by: data.confirmed_by,
                            confirmed_on: data.confirm_date, confirmed: data.confirmed})
                    }) : [];
                resolve({
                    data: data,
                    page: query.page,
                    totalCount: result.total
                })
            });
    }

    exportUnSettlements = (query) => {
        let filter = "_type: {'$eq': 'Afrijula::Shop::SettlementSale'}, ";

        filter += "settlement_status:{'$eq': 'none'}";

        let include = {"include": "{order: {only: [:customer_name,:order_status,:customer_phone]}}"}

        let url = compileQuery(query, (baseUrl + "/finance/revenue"),
            ["total","number","id","description",'settlement_status','date'],
            ([{"by":"desc","attr":"date"}]),
            "Afrijula::Shop::SettlementSale", include, filter,
            null);

        url += '&download_as=true';
        fetch(url, {dataType: 'blob',
            headers:{'Accept': 'application/json',
                "Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {return response.blob()})
            .then(result => {
                var windowUrl = window.URL || window.webkitURL;
                var url = windowUrl.createObjectURL(result);
                var anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = 'settlements.xls';
                anchor.click();
                if(anchor.parentNode)
                    anchor.parentNode.removeChild(anchor);
                windowUrl.revokeObjectURL(url);
            });
    }

    loadUnSettlementsTable = (query, resolve, reject) => {
        let filter = "_type: {'$eq': 'Afrijula::Shop::SettlementSale'}, ";

        filter += "settlement_status:{'$eq': 'none'}";

        let include = {"include": "{order: {only: [:customer_name,:order_status,:customer_phone]}}"}

        let url = compileQuery(query, (baseUrl + "/finance/revenue"),
            ["total","number","id","description",'settlement_status','date'],
            ([{"by":"desc","attr":"date"}]),
            "Afrijula::Shop::SettlementSale", include, filter,
            null);

        fetch(url, {dataType: "json",
            headers:{'Accept': 'application/json',
                "Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {return response.json()})
            .then(result => {

                let data = (result.data !== undefined) ?
                    result.data.map((data)=>{
                        return ({ customer_name: data.order.customer_name, description: data.description,
                            date: dateFormat(data.date), amount: currencyFormat(data.total),
                            status: data.settlement_status, _id: data._id, customer_phone: data.order.customer_phone,
                            number: data.number})
                    }) : [];
                resolve({
                    data: data,
                    page: query.page,
                    totalCount: result.total
                })
            });
    };

    exportSettlements = (query) => {
        let filter = "'$or': [{request_status:{'$eq': 'sent'}},{transaction_complete:{'$eq': false}}]";

        let url = compileQuery(query, (baseUrl + "/finance/revenue"),
            ["amount","s_type","id","transaction_fee",'reference_number','request_status','account_name'],
            ([{"by":"desc","attr":"date"}]),
            "Afrijula::Shop::Settlement", null, filter,
            null);
        url += '&download_as=true';
        fetch(url, {dataType: 'blob',
            headers:{'Accept': 'application/json',
                "Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {return response.blob()})
            .then(result => {
                var windowUrl = window.URL || window.webkitURL;
                var url = windowUrl.createObjectURL(result);
                var anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = 'settlement_clams.xls';
                anchor.click();
                if(anchor.parentNode)
                    anchor.parentNode.removeChild(anchor);
                windowUrl.revokeObjectURL(url);
            });
    }

    loadSettlementsTable = (query, resolve, reject) => {
        let filter = "'$or': [{request_status:{'$eq': 'sent'}},{transaction_complete:{'$eq': false}}]";


        let url = compileQuery(query, (baseUrl + "/finance/revenue"),
            ["amount","reference_number","id","transaction_fee",'s_type','confirmed_on','request_status','account_name'],
            ([{"by":"desc","attr":"date"}]),
            "Afrijula::Shop::Settlement", null, filter,
            null);

        fetch(url, {dataType: "json",
            headers:{'Accept': 'application/json',
                "Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {return response.json()})
            .then(result => {

                let data = (result.data !== undefined) ?
                    result.data.map((data)=>{
                        return ({ amount: currencyFormat(data.amount), confirmed_on: dateFormat(data.confirmed_on), reference_number: data.reference_number,
                            fee: currencyFormat(data.transaction_fee), s_type: data.s_type, _id: data._id, request_status: data.request_status, customer_name: data.account_name})
                    }) : [];
                resolve({
                    data: data,
                    page: query.page,
                    totalCount: result.total
                })
            });
    };

    exportOrders = (query) => {
        let filter = "'$or': [{merchant_status:{'$eq': 'picked up'}},{merchant_status:{'$eq': 'delivered'}},{merchant_status:{'$eq':'delivery failed'}},{merchant_status:{'$eq':'completed'}}],payment_method: {'$eq':'Cash on Delivery'},payment_settled:{'$eq': false}";


        let url = compileQuery(query, (baseUrl + "/finance/revenue"),
            ["order_amount","shipping_cost","id","currency",'shipping_method','customer_name','merchant_status','shop_order_id'],
            ([{"by":"desc","attr":"date"}]),
            "Afrijula::Shop::Order", null, filter,
            null);
        url += '&download_as=true';
        fetch(url, {dataType: 'blob',
            headers:{'Accept': 'application/json',
                "Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {return response.blob()})
            .then(result => {
                var windowUrl = window.URL || window.webkitURL;
                var url = windowUrl.createObjectURL(result);
                var anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = 'settlement_clams.xls';
                anchor.click();
                if(anchor.parentNode)
                    anchor.parentNode.removeChild(anchor);
                windowUrl.revokeObjectURL(url);
            });
    };

    confirmReceipt = (event, colData) => {
        let _this = this;

        const ids = colData.map((col)=> {return col._id});
        $.ajax({
            method: "PUT",
            url: baseUrl + "/finance/revenue/ids",
            data: {ids: ids, reconcile: true},
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')},
            success: function(result){
                _this.tablePendingCashRef.current && _this.tablePendingCashRef.current.onQueryChange();
            },
            error: function (jqXHR, textStatus, errorThrown) {
                alert(jqXHR.responseText);
            }});
    };

    loadPendingCashOrders  = (query, resolve, reject) => {
        let filter = "'$or': [{merchant_status:{'$eq': 'picked up'}},{merchant_status:{'$eq': 'delivered'}},{merchant_status:{'$eq':'delivery failed'}},{merchant_status:{'$eq':'completed'}}],payment_method: {'$eq':'Cash on Delivery'},payment_settled:{'$eq': false}";

        let url = compileQuery(query, (baseUrl + "/finance/revenue"),
            ["order_amount","shipping_cost","id","currency",'shipping_method','customer_name','merchant_status','shop_order_id','date_purchased'],
            ([{"by":"desc","attr":"date_purchased"}]),
            "Afrijula::Shop::Order", null, filter,
            null);

        fetch(url, {dataType: "json",
            headers:{'Accept': 'application/json',
                "Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"),"UserKey":cookies.load('User-Key')}})
            .then(response => {return response.json()})
            .then(result => {

                let data = (result.data !== undefined) ?
                    result.data.map((data)=>{
                        return ({ amount: currencyFormat(data.order_amount), shipping_cost: currencyFormat(data.shipping_cost), customer_name: data.customer_name, date: dateFormat(data.date_purchased),
                            reference_number: data.shop_order_id,currency: data.currency, shipping_method: data.shipping_method, merchant_status: data.merchant_status,_id: data._id.$oid})
                    }) : [];
                resolve({
                    data: data,
                    page: query.page,
                    totalCount: result.total
                })
            });
    };

    getProps = () => {
        return this.props.returningProps || this.props;
    };

    loadOrgData = (query, resolve, reject) => {

    };

    selectClaim = (event, colData) => {
        this.setState({claimId: colData._id.$oid});
    };

    cancelAddUser = () => {
        this.setState(prevState => ({
            edit: !prevState.edit
        }))
    };

    componentDidMount() {

    };

    render() {
        let _state = this.state;
        const {classes} = this.getProps();
        if (_state.vouchers) {
            _state.vouchers = false;
            return <Vouchers callerState={_state} callerProps={this.getProps()}
                             callerName={Dashboard}
            />;
        }
        if (_state.claimId) {
            let data = _state.claimId;
            _state.claimId = null;
            return <ClaimDetails callerState={_state} callerProps={this.getProps()}
                                   callerName={Dashboard}
                                   claimId={data}
            />;
        } else {
            return (
                <div style={{marginTop: -40}}>
                    <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT}/>

                                <GridContainer>
                                    <GridItem xs={12} sm={6} md={3}>
                                        <Card>
                                            <CardHeader color="warning" stats icon>
                                                <CardIcon color="warning">
                                                    <Icon>people</Icon>
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Subscription Revenue</p>
                                                <h3 className={classes.cardTitle}>
                                                    {_state.subscriptionRev}
                                                </h3>
                                            </CardHeader>
                                        </Card>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={3}>
                                        <Card>
                                            <CardHeader color="success" stats icon>
                                                <CardIcon color="success">
                                                    <EmailIcon/>
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Total Shop Revenue</p>
                                                <h3 className={classes.cardTitle}>
                                                    {_state.shopRevenue}
                                                </h3>
                                            </CardHeader>
                                        </Card>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={3}>
                                        <Card>
                                            <CardHeader color="danger" stats icon>
                                                <CardIcon color="danger">
                                                    <TrendingUpIcon/>
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Pending Claims</p>
                                                <h3 className={classes.cardTitle}>{_state.pendingClaims}</h3>
                                            </CardHeader>

                                        </Card>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={3}>
                                        <Card>
                                            <CardHeader color="primary" stats icon>
                                                <CardIcon color="primary">
                                                    <TrendingDownIcon/>
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Pending Cash Revenue</p>
                                                <h3 className={classes.cardTitle}>{_state.pendingRevenue}</h3>
                                            </CardHeader>
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} lg={6} >
                                        <Card>
                                            <CardHeader color="info">
                                                <h4 className={classes.cardTitleWhite}>Cash with Delivery Company</h4>
                                                <p className={classes.cardCategoryWhite}></p>
                                            </CardHeader>
                                            <CardBody>
                                                <MaterialTable
                                                    tableRef={this.tablePendingCashRef}
                                                    data={query=>new Promise((resolve,reject) => {
                                                        this.loadPendingCashOrders(query,resolve,reject);
                                                    })}
                                                    columns={[
                                                        {title: 'order_number', field: 'reference_number',cellStyle: {padding: '2px'}, headerStyle: {padding: '2px'}},
                                                        {title: getString().date, field:'date', cellStyle:{padding:'2px'}, headerStyle:{padding:'2px'}},
                                                        {title: getString().amount, field: 'amount', cellStyle: {padding: '2px'},headerStyle: {padding: '2px'}},
                                                        {title: 'shipping cost', field: 'shipping_cost', cellStyle: {padding: '2px'},headerStyle: {padding: '2px'}},
                                                        {title: 'shipping method', field: 'shipping_method', cellStyle: {padding: '2px'},headerStyle: {padding: '2px'}},
                                                        {title: 'customer name', field: 'customer_name', cellStyle: {padding: '2px'},headerStyle: {padding: '2px'}},
                                                        {title: getString().status, field: 'merchant_status', cellStyle: {padding: '2px'}, headerStyle: {padding: '2px'}},

                                                    ]}
                                                    options={{
                                                        filtering: true,
                                                        debounceInterval: 1000,
                                                        grouping:false,sorting:true,
                                                        paging:true,
                                                        selection:true,showTitle:false,
                                                        pageSize:10,rowStyle:{height:'4px'}
                                                    }}
                                                    actions={[
                                                        {
                                                            icon: 'cloud_download',tooltip: 'Download',isFreeAction: true,
                                                            onClick: () => { this.exportOrders({})},
                                                        },
                                                        {
                                                            icon: 'refresh',tooltip: 'Refresh',isFreeAction: true,
                                                            onClick: () => { this.tablePendingCashRef.current && this.tablePendingCashRef.current.onQueryChange()},
                                                        },
                                                        {
                                                            icon: 'done',tooltip: 'Confirm Receipt',
                                                            onClick: this.confirmReceipt,
                                                        }
                                                    ]}
                                                />
                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                    <GridItem xs={12} lg={6}>
                                        <Card>
                                            <CardHeader color="success">
                                                <h4 className={classes.cardTitleWhite}>Revenue</h4>
                                                <p className={classes.cardCategoryWhite}></p>
                                            </CardHeader>
                                            <CardBody>
                                                <MaterialTable
                                                    tableRef={this.tableSubsRef}
                                                    data={query=>new Promise((resolve,reject) => {
                                                        this.loadSubscriptionTable(query,resolve,reject);
                                                    })}
                                                    columns={[
                                                        {title: getString().customer, field: 'customer_name',cellStyle: {padding: '2px'}, headerStyle: {padding: '2px'}},
                                                        {title: getString().date, field:'date', cellStyle:{padding:'2px'}, headerStyle:{padding:'2px'}},
                                                        {title: getString().description, field:'description', cellStyle:{padding:'2px'}, headerStyle:{padding:'2px'}},
                                                        {title: getString().amount, field: 'amount', cellStyle: {padding: '2px'},headerStyle: {padding: '2px'}},
                                                        {title: getString().status, field: 'status', cellStyle: {padding: '2px'}, headerStyle: {padding: '2px'}},

                                                    ]}
                                                    options={{
                                                        filtering: true,
                                                        debounceInterval: 1000,
                                                        grouping:false,sorting:true,
                                                        paging:true,
                                                        selection:true,showTitle:false,
                                                        pageSize:10,rowStyle:{height:'4px'}
                                                    }}
                                                    actions={[
                                                        {
                                                            icon: 'cloud_download',tooltip: 'Download',isFreeAction: true,
                                                            onClick: () => { this.exportSubs({})},
                                                        },
                                                        {
                                                            icon: 'refresh',tooltip: 'Refresh',isFreeAction: true,
                                                            onClick: () => { this.tableSubsRef.current && this.tableSubsRef.current.onQueryChange()},
                                                        }
                                                    ]}
                                                />

                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} lg={6}>
                                        <Card>
                                            <CardHeader color="warning">
                                                <h4 className={classes.cardTitleWhite}>Pending Claims</h4>
                                                <p className={classes.cardCategoryWhite}></p>
                                            </CardHeader>
                                            <CardBody>
                                                <CardBody>
                                                    <MaterialTable
                                                        tableRef={this.tableClaimedRef}
                                                        data={query=>new Promise((resolve,reject) => {

                                                            this.loadSettlementsTable(query,resolve,reject);
                                                        })}
                                                        columns={[
                                                            {title: getString().customer, field: 'customer_name',cellStyle: {padding: '2px'}, headerStyle: {padding: '2px'}},
                                                            {title: getString().type, field: 's_type',cellStyle: {padding: '2px'}, headerStyle: {padding: '2px'}},
                                                            {title: getString().date, field:'confirmed_on', cellStyle:{padding:'2px'}, headerStyle:{padding:'2px'}},
                                                            {title: getString().amount, field:'amount', cellStyle:{padding:'2px'}, headerStyle:{padding:'2px'}},
                                                            {title: 'Fees', field: 'fee', cellStyle: {padding: '2px'}, headerStyle: {padding: '2px'}},
                                                            {title: getString().number, field: 'reference_number', cellStyle: {padding: '2px'},headerStyle: {padding: '2px'}},
                                                            {title: getString().status, field: 'request_status', cellStyle: {padding: '2px'},headerStyle: {padding: '2px'}}

                                                        ]}
                                                        onRowClick={this.selectClaim}
                                                        options={{
                                                            filtering: true,
                                                            debounceInterval: 1000,
                                                            grouping:false,sorting:true,
                                                            paging:true,
                                                            selection:true,showTitle:false,
                                                            pageSize:10,rowStyle:{height:'2px'}
                                                        }}
                                                        actions={[
                                                            {
                                                                icon: 'cloud_download',tooltip: 'Download',isFreeAction: true,
                                                                onClick: () => { this.exportSettlements({})},
                                                            },
                                                            {
                                                                icon: 'refresh',tooltip: 'Refresh',isFreeAction: true,
                                                                onClick: () => { this.tableClaimedRef.current && this.tableClaimedRef.current.onQueryChange()},
                                                            }
                                                        ]}
                                                    />

                                                </CardBody>
                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} lg={6}>
                                        <Card>
                                            <CardHeader color="warning">
                                                <h4 className={classes.cardTitleWhite}>Unclaimed Settements</h4>
                                                <p className={classes.cardCategoryWhite}></p>
                                            </CardHeader>
                                            <CardBody>
                                                <MaterialTable
                                                    tableRef={this.tableUnclaimedRef}
                                                    data={query=>new Promise((resolve,reject) => {

                                                        this.loadUnSettlementsTable(query,resolve,reject);
                                                    })}
                                                    columns={[
                                                        {title: getString().customer, field: 'customer_name',cellStyle: {padding: '2px'}, headerStyle: {padding: '2px'}},
                                                        {title: getString().date, field:'date', cellStyle:{padding:'2px'}, headerStyle:{padding:'2px'}},
                                                        {title: getString().description, field:'description', cellStyle:{padding:'2px'}, headerStyle:{padding:'2px'}},
                                                        {title: getString().amount, field: 'amount', cellStyle: {padding: '2px'},headerStyle: {padding: '2px'}},
                                                        {title: getString().status, field: 'status', cellStyle: {padding: '2px'}, headerStyle: {padding: '2px'}},

                                                    ]}
                                                    options={{
                                                        filtering: true,
                                                        debounceInterval: 1000,
                                                        grouping:false,sorting:true,
                                                        paging:true,
                                                        selection:true,showTitle:false,
                                                        pageSize:10,rowStyle:{height:'4px'}
                                                    }}
                                                    actions={[
                                                        {
                                                            icon: 'cloud_download',tooltip: 'Download',isFreeAction: true,
                                                            onClick: () => { this.exportUnSettlements({})},
                                                        },
                                                        {
                                                            icon: 'refresh',tooltip: 'Refresh',isFreeAction: true,
                                                            onClick: () => { this.tableUnclaimedRef.current && this.tableUnclaimedRef.current.onQueryChange()},
                                                        }
                                                    ]}
                                                />
                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                </div>
            );
        }
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Dashboard);
